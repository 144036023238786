import { CheckBoxSvg } from 'components/svg/check-box-svg';
import type { ChangeEventHandler } from 'react';
import { useState } from 'react';
import { Tooltip } from 'components/tooltip';
import { CheckBoxStyled } from './style';

interface CheckBoxProps {
  checked: boolean;
  setChecked: (checked: boolean) => void;
  disabled?: boolean;
}

export const CheckBox = ({ checked, setChecked, disabled }: CheckBoxProps) => {
  const [isHover, setIsHover] = useState(false);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setChecked(e.target.checked);
  };

  const handleMouseEnter = () => {
    if (disabled) {
      setIsHover(true);
    }
  };

  const handleMouseLeave = () => {
    if (disabled) {
      setIsHover(false);
    }
  };

  return (
    <CheckBoxStyled
      style={{ cursor: disabled ? 'default' : 'pointer' }}
      checked={checked}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
      <CheckBoxSvg />
      {disabled && isHover && (
        <Tooltip>
          Ці дані вже були редагованню! Зверніться до працівника AVIS
        </Tooltip>
      )}
    </CheckBoxStyled>
  );
};
