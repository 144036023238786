import styled from 'styled-components';

export const ContactItemStyled = styled.div`
  border-radius: 10px;
  border: 1px solid ${(p) => p.theme.colors.stroke};
`;

export const ContactItemHeader = styled.div`
  padding: 10px 10px 8px;
  border-radius: 10px 10px 0 0;
  min-height: 35px;
  background: ${(p) => p.theme.colors.secondaryDark};
  color: ${(p) => p.theme.colors.white};
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  text-align: center;
`;

export const ContactItemBody = styled.div`
  padding: 20px;
`;

export const FullName = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  color: ${(p) => p.theme.colors.black};
`;

export const ContactData = styled.a`
  display: block;
  margin-top: 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  color: ${(p) => p.theme.colors.secondaryDark};
`;
