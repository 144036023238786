import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFleetAllUpdatesApi } from 'api/fleet-all-updates';

export enum FleetAllUpdatesAction {
  GET_FLEET_UPDATES = 'fleet-all-updates/get-updates'
}

export const getFleetAllUpdatesAction = createAsyncThunk(
  FleetAllUpdatesAction.GET_FLEET_UPDATES,
  getFleetAllUpdatesApi
);
