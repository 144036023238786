import styled from 'styled-components';
import type { DateSelectStyledProps } from './types';

export const DateSelectStyled = styled.div`
  position: relative;
`;

export const DateSelectHeader = styled.div<DateSelectStyledProps>`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${(p) =>
    p.$isActive ? p.theme.colors.primary : p.theme.colors.black};
  cursor: pointer;
  svg {
    margin-left: 8px;
    transform: ${(p) => (p.$isActive ? 'rotate(0deg)' : 'rotate(180deg)')};
    path {
      stroke: ${(p) =>
        p.$isActive ? p.theme.colors.primary : p.theme.colors.black};
    }
  }
`;

export const DateSelectOptionsStyled = styled.div`
  position: absolute;
  left: 0;
  min-width: 100%;
  top: calc(100% + 6px);
  background: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.stroke};
  box-shadow:
    3px 0px 10px rgba(63, 81, 126, 0.11),
    0px 4px 8px rgba(63, 81, 126, 0.09);
  border-radius: 9px;
  max-height: 200px;
  overflow: auto;
  z-index: 1;
`;

export const OptionItem = styled.div`
  padding: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${(p) => p.theme.colors.black};
  text-align-last: left;
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.black};
    transition: background-color 0.2s;
  }
`;
