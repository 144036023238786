import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FleetVehicle } from 'models/fleet-vehicle';
import type { ResponseWithPagination } from 'common/types';
import { getFleetVehicleAction, updateFleetVehicleAction } from './actions';

interface FleetVehicleState {
  fleetVehicle: ResponseWithPagination<FleetVehicle>;
  isLoading: boolean;
}

const initialState: FleetVehicleState = {
  fleetVehicle: { count: 0, next: null, previous: null, results: [] },
  isLoading: false
};

const fleetVehicleSlice = createSlice({
  name: 'fleet-vehicle',
  initialState,
  reducers: {
    updateFleetVehicle: (
      state,
      { payload }: PayloadAction<Partial<FleetVehicle>>
    ) => {
      state.fleetVehicle.results = state.fleetVehicle.results.map((item) =>
        item.id === payload.id ? { ...item, ...payload } : item
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFleetVehicleAction.fulfilled, (state, { payload }) => {
      state.fleetVehicle = payload;
      state.isLoading = false;
    });
    builder.addCase(getFleetVehicleAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFleetVehicleAction.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      updateFleetVehicleAction.fulfilled,
      (state, { payload }) => {
        state.fleetVehicle.results = state.fleetVehicle.results.map((item) =>
          item.id === payload.id ? payload : item
        );
      }
    );
  }
});

export const { updateFleetVehicle } = fleetVehicleSlice.actions;
export const fleetVehicleReducer = fleetVehicleSlice.reducer;
