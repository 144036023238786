import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { TiresMovements } from 'models/tires-movements';
import type { ID, ResponseWithPagination } from 'common/types';
import type { ServiceTablesLastUpdate } from 'models/service-tables-last-update';
import {
  getLastUpdateAction,
  getTiresMovementsAction,
  updateTiresMovementsAction
} from './actions';

interface TiresMovementsState {
  movements: ResponseWithPagination<TiresMovements>;
  lastUpdate?: ServiceTablesLastUpdate;
  isLoading: boolean;
}

const initialState: TiresMovementsState = {
  movements: { count: 0, next: null, previous: null, results: [] },
  lastUpdate: undefined,
  isLoading: false
};

const tiresMovementsSlice = createSlice({
  name: 'movements',
  initialState,
  reducers: {
    updateMovementsDraft: (
      state,
      { payload }: PayloadAction<TiresMovements>
    ) => {
      state.movements.results = state.movements.results.map((item) =>
        item.id === payload.id ? payload : item
      );
    },
    updateTiresMovementsComment: (
      state,
      { payload }: PayloadAction<{ id: ID; comment: string; key: string }>
    ) => {
      state.movements.results = state.movements.results.map((item) =>
        item.id === payload.id
          ? { ...item, [payload.key]: payload.comment }
          : item
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTiresMovementsAction.fulfilled, (state, { payload }) => {
      state.movements = payload;
      state.isLoading = false;
    });
    builder.addCase(getTiresMovementsAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTiresMovementsAction.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getLastUpdateAction.fulfilled, (state, { payload }) => {
      state.lastUpdate = payload;
    });
    builder.addCase(
      updateTiresMovementsAction.fulfilled,
      (state, { payload }) => {
        state.movements.results = state.movements.results.map((item) =>
          item.id === payload.id ? payload : item
        );
      }
    );
  }
});

export const { updateMovementsDraft, updateTiresMovementsComment } =
  tiresMovementsSlice.actions;
export const tiresMovementsReducer = tiresMovementsSlice.reducer;
