import styled from 'styled-components';

export const NotificationsStyled = styled.div`
  position: relative;
`;

export const NotifIcon = styled.div<{ $isActive?: boolean }>`
  padding: 0 14px;
  cursor: pointer;
  height: 20px;
  border-right: 1px solid ${(p) => p.theme.colors.secondaryDark};
  margin-right: 14px;
  svg {
    path {
      fill: ${(p) =>
        p.$isActive ? p.theme.colors.primary : p.theme.colors.black};
    }
  }
`;

export const NotifContent = styled.div<{ $isSettings?: boolean }>`
  padding: 20px;
  padding-top: 0;
  position: fixed;
  top: 58px;
  right: 52px;
  border-radius: 9px;
  border: 1px solid ${(p) => p.theme.colors.stroke};
  z-index: 10;
  max-height: 320px;
  max-width: 398px;
  width: 100%;
  overflow: ${(p) => (p.$isSettings ? 'none' : 'auto')};
  background: #fff;
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    right: 16px;
    width: calc(100% - 32px);
    top: 46px;
  }
`;

export const NotifContentHeader = styled.div<{ $isEmpty?: boolean }>`
  padding: ${(p) => (p.$isEmpty ? '20px 0 0' : '20px 0 14px')};
  border-bottom: ${(p) =>
    p.$isEmpty
      ? 'none'
      : `1px solid
    ${p.theme.colors.stroke}`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: #fff;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NotifCount = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  margin-right: 14px;
`;

export const SettingsIcon = styled.div`
  cursor: pointer;
`;

export const ReadAll = styled.div`
  color: ${(p) => p.theme.colors.primary};
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
`;

export const NotifTitle = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  font-size: 16px;
  font-weight: 700;
  gap: 12px;
  span {
    font-size: 12px;
    font-weight: 400;
  }
`;

export const Text = styled.p`
  margin-top: 14px;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 160%;
`;

export const BackIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid ${(p) => p.theme.colors.primary};
  margin-right: 12px;
  svg {
    transform: rotate(90deg);
  }
  cursor: pointer;
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 30px;
  gap: 12px;
`;

export const SettingsSubmitWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;
