import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTiresMovementsHistoryApi } from 'api/tires-movements-history';

export enum TiresMovementsHistoryAction {
  GET_MOVEMENTS_HISTORY = 'movements-history/get-movements-history'
}

export const getMovementsHistoryAction = createAsyncThunk(
  TiresMovementsHistoryAction.GET_MOVEMENTS_HISTORY,
  getTiresMovementsHistoryApi
);
