import type { ID } from 'common/types';
import type { HeaderItem } from 'components/data-table';
import type { ReactNode } from 'react';

export interface FleetVehicleTable {
  id: ID;
  vehicle_plate: string;
  vehicle_brand_model: string;
  driver_first_name: ReactNode;
  driver_last_name: ReactNode;
  driver_phone: ReactNode;
  vehicle_location: ReactNode;
  tires_storage_city: ReactNode;
}

export const tableHeaders: HeaderItem<FleetVehicleTable>[] = [
  { id: 'vehicle_plate', title: 'ДНЗ', width: '100px' },
  { id: 'vehicle_brand_model', title: 'Марка/ модель', width: '154px' },
  {
    id: 'driver_last_name',
    title: 'Прізвище водія',
    editable: true
  },
  {
    id: 'driver_first_name',
    title: 'Імʼя водія',
    editable: true
  },
  {
    id: 'driver_phone',
    title: 'Телефон водія',
    width: '151px',
    editable: true
  },
  {
    id: 'vehicle_location',
    title: 'Місто знаходження авто',
    width: '224px',
    editable: true
  },
  {
    id: 'tires_storage_city',
    title: 'Місто зберігання шин',
    width: '201px',
    editable: true
  }
];
