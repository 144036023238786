import { updateTiresDisposalsApi } from 'api/tires-disposals';
import type { ID } from 'common/types';
import { CellInput } from 'components/cell-input';
import { EditableCell } from 'components/editable-cell';
import { useAppDispatch } from 'store/store';
import { updateTiresDisposals } from 'store/tires-disposals/reducer';

interface CommentProps {
  initialValue: string;
  id: ID;
  disabled?: boolean;
}

export const Comment = ({ id, initialValue, disabled }: CommentProps) => {
  const dispatch = useAppDispatch();

  const handleChange = (value: string) => {
    dispatch(updateTiresDisposals({ id, service_comment: value }));
  };

  const handleBlur = () => {
    updateTiresDisposalsApi({
      id,
      body: {
        service_comment: initialValue
      }
    });
  };

  return (
    <EditableCell withOutPaddingTop disabled={disabled}>
      <CellInput
        disabled={disabled}
        value={initialValue}
        setValue={handleChange}
        onBlur={handleBlur}
      />
    </EditableCell>
  );
};
