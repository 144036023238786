export const SidebarLogoSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="232"
    height="130"
    viewBox="0 0 232 130"
    fill="none"
  >
    <g clipPath="url(#clip0_425_25601)">
      <path d="M232 0H0V161H232V0Z" fill="white" />
      <path
        d="M57.6763 21.1818H68.5494L87.2198 67.7134H75.7316L71.7481 58.4031L53.5765 58.4198L49.9455 67.7134H37.8522L57.673 21.1818H57.6763ZM62.6108 34.2216L56.998 49.1798H68.29L62.6108 34.2216ZM80.7757 21.1818H92.7028L103.815 54.1011L115.101 21.1818H126.476L109.042 67.7134H98.0895L80.7724 21.1818H80.7757ZM131.264 21.1818H142.064V67.7134H131.264V21.1818ZM176.608 32.8658C174.975 30.7633 171.241 29.307 168.761 29.307C165.366 29.307 161.908 30.7767 161.908 34.2618C161.908 41.5835 183.188 37.2849 183.188 53.5855C183.188 63.9706 174.899 68.8986 165.369 68.8986C159.431 68.8986 153.037 66.2906 148.764 61.9719L155.444 55.2461C157.532 58.008 162.563 59.4342 165.958 59.4342C169.353 59.4342 172.379 57.8574 172.379 54.5698C172.379 46.0261 151.148 50.6227 150.862 35.1155C150.669 24.6301 159.886 20 168.96 20C174.181 20 179.594 22.1125 183.637 25.8889L176.611 32.8658H176.608ZM192.03 21.145C194.218 21.145 196 22.9394 196 25.1423C196 27.3452 194.218 29.1396 192.03 29.1396C189.842 29.1396 188.06 27.3452 188.06 25.1423C188.06 22.9394 189.842 21.145 192.03 21.145ZM192.03 28.1721C193.619 28.1721 194.913 26.8062 194.913 25.1423C194.913 23.4784 193.623 22.1125 192.03 22.1125C190.437 22.1125 189.147 23.4784 189.147 25.1423C189.147 26.8062 190.437 28.1721 192.03 28.1721ZM190.504 23.003H191.977C192.426 23.003 193.749 23.003 193.749 24.3555C193.749 25.2059 193.257 25.4637 192.778 25.5273L193.845 27.1945H192.725L191.774 25.5809H191.591V27.1945H190.504V23.003ZM191.581 24.7439H192.02C192.276 24.7439 192.662 24.7338 192.662 24.2919C192.662 23.85 192.276 23.84 192.02 23.84H191.581V24.7439Z"
        fill="#D4002A"
      />
      <path
        d="M190.916 82.7019V81.6306L35.9999 81.6306V82.7019L190.916 82.7019Z"
        fill="#D4002A"
      />
      <path
        d="M36.1629 100.459H43.1855V101.825H37.6891V104.513H42.4606V105.819H37.6891V109.783H36.1595V100.459H36.1629Z"
        fill="#D4002A"
      />
      <path
        d="M47.1091 109.783V100.459H48.6387V108.417H53.7793V109.783H47.1091Z"
        fill="#D4002A"
      />
      <path
        d="M63.6681 105.819H59.0063V108.413H64.5027V109.779H57.4801V100.456H64.5027V101.821H59.0063V104.51H63.6681V105.815V105.819Z"
        fill="#D4002A"
      />
      <path
        d="M74.7838 105.819H70.122V108.413H75.6184V109.779H68.5958V100.456H75.6184V101.821H70.122V104.51H74.7838V105.815V105.819Z"
        fill="#D4002A"
      />
      <path
        d="M78.7174 100.459H86.4815V101.825H83.3626V109.783H81.833V101.825H78.7141V100.459H78.7174Z"
        fill="#D4002A"
      />
      <path
        d="M95.1865 107.67C95.1865 107.67 96.2971 108.57 97.9497 108.57C99.3229 108.57 99.8948 108.028 99.8948 107.282C99.8948 106.535 99.416 106.21 97.6404 105.695C95.4625 105.072 94.4284 104.372 94.4284 102.913C94.4284 101.279 95.6953 100.254 97.963 100.254C100.357 100.254 101.375 101.185 101.375 101.185L100.54 102.444C100.54 102.444 99.5823 101.681 97.963 101.681C96.8058 101.681 96.0943 102.099 96.0943 102.846C96.0943 103.482 96.6329 103.793 98.3188 104.258C100.666 104.895 101.591 105.718 101.591 107.164C101.591 108.751 100.48 109.993 97.9796 109.993C95.4791 109.993 94.3519 108.828 94.3519 108.828L95.1865 107.663V107.67Z"
        fill="#D4002A"
      />
      <path
        d="M105.032 105.136C105.032 102.417 106.901 100.254 109.88 100.254C112.859 100.254 114.741 102.414 114.741 105.136C114.741 107.857 112.873 110 109.88 110C106.888 110 105.032 107.824 105.032 105.136ZM113.169 105.136C113.169 103.207 111.855 101.731 109.88 101.731C107.905 101.731 106.608 103.207 106.608 105.136C106.608 107.064 107.922 108.507 109.88 108.507C111.839 108.507 113.169 107.047 113.169 105.136Z"
        fill="#D4002A"
      />
      <path
        d="M118.848 109.783V100.459H120.374V108.417H125.515V109.783H118.845H118.848Z"
        fill="#D4002A"
      />
      <path
        d="M128.786 105.836V100.459H130.316V105.836C130.316 107.345 131.151 108.524 132.833 108.524C134.516 108.524 135.317 107.342 135.317 105.836V100.459H136.847V105.836C136.847 108.259 135.38 110 132.833 110C130.286 110 128.79 108.259 128.79 105.836H128.786Z"
        fill="#D4002A"
      />
      <path
        d="M140.454 100.459H148.218V101.825H145.099V109.783H143.573V101.825H140.454V100.459Z"
        fill="#D4002A"
      />
      <path
        d="M153.482 109.783H151.956V100.459H153.482V109.783Z"
        fill="#D4002A"
      />
      <path
        d="M157.602 105.136C157.602 102.417 159.47 100.254 162.45 100.254C165.429 100.254 167.311 102.414 167.311 105.136C167.311 107.857 165.442 110 162.45 110C159.457 110 157.602 107.824 157.602 105.136ZM165.738 105.136C165.738 103.207 164.425 101.731 162.45 101.731C160.475 101.731 159.178 103.207 159.178 105.136C159.178 107.064 160.491 108.507 162.45 108.507C164.408 108.507 165.738 107.047 165.738 105.136Z"
        fill="#D4002A"
      />
      <path
        d="M179.75 109.783H179.348L172.94 103.536V109.783H171.414V100.459H171.813L178.221 106.612V100.459H179.75V109.783Z"
        fill="#D4002A"
      />
      <path
        d="M184.675 107.67C184.675 107.67 185.785 108.57 187.438 108.57C188.811 108.57 189.383 108.028 189.383 107.282C189.383 106.535 188.904 106.21 187.128 105.695C184.951 105.072 183.916 104.372 183.916 102.913C183.916 101.279 185.183 100.254 187.451 100.254C189.845 100.254 190.863 101.185 190.863 101.185L190.028 102.444C190.028 102.444 189.07 101.681 187.451 101.681C186.294 101.681 185.582 102.099 185.582 102.846C185.582 103.482 186.124 103.793 187.807 104.258C190.154 104.895 191.079 105.718 191.079 107.164C191.079 108.751 189.968 109.993 187.468 109.993C184.967 109.993 183.84 108.828 183.84 108.828L184.675 107.663V107.67Z"
        fill="#D4002A"
      />
    </g>
    <defs>
      <clipPath id="clip0_425_25601">
        <rect width="232" height="130" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
