import type { ReactNode } from 'react';
import { BtnSubmitStyled } from './style';

interface BtnSubmitProps {
  children: ReactNode;
  onClick?: () => void;
  type?: 'submit' | 'button';
  minWidth?: string;
  disabled?: boolean;
}

export const BtnSubmit = ({
  children,
  onClick,
  type = 'submit',
  minWidth,
  disabled
}: BtnSubmitProps) => (
  <BtnSubmitStyled
    disabled={disabled}
    $minWidth={minWidth}
    type={type}
    onClick={onClick}
  >
    {children}
  </BtnSubmitStyled>
);
