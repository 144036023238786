export const ExcellSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
  >
    <g clipPath="url(#clip0_445_168425)">
      <path
        d="M40.2851 1.7816C39.939 1.5005 39.4835 1.39304 39.0484 1.48998L1.13182 10.24C0.464498 10.3934 -0.00636081 10.9903 6.49696e-05 11.675V61.2584C0.000338407 61.9779 0.525475 62.5899 1.23669 62.6992L39.1533 68.5326C39.9494 68.655 40.694 68.1089 40.8164 67.313C40.8276 67.2398 40.8333 67.1658 40.8333 67.0919V2.92498C40.8347 2.48023 40.6327 2.05927 40.2851 1.7816Z"
        fill="#4CAF50"
      />
      <path
        d="M68.5431 62.7194H39.3763C38.5709 62.7194 37.918 62.0664 37.918 61.261C37.918 60.4556 38.5709 59.8026 39.3763 59.8026H67.0847V10.2194H39.3763C38.5709 10.2194 37.918 9.56639 37.918 8.76098C37.918 7.95557 38.5709 7.30273 39.3763 7.30273H68.543C69.3484 7.30273 70.0013 7.9557 70.0013 8.76111V61.2611C70.0013 62.0665 69.3485 62.7194 68.5431 62.7194Z"
        fill="#4CAF50"
      />
      <path
        d="M27.7075 48.1327C27.2047 48.1327 26.7374 47.8736 26.4709 47.4473L11.8875 24.114C11.4392 23.4449 11.6183 22.539 12.2874 22.0907C12.9565 21.6424 13.8624 21.8215 14.3107 22.4906C14.3284 22.5168 14.3451 22.5436 14.3609 22.571L28.9443 45.9043C29.3695 46.5883 29.1598 47.4875 28.4758 47.9129C28.2451 48.0561 27.9791 48.1323 27.7075 48.1327Z"
        fill="#FAFAFA"
      />
      <path
        d="M13.1233 48.1357C12.3179 48.134 11.6663 47.4798 11.668 46.6744C11.6685 46.4021 11.7454 46.1354 11.8896 45.9044L26.473 22.5711C26.8768 21.8742 27.7692 21.6367 28.4659 22.0406C29.1628 22.4445 29.4003 23.3368 28.9964 24.0335C28.9805 24.0609 28.9639 24.0877 28.9462 24.1139L14.363 47.4473C14.0966 47.8756 13.6278 48.1361 13.1233 48.1357Z"
        fill="#FAFAFA"
      />
      <path
        d="M51.0443 62.7117C50.2389 62.7117 49.5859 62.0587 49.5859 61.2533V8.7533C49.5859 7.94789 50.2389 7.29492 51.0443 7.29492C51.8497 7.29492 52.5027 7.94789 52.5027 8.7533V61.2533C52.5026 62.0588 51.8497 62.7117 51.0443 62.7117Z"
        fill="#4CAF50"
      />
      <path
        d="M68.5431 53.9617H39.3763C38.5709 53.9617 37.918 53.3087 37.918 52.5033C37.918 51.6979 38.5709 51.0449 39.3763 51.0449H68.543C69.3484 51.0449 70.0013 51.6979 70.0013 52.5033C70.0013 53.3088 69.3485 53.9617 68.5431 53.9617Z"
        fill="#4CAF50"
      />
      <path
        d="M68.5431 45.2117H39.3763C38.5709 45.2117 37.918 44.5587 37.918 43.7533C37.918 42.9479 38.5709 42.2949 39.3763 42.2949H68.543C69.3484 42.2949 70.0013 42.9479 70.0013 43.7533C70.0013 44.5588 69.3485 45.2117 68.5431 45.2117Z"
        fill="#4CAF50"
      />
      <path
        d="M68.5431 36.4617H39.3763C38.5709 36.4617 37.918 35.8087 37.918 35.0033C37.918 34.1979 38.5709 33.5449 39.3763 33.5449H68.543C69.3484 33.5449 70.0013 34.1979 70.0013 35.0033C70.0013 35.8088 69.3485 36.4617 68.5431 36.4617Z"
        fill="#4CAF50"
      />
      <path
        d="M68.5431 27.7117H39.3763C38.5709 27.7117 37.918 27.0587 37.918 26.2533C37.918 25.4479 38.5709 24.7949 39.3763 24.7949H68.543C69.3484 24.7949 70.0013 25.4479 70.0013 26.2533C70.0013 27.0587 69.3485 27.7117 68.5431 27.7117Z"
        fill="#4CAF50"
      />
      <path
        d="M68.5431 18.9617H39.3763C38.5709 18.9617 37.918 18.3087 37.918 17.5033C37.918 16.6979 38.5709 16.0449 39.3763 16.0449H68.543C69.3484 16.0449 70.0013 16.6979 70.0013 17.5033C70.0013 18.3088 69.3485 18.9617 68.5431 18.9617Z"
        fill="#4CAF50"
      />
    </g>
    <defs>
      <clipPath id="clip0_445_168425">
        <rect width="70" height="70" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
