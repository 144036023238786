export const AuthLogoSvg = () => (
  <svg
    width="425"
    height="200"
    viewBox="0 0 425 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M425 0H0V200H425V0Z" fill="#D4002A" />
    <g clipPath="url(#clip0_371_19476)">
      <mask
        id="mask0_371_19476"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="425"
        height="200"
      >
        <path d="M425 0H0V200H425V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_371_19476)">
        <path
          d="M92.8863 50.0063H116.419L156.814 149.994H131.963L123.341 129.986H84.011L76.15 149.994H49.9806L92.8863 50.0063ZM103.562 78.0271L91.4156 110.181H115.861L103.562 78.0271ZM142.867 50.0063H168.681L192.721 120.756L217.14 50.0063H241.763L204.03 149.994H180.321L142.867 50.0063ZM252.109 50.0063H275.489V149.994H252.109V50.0063ZM350.219 75.1109C346.694 70.5972 338.605 67.4528 333.229 67.4528C325.876 67.4528 318.395 70.6225 318.395 78.1032C318.395 93.8506 364.445 84.5949 364.445 119.64C364.445 141.955 346.517 152.555 325.876 152.555C313.019 152.555 299.199 146.951 289.943 137.67L304.397 123.215C308.911 129.149 319.79 132.218 327.143 132.218C334.497 132.218 341.04 128.82 341.04 121.77C341.04 103.411 295.091 113.3 294.482 79.9544C294.077 57.4109 314.008 47.4705 333.635 47.4705C344.945 47.4705 356.635 52.0096 365.383 60.1243L350.194 75.1109H350.219ZM383.59 49.9303C388.332 49.9303 392.187 53.7847 392.187 58.5267C392.187 63.2687 388.332 67.1231 383.59 67.1231C378.848 67.1231 374.994 63.2687 374.994 58.5267C374.994 53.7847 378.848 49.9303 383.59 49.9303ZM383.59 65.0437C387.039 65.0437 389.828 62.1022 389.828 58.5267C389.828 54.9512 387.039 52.0096 383.59 52.0096C380.142 52.0096 377.352 54.9512 377.352 58.5267C377.352 62.1022 380.142 65.0437 383.59 65.0437ZM380.294 53.9369H383.489C384.453 53.9369 387.318 53.9369 387.318 56.853C387.318 58.6788 386.253 59.2367 385.213 59.3635L387.521 62.939H385.086L383.033 59.4649H382.627V62.939H380.268V53.9369H380.294ZM382.627 57.6899H383.565C384.123 57.6899 384.96 57.6899 384.96 56.7263C384.96 55.7626 384.123 55.7626 383.565 55.7626H382.627V57.7152V57.6899Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_371_19476">
        <rect width="425" height="200" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
