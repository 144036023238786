import { DataTable } from 'components/data-table';
import { Navbar } from 'components/navbar';
import { Subheader } from 'components/subheader';
import { useScrollHeight } from 'hooks/use-scroll-height';
import { useEffect, useRef } from 'react';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { NoData } from 'components/no-data';
import {
  getTiresDisposalsAction,
  updateTiresDisposalsAction
} from 'store/tires-disposals/actions';
import type { TiresDisposals } from 'models/tires-disposals';
import { EditableCell } from 'components/editable-cell';
import { CheckBox } from 'components/checkbox';
import { groupeEntities } from 'helpers/groupe-entities';
import { UpdatedAt } from 'components/updated-at';
import { dateToHeaderFormat } from 'helpers/date';
import { Loader } from 'components/loader';
import { getServiceAllUpdatesAction } from 'store/service-all-updates/actions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { updateNotif } from 'store/notifications/reducer';
import { HeaderBtn } from 'components/header-btn';
import { AppRoute } from 'common/enums/app/app-route';
import { SIZE } from 'components/paginations';
import { Search } from 'components/search';
import { getTiresDisposalsApi } from 'api/tires-disposals';
import type { TiresDisposalsTable } from './data';
import { tableHeaders } from './data';
import { Comment } from './comment';

export const TiresDisposalsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tableRef = useRef(null);
  const { scrollHeight } = useScrollHeight(tableRef);

  const { tiresDisposals, isLoading } = useTypedSelector(
    (state) => state.tiresDisposals
  );
  const { serviceUpdates } = useTypedSelector((state) => state.serviceUpdates);

  useEffect(() => {
    const search = searchParams.get('search');
    const page = searchParams.get('page');
    dispatch(
      getTiresDisposalsAction(
        search ? `?search=${search}&page=${page || 1}` : `?page=${page || 1}`
      )
    );
    dispatch(getServiceAllUpdatesAction());
    dispatch(updateNotif({ serviceDisposals: false }));
  }, [dispatch, searchParams]);

  const handleConfirm = (item: TiresDisposals) => {
    dispatch(
      updateTiresDisposalsAction({
        id: item.id,
        body: {
          confirmed: true,
          service_comment: item.service_comment
        }
      })
    );
  };

  const transformToTableFormat = (disposals: TiresDisposals[]) =>
    disposals.map((item) => ({
      ...item,
      vehicle_plate: item.isHeader ? item.vehicle_plate : '',
      vehicle_model: item.isHeader ? item.vehicle_model : '',
      tire_location: item.isHeader ? item.tire_location : '',
      season: item.isHeader ? '' : item.season,
      tire_model: item.isHeader ? '' : item.tire_model,
      size: item.isHeader ? '' : item.size,
      diameter: item.isHeader ? '' : item.diameter,
      tread_depth: item.isHeader ? '' : item.tread_depth,
      tire_status: item.isHeader ? '' : item.tire_status,
      comment: item.isHeader ? '' : item.comment,
      service_comment: item.isHeader ? (
        ''
      ) : (
        <Comment
          initialValue={item.service_comment}
          id={item.id}
          disabled={item.confirmed}
        />
      ),
      confirmed: item.isHeader ? (
        ''
      ) : (
        <EditableCell disabled={item.confirmed}>
          <CheckBox
            checked={item.confirmed}
            disabled={item.confirmed}
            setChecked={() => handleConfirm(item)}
          />
        </EditableCell>
      )
    }));

  return (
    <>
      <Navbar>
        {' '}
        <Search
          fetcher={getTiresDisposalsApi}
          rootPath={AppRoute.UTILIZATION}
        />
      </Navbar>
      <Subheader title="Утилізація">
        {!!serviceUpdates.results.length &&
          !!tiresDisposals.results.length &&
          serviceUpdates.results[0].service_disposals_table && (
            <UpdatedAt absoluteAlign>
              {dateToHeaderFormat(
                serviceUpdates.results[0].service_disposals_table
              )}
            </UpdatedAt>
          )}
        <HeaderBtn onClick={() => navigate(AppRoute.UTILIZATION_HISTORY)}>
          Переглянути історію
        </HeaderBtn>
      </Subheader>
      <div ref={tableRef} style={{ position: 'relative' }}>
        {tiresDisposals.results.length ? (
          <DataTable<TiresDisposalsTable>
            headers={tableHeaders}
            data={transformToTableFormat(
              groupeEntities(tiresDisposals.results)
            )}
            checkedList={[]}
            setCheckedList={() => {}}
            actions={[]}
            withOutCheck
            customHeight={
              tiresDisposals.count >= SIZE ? scrollHeight - 64 : scrollHeight
            }
            isFetching={isLoading}
            count={tiresDisposals.count}
          />
        ) : isLoading ? (
          <Loader isBig place={{ left: 'calc(50% - 16px)', top: '100px' }} />
        ) : (
          <NoData height={`${scrollHeight}px`} />
        )}
      </div>
    </>
  );
};
