import styled from 'styled-components';

export const MainLayoutStyled = styled.div`
  height: 100vh;
  display: flex;
`;

export const Content = styled.div`
  width: calc(100% - 232px);

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    width: 100%;
  }
`;
