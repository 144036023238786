export const TiresStorage = [
  { id: 0, title: '-' },
  { id: 1, title: 'Склад №1' },
  { id: 2, title: 'Склад №2' },
  { id: 3, title: 'Склад №3' },
  { id: 4, title: 'Склад №4' },
  { id: 5, title: 'Склад №6' },
  { id: 6, title: 'Склад №5' },
  { id: 7, title: 'Грінченко' },
  { id: 8, title: 'Шини в дорозі' },
  { id: 10, title: 'Матадор-К' },
  { id: 12, title: 'Мукачево склад' },
  { id: 13, title: 'Біла Церква склад' },
  { id: 14, title: 'Вінниця склад' },
  { id: 15, title: 'Дніпро склад АТЛ' },
  { id: 16, title: 'Дніпро-Імпульс  Склад' },
  { id: 17, title: 'Житомир склад' },
  { id: 18, title: 'Запоріжжя склад' },
  { id: 19, title: 'Івано Франківськ Альянс-ІФ' },
  { id: 20, title: 'Ізмаї́л склад' },
  { id: 21, title: 'Краматорьск СТО Талисман' },
  { id: 22, title: 'Кременчуг СТО Шкода' },
  { id: 23, title: 'Кривий Рiг ТОВ "АСТ-КОМБІ' },
  { id: 24, title: 'Кропивницький склад' },
  { id: 25, title: 'Луцьк  Рено склад' },
  { id: 26, title: 'Львів склад' },
  { id: 27, title: 'Миколаїв склад Єкспрес авто' },
  { id: 29, title: 'Одеса склад Рено' },
  { id: 30, title: 'Полтава склад Стерх' },
  { id: 31, title: 'Рiвне склад Богдан авто' },
  { id: 32, title: 'Суми  склад Авто плюс' },
  { id: 33, title: 'Тернополь Склад' },
  { id: 34, title: 'Ужгород склад' },
  { id: 35, title: 'Харків склад' },
  { id: 36, title: 'Херсон склад Тойота центр' },
  { id: 37, title: 'Херсон склад Пежо' },
  { id: 38, title: 'Хмельницький склад  ПРАТ' },
  { id: 39, title: 'Черкаси склад Віанор' },
  { id: 40, title: 'Чернiгiв  склад АТЛ' },
  { id: 41, title: 'Чернівці  Магнат Авто склад' },
  { id: 42, title: 'Тип Топ' },
  { id: 43, title: 'Новий склад' },
  { id: 44, title: 'Шина-TireShop' },
  { id: 45, title: 'Шина-ТАЄРС1' },
  { id: 46, title: 'Шина-ТАЄРС2' },
  { id: 47, title: 'Таєрс Лізинг-1' },
  { id: 48, title: 'Таєрс Лізинг-2' },
  { id: 49, title: 'Шина-Асканія4334' }
];

export const CITIES = [
  { id: 760, title: 'Авдеевка' },
  { id: 761, title: 'Александрия' },
  { id: 762, title: 'Александровск' },
  { id: 763, title: 'Алмазная' },
  { id: 764, title: 'Алупка' },
  { id: 26, title: 'Алушта' },
  { id: 46, title: 'Алчевск' },
  { id: 765, title: 'Амвросиевка' },
  { id: 766, title: 'Ананьев' },
  { id: 767, title: 'Андрушевка' },
  { id: 768, title: 'Антрацит' },
  { id: 769, title: 'Апостолово' },
  { id: 770, title: 'Армянск' },
  { id: 771, title: 'Артемово' },
  { id: 772, title: 'Артемовск' },
  { id: 773, title: 'Арциз' },
  { id: 774, title: 'Ахтырка' },
  { id: 775, title: 'Балаклея' },
  { id: 776, title: 'Балта' },
  { id: 777, title: 'Бар' },
  { id: 778, title: 'Барвенково' },
  { id: 779, title: 'Бахмач' },
  { id: 780, title: 'Бахчисарай' },
  { id: 781, title: 'Баштанка' },
  { id: 783, title: 'Белгород-Днестровский' },
  { id: 784, title: 'Белз' },
  { id: 785, title: 'Белицкое' },
  { id: 786, title: 'Белогорск' },
  { id: 787, title: 'Белозерское' },
  { id: 788, title: 'Белополье' },
  { id: 789, title: 'Беляевка' },
  { id: 790, title: 'Бердичев' },
  { id: 791, title: 'Бердянськ' },
  { id: 792, title: 'Берегово' },
  { id: 793, title: 'Бережаны' },
  { id: 794, title: 'Березань' },
  { id: 795, title: 'Березовка' },
  { id: 796, title: 'Берестечко' },
  { id: 797, title: 'Берислав' },
  { id: 798, title: 'Бершадь' },
  { id: 782, title: 'Біла Церква' },
  { id: 799, title: 'Бобринец' },
  { id: 800, title: 'Бобрка' },
  { id: 801, title: 'Бобровица' },
  { id: 802, title: 'Богодухов' },
  { id: 803, title: 'Богуслав' },
  { id: 804, title: 'Болград' },
  { id: 805, title: 'Болехов' },
  { id: 806, title: 'Борзна' },
  { id: 807, title: 'Борислав' },
  { id: 808, title: 'Бориспіль' },
  { id: 859, title: 'Борщев' },
  { id: 860, title: 'Боярка' },
  { id: 861, title: 'Бровари' },
  { id: 862, title: 'Броды' },
  { id: 863, title: 'Брянка' },
  { id: 1310, title: 'Буковель' },
  { id: 864, title: 'Бурштын' },
  { id: 865, title: 'Бурынь' },
  { id: 866, title: 'Буск' },
  { id: 867, title: 'Бучач' },
  { id: 868, title: 'Валки' },
  { id: 1312, title: 'Варва' },
  { id: 869, title: 'Васильевка' },
  { id: 870, title: 'Васильков' },
  { id: 871, title: 'Ватутино' },
  { id: 872, title: 'Вахрушево' },
  { id: 873, title: 'Вашковцы' },
  { id: 874, title: 'Великие Мосты' },
  { id: 875, title: 'Верхнеднепровск' },
  { id: 876, title: 'Верховцево' },
  { id: 877, title: 'Вижница' },
  { id: 878, title: 'Вилково' },
  { id: 879, title: 'Винники' },
  { id: 880, title: 'Виноградов' },
  { id: 890, title: 'Вишгород' },
  { id: 881, title: 'Вишневое' },
  { id: 13, title: 'Вінниця' },
  { id: 882, title: 'Владимир-Волынский' },
  { id: 883, title: 'Вознесенск' },
  { id: 884, title: 'Волноваха' },
  { id: 885, title: 'Волочиск' },
  { id: 886, title: 'Волчанск' },
  { id: 887, title: 'Вольногорск' },
  { id: 888, title: 'Вольнянск' },
  { id: 889, title: 'Ворожба' },
  { id: 891, title: 'Гадяч' },
  { id: 892, title: 'Гайворон' },
  { id: 893, title: 'Гайсин' },
  { id: 894, title: 'Галич' },
  { id: 895, title: 'Геническ' },
  { id: 896, title: 'Герца' },
  { id: 897, title: 'Глиняны' },
  { id: 898, title: 'Глобино' },
  { id: 899, title: 'Глухов' },
  { id: 900, title: 'Гнивань' },
  { id: 901, title: 'Голая Пристань' },
  { id: 902, title: 'Горловка' },
  { id: 903, title: 'Горняк' },
  { id: 904, title: 'Городенка' },
  { id: 905, title: 'Городище' },
  { id: 906, title: 'Городня' },
  { id: 907, title: 'Городок' },
  { id: 908, title: 'Горохов' },
  { id: 909, title: 'Горское' },
  { id: 910, title: 'Гребенка' },
  { id: 911, title: 'Гуляйполе' },
  { id: 912, title: 'Дебальцево' },
  { id: 913, title: 'Деражня' },
  { id: 914, title: 'Дергачи' },
  { id: 915, title: 'Джанкой' },
  { id: 916, title: 'Дзержинск' },
  { id: 917, title: 'Димитров' },
  { id: 918, title: 'Днепродзержинск' },
  { id: 919, title: 'Днепрорудное' },
  { id: 4, title: 'Дніпро' },
  { id: 920, title: 'Добромиль' },
  { id: 921, title: 'Доброполье' },
  { id: 922, title: 'Докучаевск' },
  { id: 923, title: 'Долина' },
  { id: 924, title: 'Долинская' },
  { id: 3, title: 'Донецьк' },
  { id: 925, title: 'Дрогобыч' },
  { id: 926, title: 'Дружба' },
  { id: 927, title: 'Дружковка' },
  { id: 928, title: 'Дубляны' },
  { id: 929, title: 'Дубно' },
  { id: 930, title: 'Дубровица' },
  { id: 931, title: 'Дунаевцы' },
  { id: 932, title: 'Евпатория' },
  { id: 933, title: 'Енакиево' },
  { id: 934, title: 'Жашків' },
  { id: 935, title: 'Ждановка' },
  { id: 1354, title: 'Жденієво' },
  { id: 936, title: 'Желтые Воды' },
  { id: 937, title: 'Жидачив' },
  { id: 43, title: 'Житомир' },
  { id: 938, title: 'Жмеринка' },
  { id: 939, title: 'Жолква' },
  { id: 1366, title: 'Зазим`я' },
  { id: 940, title: 'Залещики' },
  { id: 11, title: 'Запоріжжя' },
  { id: 941, title: 'Заставна' },
  { id: 942, title: 'Збараж' },
  { id: 943, title: 'Зборов' },
  { id: 944, title: 'Звенигородка' },
  { id: 945, title: 'Здолбунов' },
  { id: 946, title: 'Зеленодольск' },
  { id: 947, title: 'Зеньков' },
  { id: 948, title: 'Зимогорье' },
  { id: 949, title: 'Змиев' },
  { id: 950, title: 'Знаменка' },
  { id: 951, title: 'Золотое' },
  { id: 952, title: 'Золотоноша' },
  { id: 953, title: 'Золочев' },
  { id: 954, title: 'Зоринск' },
  { id: 955, title: 'Зугрэс' },
  { id: 956, title: 'Измаил' },
  { id: 957, title: 'Изюм' },
  { id: 958, title: 'Изяслав' },
  { id: 959, title: 'Иловайск' },
  { id: 960, title: 'Ильинцы' },
  { id: 961, title: 'Ильичевск' },
  { id: 962, title: 'Ингулец' },
  { id: 963, title: 'Инкерман' },
  { id: 964, title: 'Ирпень' },
  { id: 965, title: 'Иршава' },
  { id: 966, title: 'Ичня' },
  { id: 23, title: 'Івано-Франківськ' },
  { id: 1358, title: 'ізкі' },
  { id: 1277, title: 'Ічня' },
  { id: 967, title: 'Кагарлык' },
  { id: 968, title: 'Калинівка' },
  { id: 969, title: 'Калуш' },
  { id: 970, title: 'Каменец-Подольский' },
  { id: 971, title: 'Каменка' },
  { id: 972, title: 'Каменка-Бугская' },
  { id: 973, title: 'Каменка-Днепровская' },
  { id: 974, title: 'Камень-Каширский' },
  { id: 975, title: 'Канев' },
  { id: 976, title: 'Карловка' },
  { id: 977, title: 'Каховка' },
  { id: 978, title: 'Керчь' },
  { id: 979, title: 'Киверцы' },
  { id: 1, title: 'Київ' },
  { id: 1321, title: 'Київ/Грінченко 18' },
  { id: 1308, title: 'Київ/Жуляни' },
  { id: 1302, title: 'Київ/Камишанська,4' },
  { id: 1323, title: 'КиїївЯмська 72' },
  { id: 980, title: 'Килия' },
  { id: 981, title: 'Кировск' },
  { id: 982, title: 'Кировское' },
  { id: 983, title: 'Кицмань' },
  { id: 1268, title: 'Кіровоград' },
  { id: 984, title: 'Кобеляки' },
  { id: 985, title: 'Ковель' },
  { id: 986, title: 'Кодыма' },
  { id: 987, title: 'Козятин' },
  { id: 988, title: 'Коломыя' },
  { id: 989, title: 'Комарно' },
  { id: 990, title: 'Комсомольск' },
  { id: 991, title: 'Комсомольское' },
  { id: 1269, title: 'Комуніст смт.' },
  { id: 992, title: 'Конотоп' },
  { id: 993, title: 'Константиновка' },
  { id: 994, title: 'Копычинцы' },
  { id: 995, title: 'Корец' },
  { id: 996, title: 'Коростень' },
  { id: 997, title: 'Коростышев' },
  { id: 998, title: 'Корсунь-Шевченковский' },
  { id: 999, title: 'Корюковка' },
  { id: 1000, title: 'Косов' },
  { id: 1001, title: 'Костополь' },
  { id: 1002, title: 'Котовск' },
  { id: 1363, title: 'Краковець' },
  { id: 1003, title: 'Краматорск' },
  { id: 1004, title: 'Красилов' },
  { id: 1005, title: 'Красноармейск' },
  { id: 1006, title: 'Красногоровка' },
  { id: 1007, title: 'Красноград' },
  { id: 1008, title: 'Краснодон' },
  { id: 1009, title: 'Красноперекопск' },
  { id: 1010, title: 'Красный Лиман' },
  { id: 1011, title: 'Красный Луч' },
  { id: 1012, title: 'Кременец' },
  { id: 1013, title: 'Кременная' },
  { id: 47, title: 'Кременчуг' },
  { id: 1322, title: 'Кременчук' },
  { id: 24, title: 'Кривий Ріг' },
  { id: 37, title: 'Крим' },
  { id: 1014, title: 'Кристиновка' },
  { id: 1015, title: 'Кролевец' },
  { id: 1327, title: 'Кропивницкий' },
  { id: 25, title: 'Кропивницький' },
  { id: 39, title: 'Кузнецовськ' },
  { id: 1016, title: 'Купянск' },
  { id: 1017, title: 'Курахово' },
  { id: 1018, title: 'Ладыжин' },
  { id: 1019, title: 'Лебедин' },
  { id: 1020, title: 'Лисичанск' },
  { id: 1021, title: 'Лозовая' },
  { id: 1022, title: 'Лохвица' },
  { id: 1233, title: 'Лубни' },
  { id: 42, title: 'Лубны' },
  { id: 8, title: 'Луганськ' },
  { id: 1023, title: 'Лутугино' },
  { id: 6, title: 'Луцьк' },
  { id: 2, title: 'Львів' },
  { id: 1342, title: 'Любашів' },
  { id: 1024, title: 'Любомль' },
  { id: 1025, title: 'Люботин' },
  { id: 1026, title: 'Макеевка' },
  { id: 1027, title: 'Малая Виска' },
  { id: 1028, title: 'Малин' },
  { id: 1029, title: 'Марганец' },
  { id: 50, title: 'Маріуполь' },
  { id: 1030, title: 'Марьинка' },
  { id: 1346, title: 'Меджибош' },
  { id: 45, title: 'Мелитополь' },
  { id: 1369, title: 'Мелітополь' },
  { id: 1031, title: 'Мена' },
  { id: 1032, title: 'Мерефа' },
  { id: 10, title: 'Миколаїв' },
  { id: 1033, title: 'Миргород' },
  { id: 1034, title: 'Мироновка' },
  { id: 1287, title: 'Миропіль' },
  { id: 1035, title: 'Миусинск' },
  { id: 1232, title: "Міжгір'я" },
  { id: 1036, title: 'Могилев-Подольский' },
  { id: 15, title: 'Молдова' },
  { id: 1037, title: 'Молодогвардейск' },
  { id: 1038, title: 'Молочанск' },
  { id: 1039, title: 'Монастыриска' },
  { id: 1040, title: 'Монастырище' },
  { id: 1041, title: 'Моршин' },
  { id: 1042, title: 'Моспино' },
  { id: 1043, title: 'Мостиска' },
  { id: 1044, title: 'Мукачево' },
  { id: 1045, title: 'Надворная' },
  { id: 1047, title: 'Немиров' },
  { id: 1048, title: 'Нетишин' },
  { id: 1049, title: 'Никополь' },
  { id: 1046, title: 'Ніжин' },
  { id: 1050, title: 'Новая Каховка' },
  { id: 1051, title: 'Новая Одесса' },
  { id: 1052, title: 'Новгород-Северский' },
  { id: 1053, title: 'Новоазовск' },
  { id: 1368, title: 'Новоалександрівка' },
  { id: 1054, title: 'Нововолынск' },
  { id: 1055, title: 'Новоград-Волынский' },
  { id: 1056, title: 'Новогродовка' },
  { id: 1057, title: 'Новоднестровск' },
  { id: 1374, title: 'Новодністровськ' },
  { id: 1076, title: 'Новодружеск' },
  { id: 1077, title: 'Новомиргород' },
  { id: 1078, title: 'Новомосковск' },
  { id: 1370, title: 'Новоолексіївка' },
  { id: 1079, title: 'Новоселица' },
  { id: 1080, title: 'Новоукраинка' },
  { id: 1081, title: 'Новояворовск' },
  { id: 1082, title: 'Новый Буг' },
  { id: 1083, title: 'Новый Роздол' },
  { id: 1084, title: 'Носовка' },
  { id: 48, title: 'Обухов' },
  { id: 1085, title: 'Овруч' },
  { id: 5, title: 'Одеса' },
  { id: 1086, title: 'Орджоникидзе' },
  { id: 1087, title: 'Орехов' },
  { id: 1088, title: 'Остер' },
  { id: 1089, title: 'Острог' },
  { id: 1090, title: 'Очаков' },
  { id: 1091, title: 'Павлоград' },
  { id: 1092, title: 'Первомайск' },
  { id: 1093, title: 'Первомайский' },
  { id: 1094, title: 'Перевальск' },
  { id: 1095, title: 'Перемышляны' },
  { id: 1096, title: 'Переяслав-Хмельницкий' },
  { id: 1097, title: 'Петровское' },
  { id: 1098, title: 'Пивденное' },
  { id: 1356, title: 'Пилипець' },
  { id: 1099, title: 'Пирятин' },
  { id: 1100, title: 'Погребище' },
  { id: 1101, title: 'Подгайцы' },
  { id: 1102, title: 'Подгородное' },
  { id: 1103, title: 'Пологи' },
  { id: 1104, title: 'Полонное' },
  { id: 36, title: 'Полтава' },
  { id: 1105, title: 'Помошная' },
  { id: 1106, title: 'Попасная' },
  { id: 1107, title: 'Почаев' },
  { id: 1373, title: 'Почуйки' },
  { id: 1108, title: 'Приволье' },
  { id: 9, title: 'Прилуки' },
  { id: 1109, title: 'Приморск' },
  { id: 1110, title: 'Пустомыты' },
  { id: 1111, title: 'Путивль' },
  { id: 1112, title: 'Пятихатки' },
  { id: 1113, title: 'Рава-Русская' },
  { id: 1114, title: 'Радехов' },
  { id: 1115, title: 'Радомышль' },
  { id: 1116, title: 'Радывылив' },
  { id: 1117, title: 'Раздельная' },
  { id: 1118, title: 'Рахов' },
  { id: 1119, title: 'Рени' },
  { id: 1285, title: 'Решетилівка' },
  { id: 1120, title: 'Ржищев' },
  { id: 22, title: 'Рівне' },
  { id: 1121, title: 'Ровеньки' },
  { id: 1122, title: 'Рогатин' },
  { id: 1123, title: 'Родинское' },
  { id: 1124, title: 'Рожище' },
  { id: 1125, title: 'Ромны' },
  { id: 1126, title: 'Рубіжне' },
  { id: 1127, title: 'Рудки' },
  { id: 1234, title: 'с. Зоря' },
  { id: 1276, title: 'с. Поляниця' },
  { id: 1280, title: 'Сазонівка' },
  { id: 1128, title: 'Саки' },
  { id: 1129, title: 'Самбор' },
  { id: 1130, title: 'Сарны' },
  { id: 1131, title: 'Свалява' },
  { id: 1132, title: 'Сватово' },
  { id: 1133, title: 'Свердловск' },
  { id: 1134, title: 'Светловодск' },
  { id: 1135, title: 'Светлодарск' },
  { id: 18, title: 'Севастополь' },
  { id: 1314, title: 'Северодонецьк' },
  { id: 1136, title: 'Северск' },
  { id: 1137, title: 'Селидово' },
  { id: 1138, title: 'Семеновка' },
  { id: 1139, title: 'Середина-Буда' },
  { id: 1140, title: 'Синельниково' },
  { id: 17, title: 'Сімферополь' },
  { id: 1141, title: 'Скадовск' },
  { id: 1142, title: 'Скалат' },
  { id: 1143, title: 'Сквира' },
  { id: 1144, title: 'Сколе' },
  { id: 1145, title: 'Славута' },
  { id: 1146, title: 'Славутич' },
  { id: 1147, title: 'Славяногорск' },
  { id: 1148, title: 'Славянск' },
  { id: 1149, title: 'Смела' },
  { id: 1231, title: 'смт. В.Бичків' },
  { id: 1150, title: 'Снежное' },
  { id: 1151, title: 'Снигиревка' },
  { id: 1152, title: 'Снятин' },
  { id: 1153, title: 'Сокаль' },
  { id: 1154, title: 'Сокиряны' },
  { id: 1155, title: 'Соледар' },
  { id: 1343, title: 'Соснівка' },
  { id: 1156, title: 'Сосновка' },
  { id: 1367, title: 'Старичі' },
  { id: 1157, title: 'Старобельск' },
  { id: 1158, title: 'Староконстантинов' },
  { id: 1159, title: 'Старый Крым' },
  { id: 1160, title: 'Старый Самбор' },
  { id: 1161, title: 'Стаханов' },
  { id: 1162, title: 'Стебник' },
  { id: 1163, title: 'Сторожинец' },
  { id: 1164, title: 'Стрый' },
  { id: 1165, title: 'Судак' },
  { id: 1166, title: 'Судовая вишня' },
  { id: 1167, title: 'Суми' },
  { id: 1168, title: 'Суходольск' },
  { id: 1169, title: 'Счастье' },
  { id: 1170, title: 'Таврийск' },
  { id: 1171, title: 'Тальное' },
  { id: 1172, title: 'Тараща' },
  { id: 1173, title: 'Татарбунары' },
  { id: 1174, title: 'Теплогорск' },
  { id: 1175, title: 'Теплодар' },
  { id: 1176, title: 'Теребовля' },
  { id: 1177, title: 'Терновка' },
  { id: 16, title: 'Тернопіль' },
  { id: 1178, title: 'Тетиев' },
  { id: 1179, title: 'Тисменница' },
  { id: 1180, title: 'Тлумач' },
  { id: 1181, title: 'Токмак' },
  { id: 1182, title: 'Торез' },
  { id: 19, title: 'Тростянец' },
  { id: 1235, title: 'Тростянець' },
  { id: 1183, title: 'Трускавец' },
  { id: 1377, title: 'Трускавець' },
  { id: 1184, title: 'Тульчин' },
  { id: 1185, title: 'Турка' },
  { id: 1186, title: 'Тячев' },
  { id: 1187, title: 'Углегорск' },
  { id: 1188, title: 'Угледар' },
  { id: 1189, title: 'Угнев' },
  { id: 1190, title: 'Ужгород' },
  { id: 1191, title: 'Узин' },
  { id: 1192, title: 'Украинка' },
  { id: 1193, title: 'Ульяновка' },
  { id: 49, title: 'Умань' },
  { id: 1194, title: 'Устилуг' },
  { id: 1195, title: 'Фастов' },
  { id: 38, title: 'Феодосія' },
  { id: 7, title: 'Харків' },
  { id: 1196, title: 'Харцизьк' },
  { id: 14, title: 'Херсон' },
  { id: 1197, title: 'Хирев' },
  { id: 1198, title: 'Хмельник' },
  { id: 1199, title: 'Хмельницкий' },
  { id: 1200, title: 'Ходоров' },
  { id: 1201, title: 'Хорол' },
  { id: 1202, title: 'Хоростков' },
  { id: 1203, title: 'Хотин' },
  { id: 1204, title: 'Хуст' },
  { id: 1205, title: 'Цюрупинск' },
  { id: 1230, title: 'Чабани смт.' },
  { id: 1206, title: 'Часов Яр' },
  { id: 1207, title: 'Червоноград' },
  { id: 1208, title: 'Червонозаводское' },
  { id: 1209, title: 'Червонопартизанск' },
  { id: 21, title: 'Черкаси' },
  { id: 20, title: 'Чернівці' },
  { id: 41, title: 'Чернігів' },
  { id: 1210, title: 'Чигирин' },
  { id: 1211, title: 'Чоп' },
  { id: 1212, title: 'Чортков' },
  { id: 1213, title: 'Чугуев' },
  { id: 1214, title: 'Шаргород' },
  { id: 1215, title: 'Шахтерск' },
  { id: 1216, title: 'Шепетівка' },
  { id: 1217, title: 'Шостка' },
  { id: 1218, title: 'Шпола' },
  { id: 1219, title: 'Щелкино' },
  { id: 1220, title: 'Щорс' },
  { id: 1221, title: 'Энергодар' },
  { id: 40, title: 'Южно Украинск' },
  { id: 1222, title: 'Южноукраинск' },
  { id: 1223, title: 'Южный' },
  { id: 1224, title: 'Юнокоммунаровск' },
  { id: 1225, title: 'Яворов' },
  { id: 1226, title: 'Яготин' },
  { id: 44, title: 'Ялта' },
  { id: 1227, title: 'Ямполь' },
  { id: 1228, title: 'Яремча' },
  { id: 1229, title: 'Ясиноватая' }
];

export const FleetTiresActionList = [
  { id: 1, title: 'Зберігати' },
  { id: 2, title: 'Видача клієнту' },
  { id: 3, title: 'Утилізація' }
];

export const Defects = [
  { id: 0, title: '-' },
  {
    id: 1,
    title: 'Зношення протектора вище норми'
  },
  { id: 5, title: 'грижа' },
  { id: 12, title: 'Латка на плечі' },
  { id: 13, title: 'Латка верхня' },
  { id: 14, title: 'Латка бокова' },
  { id: 15, title: 'Нерівномірне  зношення шини' },
  {
    id: 16,
    title: 'Внутрішня кільцева зношеність'
  },
  { id: 17, title: 'Зовнішня кільцева зношеність' },
  { id: 22, title: 'Латка' },
  { id: 23, title: 'Шнурок' }
];

export const MONTHS = [
  { id: 0, title: '' },
  { id: '01', title: 'Січень' },
  { id: '02', title: 'Лютий' },
  { id: '03', title: 'Березень' },
  { id: '04', title: 'Квітень' },
  { id: '05', title: 'Травень' },
  { id: '06', title: 'Червень' },
  { id: '07', title: 'Липень' },
  { id: '08', title: 'Серпень' },
  { id: '09', title: 'Вересень' },
  { id: '10', title: 'Жовтень' },
  { id: '11', title: 'Листопад' },
  { id: '12', title: 'Грудень' }
];
