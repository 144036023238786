import { ApiPath } from 'common/enums/http/api-path';
import { http } from 'services/http';
import { storage } from 'services/storage';

export const login = async (body: { email: string; password: string }) => {
  const { access, refresh } = await http.post<{
    access: string;
    refresh: string;
  }>({
    url: ApiPath.AUTH_JWT_CREATE,
    options: {
      body,
      needAuthorization: false
    }
  });
  if (access && refresh) {
    storage.setAccessTokens(access);
    storage.setRefreshToken(refresh);
  }
};
