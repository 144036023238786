import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getFleetTiresPurchasesApi,
  updateFleetTiresPurchasesApi
} from 'api/fleet-tires-purchases';

export enum FleetTiresPurchasesAction {
  GET_FLEET_TIRES_PURCHASES = 'fleet-purchases/get-fleet-tires-purchases',
  UPDATE_FLEET_TIRES_PURCHASES = 'fleet-purchases/update-fleet-tires-purchases'
}

export const getFleetTiresPurchasesAction = createAsyncThunk(
  FleetTiresPurchasesAction.GET_FLEET_TIRES_PURCHASES,
  getFleetTiresPurchasesApi
);

export const updateFleetTiresPurchasesAction = createAsyncThunk(
  FleetTiresPurchasesAction.UPDATE_FLEET_TIRES_PURCHASES,
  updateFleetTiresPurchasesApi
);
