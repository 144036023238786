import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUserMe } from 'api/user';
import { getApiError } from 'helpers/get-api-error';

enum CategoryActions {
  GET_USER_ME = 'user/get-user-me'
}

export const fetchUserMe = createAsyncThunk(
  CategoryActions.GET_USER_ME,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getUserMe();

      return res;
    } catch (err) {
      return rejectWithValue(getApiError(err));
    }
  }
);
