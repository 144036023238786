import styled from 'styled-components';

export const BtnSecondaryStyled = styled.button<{ $minWidth?: string }>`
  padding: 10px 20px;
  min-width: ${(p) => p.$minWidth || 'unset'};
  height: 40px;
  border-radius: 6px;
  background: ${(p) => p.theme.colors.white};
  cursor: pointer;
  color: ${(p) => p.theme.colors.secondaryDark};
  border: 1px solid ${(p) => p.theme.colors.secondaryDark};
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 125%;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    height: 48px;
  }
`;
