import { ApiPath } from 'common/enums/http/api-path';
import type { ID, ResponseWithPagination } from 'common/types';
import type { FleetTiresPurchases } from 'models/fleet-tires-purchases';
import { http } from 'services/http';

export const getFleetTiresPurchasesApi = (params?: string) =>
  http.get<ResponseWithPagination<FleetTiresPurchases>>(
    `${ApiPath.FLEET_TIRES_PURCHASES}${params}`
  );

export const updateFleetTiresPurchasesApi = ({
  id,
  body
}: {
  id: ID;
  body: Record<string, unknown>;
}) =>
  http.patch<FleetTiresPurchases>({
    url: `${ApiPath.FLEET_TIRES_PURCHASES}${id}/`,
    options: {
      body
    }
  });
