import { createAsyncThunk } from '@reduxjs/toolkit';
import { getServiceTableLastUpdate } from 'api/service-table-last-update';
import {
  getTiresMovementsApi,
  updateTiresMovementsApi
} from 'api/tires-movements';

export enum TiresMovementsAction {
  GET_TIRES_MOVEMENTS = 'movements/get-tires-movements',
  GET_LAST_UPDATE = 'movements/get-last-update',
  UPDATE_TIRES_MOVEMENTS = 'movements/update-tires-movements'
}

export const getTiresMovementsAction = createAsyncThunk(
  TiresMovementsAction.GET_TIRES_MOVEMENTS,
  getTiresMovementsApi
);

export const getLastUpdateAction = createAsyncThunk(
  TiresMovementsAction.GET_LAST_UPDATE,
  getServiceTableLastUpdate
);

export const updateTiresMovementsAction = createAsyncThunk(
  TiresMovementsAction.UPDATE_TIRES_MOVEMENTS,
  updateTiresMovementsApi
);
