import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from './user/reducer';
import { companiesReducer } from './companies/reducer';
import { tiresMovementsReducer } from './tires-movements.ts/reducer';
import { tiresReplacementsReducer } from './tires-replacements/reducer';
import { menuReducer } from './menu/reducer';
import { fleetTiresReplacementsReducer } from './fleet-tires-replacements/reducer';
import { notificationsReducer } from './notifications/reducer';
import { fleetTiresPurchasesReducer } from './fleet-tires-purchases/reducer';
import { contactsReducer } from './contacts/reducer';
import { movementsHistoryReducer } from './tires-movements-history/reducer';
import { fleetVehicleReducer } from './fleet-vehicle/reducer';
import { tiresDisposalsReducer } from './tires-disposals/reducer';
import { reportsReducer } from './reports/reducer';
import { replacementsHistoryReducer } from './tires-replacements-history/reducer';
import { serviceUpdatesReducer } from './service-all-updates/reducer';
import { fleetUpdatesReducer } from './fleet-all-updates/reducer';
import { disposalsHistoryReducer } from './tires-disposals-history/reducer';
import { fleetReplacementsHistoryReducer } from './fleet-replacements-history/reducer';
import { fleetPurchasesHistoryReducer } from './fleet-purchases-history/reducer';
import { fleetNotificationsReducer } from './fleet-notifications/reducer';

export const store = configureStore({
  reducer: {
    user: userReducer,
    companies: companiesReducer,
    tiresMovements: tiresMovementsReducer,
    tiresReplacements: tiresReplacementsReducer,
    fleetTiresReplacements: fleetTiresReplacementsReducer,
    menu: menuReducer,
    notifications: notificationsReducer,
    fleetTiresPurchases: fleetTiresPurchasesReducer,
    contacts: contactsReducer,
    movementsHistory: movementsHistoryReducer,
    replacementsHistory: replacementsHistoryReducer,
    disposalsHistory: disposalsHistoryReducer,
    fleetReplacementsHistory: fleetReplacementsHistoryReducer,
    fleetPurchasesHistory: fleetPurchasesHistoryReducer,
    fleetVehicle: fleetVehicleReducer,
    tiresDisposals: tiresDisposalsReducer,
    reports: reportsReducer,
    serviceUpdates: serviceUpdatesReducer,
    fleetUpdates: fleetUpdatesReducer,
    fleetNotifications: fleetNotificationsReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
