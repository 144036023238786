import { ApiPath } from 'common/enums/http/api-path';
import type { ID, ResponseWithPagination } from 'common/types';
import type {
  FleetNotificationSettings,
  FleetNotifications
} from 'models/fleet-notifications';
import { http } from 'services/http';

export const getFleetNotificationsApi = (params?: string) =>
  http.get<ResponseWithPagination<FleetNotifications>>(
    `${ApiPath.FLEET_NOTIFICATIONS}${params || ''}`
  );

export const getFleetNotificationSettingsApi = () =>
  http.get<ResponseWithPagination<FleetNotificationSettings>>(
    ApiPath.FLEET_NOTIFICATIONS_SETTINGS
  );

export const createFleetNotif = (data: { date: string; user: ID }) =>
  http.post({
    url: ApiPath.FLEET_NOTIFICATIONS_SETTINGS,
    options: {
      body: data
    }
  });

export const updateFleetNotif = ({
  id,
  body
}: {
  id: ID;
  body: Partial<FleetNotificationSettings>;
}) =>
  http.patch({
    url: `${ApiPath.FLEET_NOTIFICATIONS_SETTINGS}${id}/`,
    options: {
      body
    }
  });

export const readAllNotifApi = () =>
  http.post({
    url: ApiPath.FLEET_NOTIFICATIONS_READ_ALL,
    options: {
      body: {
        read_all: true
      },
      isNotJson: true
    }
  });
