import { useAppDispatch, useTypedSelector } from 'store/store';
import type { ReactNode } from 'react';
import { BurgerSvg } from 'components/svg/burger-svg';
import { toggleMenu } from 'store/menu/reducer';
import { Notifications } from 'components/notifications';
import { Burger, LeftSide, NavbarStyled, RightSide, UserName } from './style';

interface NavbarProps {
  children?: ReactNode;
}

export const Navbar = ({ children }: NavbarProps) => {
  const dispatch = useAppDispatch();
  const { user } = useTypedSelector((state) => state.user);

  const handleToggleMenu = () => {
    dispatch(toggleMenu());
  };

  return (
    <NavbarStyled>
      <Burger onClick={handleToggleMenu}>
        <BurgerSvg />
      </Burger>
      <LeftSide>{children}</LeftSide>
      <RightSide>
        {user?.role.id === 2 && <Notifications />}
        <UserName>{user?.company_name || user?.username}</UserName>
      </RightSide>
    </NavbarStyled>
  );
};
