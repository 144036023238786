import styled from 'styled-components';

export const NoDataStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.colors.black};
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  overflow: auto;
  padding-bottom: 100px;
`;
