import { createSlice } from '@reduxjs/toolkit';
import type { TiresReplacementsHistory } from 'models/tires-replacements-history';
import type { ResponseWithPagination } from 'common/types';
import { getReplacementsHistoryAction } from './actions';

interface ReplacementsHistoryState {
  replacementsHistory: ResponseWithPagination<TiresReplacementsHistory>;
  isLoading: boolean;
}

const initialState: ReplacementsHistoryState = {
  replacementsHistory: { count: 0, next: null, previous: null, results: [] },
  isLoading: false
};

const replacementsHistorySlice = createSlice({
  name: 'replacements-history',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getReplacementsHistoryAction.fulfilled,
      (state, { payload }) => {
        state.replacementsHistory = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(getReplacementsHistoryAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getReplacementsHistoryAction.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const replacementsHistoryReducer = replacementsHistorySlice.reducer;
