import type { ID } from 'common/types';
import type { HeaderItem } from 'components/data-table';
import type { ReactNode } from 'react';

export interface ReplacementsTable {
  id: ID;
  doc_number: string;
  vehicle_plate: string;
  vehicle_model: string;
  tire_model: string;
  tire_location: ReactNode;
  season: string;
  size: string;
  diameter: string;
  action: string;
  tread_depth: ReactNode;
  defect: ReactNode;
  service_comment: ReactNode;
  confirmed: ReactNode;
  scheduled_replacement_date: string | null;
  contact_name: string;
  contact_phone: string;
  print: ReactNode;
}

export const tableHeaders: HeaderItem<ReplacementsTable>[] = [
  { id: 'doc_number', title: '№ документу', width: '135px' },
  { id: 'vehicle_plate', title: 'ДНЗ', width: '190px' },
  { id: 'vehicle_model', title: 'Марка/ модель', width: '154px' },
  {
    id: 'tire_location',
    title: (
      <>
        розміщення
        <br />
        Шин
      </>
    ),
    width: '221px'
  },
  { id: 'season', title: 'Сезон', width: '81px' },
  { id: 'tire_model', title: 'Марка / модель шин', width: '196px' },
  { id: 'size', title: 'Розмір', width: '87px' },
  { id: 'diameter', title: 'Діаметр', width: '96px' },
  { id: 'action', title: 'Дія з шинами', width: '140px' },
  { id: 'tread_depth', title: 'ВП', width: '52px', editable: true },
  { id: 'defect', title: 'дефекти, якщо є', width: '165px', editable: true },
  {
    id: 'service_comment',
    title: 'Коментар СТО',
    width: '144px',
    editable: true
  },
  { id: 'confirmed', title: 'підтвердження', width: '156px', editable: true },
  { id: 'print', title: 'Друк', width: '70px' },
  {
    id: 'scheduled_replacement_date',
    title: (
      <>
        Планова дата <br /> шиномонтажу
      </>
    ),
    width: '151px'
  },
  { id: 'contact_name', title: 'Контактна особа', width: '188px' },
  { id: 'contact_phone', title: 'Номер телефону', width: '188px' }
];
