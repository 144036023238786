import styled from 'styled-components';

export const ContactsStyled = styled.div`
  padding: 0 30px;
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 472px));
  align-content: start;
  gap: 20px;
  overflow: auto;

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    grid-template-columns: 1fr;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    padding: 0 16px;
  }
`;

export const ContactsFooter = styled.div`
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${(p) => p.theme.colors.stroke};
  grid-column: span 2;
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    grid-column: span 1;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
    padding-right: 8px;
  }
`;

export const FooterItem = styled.div`
  &:nth-child(2) {
    display: flex;
    justify-content: space-between;
    padding-right: 11%;

    @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
      display: block;
      padding-right: 0;
    }
  }
`;

export const FooterSubItem = styled.div`
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
  }
`;

export const FooterTitle = styled.div`
  color: ${(p) => p.theme.colors.black};
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
`;

export const FooterSubtitle = styled.span`
  margin-left: 10px;
  color: ${(p) => p.theme.colors.secondaryDark};
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    margin-left: 6px;
  }
`;

export const FooterData = styled.a`
  margin-top: 14px;
  display: block;
  color: ${(p) => p.theme.colors.secondaryDark};
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
`;
