import { ApiPath } from 'common/enums/http/api-path';
import type { ID, ResponseWithPagination } from 'common/types';
import type { TiresMovements } from 'models/tires-movements';
import { http } from 'services/http';

export const getTiresMovementsApi = (params?: string) =>
  http.get<ResponseWithPagination<TiresMovements>>(
    `${ApiPath.TIRES_MOVEMENTS}${params || ''}`
  );

export const updateTiresMovementsApi = ({
  id,
  body
}: {
  id: ID;
  body: Partial<TiresMovements>;
}) =>
  http.patch<TiresMovements>({
    url: `${ApiPath.TIRES_MOVEMENTS}${id}/`,
    options: {
      body
    }
  });
