export const ImportSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M9.62651 11.1666C9.49984 11.1666 9.37318 11.2132 9.27318 11.3132L7.91984 12.6666L6.56651 11.3132C6.37318 11.1199 6.05318 11.1199 5.85984 11.3132C5.66651 11.5066 5.66651 11.8266 5.85984 12.0199L7.56651 13.7266C7.75984 13.9199 8.07984 13.9199 8.27318 13.7266L9.97984 12.0199C10.1732 11.8266 10.1732 11.5066 9.97984 11.3132C9.87984 11.2132 9.75318 11.1666 9.62651 11.1666Z"
      fill="white"
    />
    <path
      d="M7.91992 6.04666C7.64659 6.04666 7.41992 6.27333 7.41992 6.54666V13.3267C7.41992 13.6 7.64659 13.8267 7.91992 13.8267C8.19326 13.8267 8.41992 13.6 8.41992 13.3267V6.54666C8.41992 6.26666 8.19326 6.04666 7.91992 6.04666Z"
      fill="white"
    />
    <path
      d="M8.00033 2.16667C4.56699 2.16667 2.16699 4.56667 2.16699 8C2.16699 8.27333 2.39366 8.5 2.66699 8.5C2.94033 8.5 3.16699 8.27333 3.16699 8C3.16699 5.15333 5.15366 3.16667 8.00033 3.16667C10.847 3.16667 12.8337 5.15333 12.8337 8C12.8337 8.27333 13.0603 8.5 13.3337 8.5C13.607 8.5 13.8337 8.27333 13.8337 8C13.8337 4.56667 11.4337 2.16667 8.00033 2.16667Z"
      fill="white"
    />
  </svg>
);
