import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTiresDisposalsHistoryApi } from 'api/tires-disposals-history';

export enum TiresDisposalsHistoryAction {
  GET_DISPOSALS_HISTORY = 'disposals-history/get-disposals-history'
}

export const getDisposalsHistoryAction = createAsyncThunk(
  TiresDisposalsHistoryAction.GET_DISPOSALS_HISTORY,
  getTiresDisposalsHistoryApi
);
