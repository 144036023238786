import { createAsyncThunk } from '@reduxjs/toolkit';
import type { UpdateTiresReplacements } from 'api/tires-replacements';
import {
  getTiresReplacementsApi,
  updateTiresReplacementsApi
} from 'api/tires-replacements';

export enum TiresReplacementsAction {
  GET_TIRES_REPPLACEMENTS = 'replacements/get-tires-replacements',
  UPDATE_TIRES_REPPLACEMENTS = 'replacements/update-tires-replacements'
}

export const getTiresReplacementsAction = createAsyncThunk(
  TiresReplacementsAction.GET_TIRES_REPPLACEMENTS,
  getTiresReplacementsApi
);

export const updateTiresReplacementsAction = createAsyncThunk(
  TiresReplacementsAction.UPDATE_TIRES_REPPLACEMENTS,
  async (data: UpdateTiresReplacements, { rejectWithValue }) => {
    try {
      return await updateTiresReplacementsApi(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
