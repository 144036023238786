import styled from 'styled-components';

export const FilterStyled = styled.div`
  margin-left: 28px;
  margin-right: 107px;
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: ${(p) => p.theme.breakpoints.xl}) {
    margin-right: 30px;
  }
`;
