import { Navbar } from 'components/navbar';
import { hasOwnProperty } from 'helpers/has-own-property';
import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();

  const getError = () => {
    if (
      error &&
      typeof error === 'object' &&
      hasOwnProperty(error, 'statusText') &&
      typeof error.statusText === 'string'
    ) {
      return error.statusText;
    }
    if (
      error &&
      typeof error === 'object' &&
      hasOwnProperty(error, 'message') &&
      typeof error.message === 'string'
    ) {
      return error.message;
    }

    return '';
  };

  return (
    <>
      <Navbar />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '100px'
        }}
        id="error-page"
      >
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{getError()}</i>
        </p>
      </div>
    </>
  );
}
