import type { ChangeEventHandler, FormEventHandler } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ArrowSvg } from 'components/svg/arrow-svg';
import {
  NextLink,
  PaginationBtnContainer,
  PaginationForm,
  PaginationInput,
  PaginationLink,
  PaginationStyled,
  PrevLink,
  SearchBtn,
  Truncable
} from './style';

interface PaginationProps {
  count: number;
}

export const SIZE = 50;

const Pagination = ({ count }: PaginationProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get('page') || '1';
  const [searchPage, setSearchPage] = useState('');
  const pageCount = Math.ceil((count || SIZE) / SIZE);

  const handleChangePage = (page: string) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (searchPage && !isNaN(+searchPage)) {
      handleChangePage(searchPage);
    }
  };

  const handleChangeSearchPage: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (+e.target.value <= pageCount) {
      setSearchPage(e.target.value);
    }
  };

  const generatePageBtns = () => {
    const pageBtns = [];
    if (pageCount <= 7) {
      for (let i = 1; i <= pageCount; i++) {
        pageBtns.push(i);
      }

      return pageBtns;
    }
    if (
      +currentPage <= 2 ||
      +currentPage === pageCount - 1 ||
      +currentPage === pageCount
    ) {
      for (let i = 1; i <= 3; i++) {
        pageBtns.push(i);
      }
      pageBtns.push('...');
      pageBtns.push(pageCount - 2);
      pageBtns.push(pageCount - 1);
      pageBtns.push(pageCount);

      return pageBtns;
    }
    if (+currentPage > 2 && +currentPage <= pageCount - 2) {
      pageBtns.push(1);
      pageBtns.push('...');
      pageBtns.push(+currentPage - 1);
      pageBtns.push(+currentPage);
      pageBtns.push(+currentPage + 1);
      pageBtns.push('...');
      pageBtns.push(pageCount);

      return pageBtns;
    } else {
      pageBtns.push(1);
      pageBtns.push('...');
      pageBtns.push(pageCount - 3);
      pageBtns.push(pageCount - 2);
      pageBtns.push(pageCount - 1);
      pageBtns.push(pageCount);
    }

    return pageBtns;
  };

  const handleSetSearchPage: FormEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    searchParams.set('page', searchPage);
    setSearchParams(searchParams);
  };

  return (
    <PaginationStyled>
      <PaginationBtnContainer>
        <PrevLink
          onClick={() =>
            handleChangePage(+currentPage === 1 ? '1' : `${+currentPage - 1}`)
          }
        >
          <ArrowSvg />
        </PrevLink>
        {generatePageBtns().map((item, i) =>
          item === '...' ? (
            <Truncable key={i}>...</Truncable>
          ) : (
            <PaginationLink
              onClick={() => handleChangePage(`${item}`)}
              key={i}
              $isActive={+currentPage === +item}
            >
              {item}
            </PaginationLink>
          )
        )}
        <NextLink
          onClick={() =>
            handleChangePage(
              +currentPage === pageCount
                ? `${pageCount}`
                : `${+currentPage + 1}`
            )
          }
        >
          <ArrowSvg />
        </NextLink>
        <PaginationForm onSubmit={onSubmit}>
          <PaginationInput
            value={searchPage}
            onChange={handleChangeSearchPage}
            type="number"
          />
          <SearchBtn onSubmit={handleSetSearchPage} type="submit">
            Пошук
          </SearchBtn>
        </PaginationForm>
      </PaginationBtnContainer>
    </PaginationStyled>
  );
};

export { Pagination };
