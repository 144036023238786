import { DataTable } from 'components/data-table';
import { Navbar } from 'components/navbar';
import { Subheader } from 'components/subheader';
import { useScrollHeight } from 'hooks/use-scroll-height';
import { useEffect, useRef } from 'react';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { NoData } from 'components/no-data';
import {
  getFleetVehicleAction,
  updateFleetVehicleAction
} from 'store/fleet-vehicle/actions';
import type { FleetVehicle } from 'models/fleet-vehicle';
import { TableSelect } from 'components/table-select';
import { CITIES, TiresStorage } from 'common/constants';
import type { OptionItem } from 'common/types';
import { UpdatedAt } from 'components/updated-at';
import { dateToHeaderFormat } from 'helpers/date';
import { Loader } from 'components/loader';
import { updateNotif } from 'store/notifications/reducer';
import { useSearchParams } from 'react-router-dom';
import { getFleetAllUpdatesAction } from 'store/fleet-all-updates/actions';
import { SIZE } from 'components/paginations';
import type { FleetVehicleTable } from './data';
import { tableHeaders } from './data';
import { DriverName } from './driver-name';
import { DriverPhone } from './driver-phone';

export const FleetVehiclePage = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const tableRef = useRef(null);
  const { scrollHeight } = useScrollHeight(tableRef);

  const { fleetVehicle, isLoading } = useTypedSelector(
    (state) => state.fleetVehicle
  );
  const { fleetUpdates } = useTypedSelector((state) => state.fleetUpdates);

  const handleSelect = (
    item: FleetVehicle,
    key: string,
    option: OptionItem
  ) => {
    dispatch(
      updateFleetVehicleAction({
        id: item.id,
        body: {
          [key]: option.id,
          driver_first_name: item.driver_first_name,
          driver_last_name: item.driver_last_name,
          driver_phone: item.driver_phone
        }
      })
    );
  };

  useEffect(() => {
    const page = searchParams.get('page');
    dispatch(getFleetVehicleAction(`?page=${page || 1}`));
    dispatch(updateNotif({ fleetVehicle: false }));
    dispatch(getFleetAllUpdatesAction());
  }, [dispatch, searchParams]);

  const transformToTableFormat = (vehicles: FleetVehicle[]) =>
    vehicles.map((item) => ({
      ...item,
      driver_last_name: (
        <DriverName
          field="driver_last_name"
          id={item.id}
          value={item.driver_last_name}
        />
      ),
      driver_first_name: (
        <DriverName
          field="driver_first_name"
          id={item.id}
          value={item.driver_first_name}
        />
      ),
      driver_phone: <DriverPhone id={item.id} value={item.driver_phone} />,
      vehicle_location: (
        <TableSelect
          options={CITIES}
          initialValue={item.vehicle_location}
          onSelect={(value) => handleSelect(item, 'vehicle_location', value)}
        />
      ),
      tires_storage_city: (
        <TableSelect
          options={TiresStorage}
          initialValue={item.tires_storage_city}
          onSelect={(value) => handleSelect(item, 'tires_storage_city', value)}
        />
      )
    }));

  return (
    <>
      <Navbar />
      <Subheader title="Авто">
        {' '}
        {!!fleetUpdates.results.length && !!fleetVehicle.results.length && (
          <UpdatedAt absoluteAlign>
            {dateToHeaderFormat(fleetUpdates.results[0].fleet_vehicle_table)}
          </UpdatedAt>
        )}
      </Subheader>
      <div ref={tableRef} style={{ position: 'relative' }}>
        {fleetVehicle.results.length ? (
          <div>
            <DataTable<FleetVehicleTable>
              headers={tableHeaders}
              data={transformToTableFormat(fleetVehicle.results)}
              checkedList={[]}
              setCheckedList={() => {}}
              actions={[]}
              withOutCheck
              customHeight={
                fleetVehicle.count >= SIZE ? scrollHeight - 64 : scrollHeight
              }
              count={fleetVehicle.count}
            />
          </div>
        ) : isLoading ? (
          <Loader isBig place={{ left: 'calc(50% - 16px)', top: '100px' }} />
        ) : (
          <NoData height={`${scrollHeight}px`} />
        )}
      </div>
    </>
  );
};
