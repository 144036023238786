export const ExportSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M6.37349 4.83342C6.50016 4.83342 6.62682 4.78675 6.72682 4.68675L8.08016 3.33342L9.43349 4.68675C9.62682 4.88008 9.94682 4.88008 10.1402 4.68675C10.3335 4.49342 10.3335 4.17342 10.1402 3.98008L8.43349 2.27342C8.24016 2.08008 7.92016 2.08008 7.72682 2.27342L6.02016 3.98008C5.82682 4.17342 5.82682 4.49342 6.02016 4.68675C6.12016 4.78675 6.24682 4.83342 6.37349 4.83342Z"
      fill="#292D32"
    />
    <path
      d="M8.08008 9.95334C8.35341 9.95334 8.58008 9.72667 8.58008 9.45334V2.67334C8.58008 2.40001 8.35341 2.17334 8.08008 2.17334C7.80674 2.17334 7.58008 2.40001 7.58008 2.67334V9.45334C7.58008 9.73334 7.80674 9.95334 8.08008 9.95334Z"
      fill="#292D32"
    />
    <path
      d="M7.99967 13.8333C11.433 13.8333 13.833 11.4333 13.833 8C13.833 7.72667 13.6063 7.5 13.333 7.5C13.0597 7.5 12.833 7.72667 12.833 8C12.833 10.8467 10.8463 12.8333 7.99967 12.8333C5.15301 12.8333 3.16634 10.8467 3.16634 8C3.16634 7.72667 2.93968 7.5 2.66634 7.5C2.39301 7.5 2.16634 7.72667 2.16634 8C2.16634 11.4333 4.56634 13.8333 7.99967 13.8333Z"
      fill="#292D32"
    />
  </svg>
);
