import type { ReactNode } from 'react';
import { BtnAddStyled } from './style';

interface HeaderBtnrops {
  children: ReactNode;
  onClick: () => void;
  icon?: ReactNode;
  isActive?: boolean;
}

export const HeaderBtn = ({
  children,
  icon: Icon,
  onClick,
  isActive
}: HeaderBtnrops) => (
  <BtnAddStyled $isActive={isActive} onClick={onClick}>
    {Icon}
    {children}
  </BtnAddStyled>
);
