import styled from 'styled-components';

export const PopupStyled = styled.div`
  padding: 16px;
  position: fixed;
  left: 0;
  width: 100vw;
  top: 0;
  height: 100vh;
  background: ${(p) => p.theme.colors.popupOverlay};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 10;
  overflow: auto;
`;

export const PopupContent = styled.div<{ $maxContentWidth?: string }>`
  padding: 30px;
  width: 100%;
  max-width: ${(p) => p.$maxContentWidth || '448px'};
  background: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.stroke};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  border-radius: 15px;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    padding: 30px 20px;
  }
`;

export const PopupHeader = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid ${(p) => p.theme.colors.stroke};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PopupTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.black};
`;

export const PopupClose = styled.div`
  width: 14px;
  height: 14px;
  cursor: pointer;
  svg {
    max-width: 100%;
  }
`;
