import { updateFleetTiresReplacementsApi } from 'api/fleet-tires-replacements';
import type { ID } from 'common/types';
import { CellInput } from 'components/cell-input';
import { EditableCell } from 'components/editable-cell';
import { updateFleetReplacementRecipient } from 'store/fleet-tires-replacements/reducer';
import { useAppDispatch } from 'store/store';

interface RecipientProps {
  value: string;
  id: ID;
  disabled?: boolean;
}

export const Recipient = ({ id, value, disabled }: RecipientProps) => {
  const dispatch = useAppDispatch();

  const handleChange = (value: string) => {
    dispatch(updateFleetReplacementRecipient({ id, recipient: value }));
  };

  const handleBlur = () => {
    updateFleetTiresReplacementsApi({
      id,
      body: {
        recipient_data: value
      }
    });
  };

  return (
    <EditableCell withOutPaddingTop disabled={disabled}>
      <CellInput
        onBlur={handleBlur}
        disabled={disabled}
        value={value}
        setValue={handleChange}
      />
    </EditableCell>
  );
};
