import { useRef, useState } from 'react';
import { useOutsideClick } from 'hooks/use-outside-click';
import { StringCutter } from 'components/string-cutter';
import { ArrowSvg } from 'components/svg/arrow-svg';
import { useTheme } from 'styled-components';
import {
  SelectHeader,
  SelectHeaderTitle,
  SelectOptionItem,
  SelectOptions,
  SelectWrapper
} from './style';

interface SelectProps {
  value: string;
  setValue: (value: string) => void;
  options: string[];
  placeholder?: string;
  minWidth?: string;
}

export const DateSelect = ({
  value,
  options,
  setValue,
  placeholder,
  minWidth
}: SelectProps) => {
  const ref = useRef(null);
  const theme = useTheme();
  useOutsideClick(ref, () => setShowOptions(false));
  const [showOptions, setShowOptions] = useState(false);

  const handleShowOptions = () => {
    setShowOptions((state) => !state);
  };

  const handleSelect = (value: string) => {
    setValue(value);
    setShowOptions(false);
  };

  return (
    <SelectWrapper ref={ref}>
      <SelectHeader
        $minWidth={minWidth}
        $isOpen={showOptions}
        onClick={handleShowOptions}
        $isSelected={!!(value && !showOptions)}
      >
        <SelectHeaderTitle $isSelected={!!(value && !showOptions)}>
          <StringCutter $lines="1">
            {value || <span>{placeholder}</span>}
          </StringCutter>
        </SelectHeaderTitle>
        <ArrowSvg
          fill={value && !showOptions ? theme.colors.white : theme.colors.black}
        />
      </SelectHeader>
      {showOptions && (
        <SelectOptions>
          {options.map((item, i) => (
            <SelectOptionItem onClick={() => handleSelect(item)} key={i}>
              {item || '-'}
            </SelectOptionItem>
          ))}
        </SelectOptions>
      )}
    </SelectWrapper>
  );
};
