import { useEffect } from 'react';
import { storage } from 'services/storage';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { fetchUserMe } from 'store/user/actions';
import { logOut } from 'store/user/reducer';

export const useCheckAuth = () => {
  const dispatch = useAppDispatch();
  const { isAuthorized } = useTypedSelector((state) => state.user);

  const needCheckAuth = isAuthorized === 'needToCheck';

  useEffect(() => {
    if (needCheckAuth) {
      if (storage.getAccessToken()) {
        dispatch(fetchUserMe());
      } else {
        dispatch(logOut());
        storage.logOut();
      }
    }
  }, [needCheckAuth, dispatch]);
};
