export const DownloadSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M0.364028 18.0534L0.364028 18.0534C0.365153 19.1879 0.816297 20.2755 1.61845 21.0777C2.42061 21.8798 3.50824 22.3309 4.64266 22.3321H4.6427H19.2628H19.2629C20.3973 22.3309 21.4849 21.8798 22.2871 21.0777C23.0892 20.2755 23.5404 19.1879 23.5415 18.0534V18.0534V15.184C23.5415 14.9484 23.4479 14.7224 23.2813 14.5559C23.1147 14.3893 22.8888 14.2957 22.6532 14.2957C22.4176 14.2957 22.1916 14.3893 22.0251 14.5559C21.8585 14.7224 21.7649 14.9484 21.7649 15.184V18.0534C21.7649 18.717 21.5013 19.3534 21.032 19.8226C20.5628 20.2918 19.9264 20.5554 19.2628 20.5554H4.6427C3.97912 20.5554 3.34272 20.2918 2.8735 19.8226C2.40427 19.3534 2.14067 18.717 2.14067 18.0534V15.184C2.14067 14.9484 2.04707 14.7224 1.88048 14.5559C1.71389 14.3893 1.48794 14.2957 1.25235 14.2957C1.01675 14.2957 0.790803 14.3893 0.624211 14.5559C0.457619 14.7224 0.364028 14.9484 0.364028 15.184L0.364028 18.0534Z"
      fill="black"
      stroke="black"
      strokeWidth="0.0961625"
    />
    <path
      d="M16.1891 10.7826L16.189 10.7824L16.1855 10.7859L12.9066 14.0648L12.9066 1.70333C12.9066 1.46773 12.813 1.24178 12.6464 1.07519C12.4798 0.908598 12.2539 0.815011 12.0183 0.815011C11.7827 0.815011 11.5568 0.908598 11.3902 1.07519C11.2236 1.24178 11.13 1.46773 11.13 1.70333L11.13 14.0522L7.85111 10.7733C7.76858 10.6908 7.6706 10.6253 7.56277 10.5806C7.45493 10.536 7.33936 10.513 7.22264 10.513C7.10592 10.513 6.99035 10.536 6.88252 10.5806C6.77469 10.6253 6.67671 10.6908 6.59418 10.7733C6.51164 10.8558 6.44618 10.9538 6.40151 11.0616C6.35685 11.1695 6.33386 11.2851 6.33386 11.4018C6.33386 11.5185 6.35685 11.6341 6.40151 11.7419C6.44618 11.8497 6.51164 11.9477 6.59418 12.0302L11.3877 16.8238L11.3877 16.8238L11.3883 16.8243C11.5112 16.9436 11.664 17.0276 11.8306 17.0676L11.8306 17.0677L11.8331 17.0681C11.9767 17.0946 12.1245 17.0862 12.2641 17.0438C12.4038 17.0013 12.5312 16.9261 12.6358 16.8242L12.6362 16.8238L17.4298 12.0429L17.4299 12.043L17.4328 12.0396C17.5738 11.8699 17.6466 11.6538 17.6371 11.4333C17.6275 11.2129 17.5363 11.0039 17.3811 10.847C17.2259 10.6902 17.0179 10.5967 16.7975 10.5848C16.5772 10.5729 16.3603 10.6434 16.1891 10.7826Z"
      fill="black"
      stroke="black"
      strokeWidth="0.0961625"
    />
  </svg>
);
