import type { ReactNode } from 'react';
import { BtnSecondaryStyled } from './style';

interface BtnSecondaryProps {
  children: ReactNode;
  onClick?: () => void;
  type?: 'submit' | 'button';
  minWidth?: string;
}

export const BtnSecondary = ({
  children,
  onClick,
  type = 'button',
  minWidth
}: BtnSecondaryProps) => (
  <BtnSecondaryStyled $minWidth={minWidth} type={type} onClick={onClick}>
    {children}
  </BtnSecondaryStyled>
);
