import { updateTiresReplacementsApi } from 'api/tires-replacements';
import type { ID } from 'common/types';
import { CellInput } from 'components/cell-input';
import { EditableCell } from 'components/editable-cell';
import { useAppDispatch } from 'store/store';
import { updateReplacementTreadDepth } from 'store/tires-replacements/reducer';

interface TreadDepthProps {
  value: string;
  id: ID;
  disabled?: boolean;
}

export const TreadDepth = ({ id, value, disabled }: TreadDepthProps) => {
  const dispatch = useAppDispatch();

  const handleChangeTreadDepth = (value: string) => {
    dispatch(updateReplacementTreadDepth({ id, treadDepth: value }));
  };

  const handleBlur = () => {
    updateTiresReplacementsApi({
      id,
      body: {
        tread_depth: value
      }
    });
  };

  return (
    <EditableCell withOutPaddingTop disabled={disabled}>
      <CellInput
        value={value}
        type="number"
        setValue={handleChangeTreadDepth}
        disabled={disabled}
        onBlur={handleBlur}
      />
    </EditableCell>
  );
};
