import { createAsyncThunk } from '@reduxjs/toolkit';
import type { CreateCompany } from 'api/companies';
import { createCompany, getCompanies, updateCompany } from 'api/companies';

enum CompaniesActions {
  GET_COMPANIES = 'companies/get-companies',
  CREATE_COMPANY = 'companies/create-company',
  UPDATE_COMPANY = 'companies/update-company'
}

export const fetchCompanies = createAsyncThunk(
  CompaniesActions.GET_COMPANIES,
  getCompanies
);

export const createCompanyAction = createAsyncThunk(
  CompaniesActions.CREATE_COMPANY,
  async (data: CreateCompany, { rejectWithValue }) => {
    try {
      return await createCompany(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateCompanyAction = createAsyncThunk(
  CompaniesActions.UPDATE_COMPANY,
  updateCompany
);
