import styled from 'styled-components';

export const SidebarStyled = styled.aside<{ $show: boolean }>`
  width: 232px;
  min-width: 232px;
  height: 100%;
  border-right: 1px solid ${(p) => p.theme.colors.stroke};

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    position: fixed;
    left: 0;
    top: 0;
    transform: ${(p) => (p.$show ? 'translateY(0)' : 'translateY(-101%)')};
    width: 100%;
    min-width: unset;
    min-height: 375px;
    height: unset;
    box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.15);
    border-right: none;
    z-index: 1000;
    background: ${(p) => p.theme.colors.white};
    transition: transform 0.5s;
  }
`;

export const Logo = styled.div`
  width: 100%;
  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    max-width: 179px;
    margin: 0 auto;
  }
`;

export const MenuWrapper = styled.div`
  height: calc(100vh - 161px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    height: unset;
    display: block;
  }
`;

export const Menu = styled.ul`
  margin-top: 28px;
`;

export const CloseMenu = styled.div`
  display: none;
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    display: block;
    position: absolute;
    left: 23px;
    top: 20px;
  }
`;

export const Exit = styled.div`
  padding: 10px 20px 0;
  color: ${(p) => p.theme.colors.secondaryDark};
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.2px;
  cursor: pointer;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    font-size: 16px;
    padding: 10px 16px 0;
  }
`;

export const SearchMobWrapper = styled.div`
  display: none;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    display: block;
    padding: 30px 16px;
  }
`;
