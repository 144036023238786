import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFleetPurchasesHistoryApi } from 'api/fleet-purchases-history';

export enum FleetPurchasesHistoryAction {
  GET_FLEET_PURCHASES_HISTORY = 'fleet-purchases-history/get-fleet-purchases-history'
}

export const getFleetPurchasesHistoryAction = createAsyncThunk(
  FleetPurchasesHistoryAction.GET_FLEET_PURCHASES_HISTORY,
  getFleetPurchasesHistoryApi
);
