import styled from 'styled-components';

export const BtnAddStyled = styled.button<{ $isActive?: boolean }>`
  padding: 10px 12px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid ${(p) => p.theme.colors.secondaryDark};
  background: ${(p) =>
    p.$isActive ? p.theme.colors.secondaryDark : p.theme.colors.white};
  color: ${(p) =>
    p.$isActive ? p.theme.colors.white : p.theme.colors.secondaryDark};
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.2px;
  transition: all 0.2s;
  svg {
    margin-right: 10px;
    path {
      fill: ${(p) =>
        p.$isActive ? p.theme.colors.white : p.theme.colors.secondaryDark};
    }
  }
  &:hover {
    border: 1px solid ${(p) => p.theme.colors.black};
    color: ${(p) =>
      p.$isActive ? p.theme.colors.white : p.theme.colors.black};
    transition: all 0.2s;
    svg {
      path {
        fill: ${(p) =>
          p.$isActive ? p.theme.colors.white : p.theme.colors.black};
      }
    }
  }
`;
