import { updateFleetVehicleApi } from 'api/fleet-vehicle';
import type { ID } from 'common/types';
import { CellInput } from 'components/cell-input';
import { EditableCell } from 'components/editable-cell';
import type { FleetVehicle } from 'models/fleet-vehicle';
import { updateFleetVehicle } from 'store/fleet-vehicle/reducer';
import { useAppDispatch } from 'store/store';

interface DriverNameProps {
  value: string;
  id: ID;
  disabled?: boolean;
  field: keyof FleetVehicle;
}

export const DriverName = ({ id, value, disabled, field }: DriverNameProps) => {
  const dispatch = useAppDispatch();

  const handleChange = (value: string) => {
    dispatch(updateFleetVehicle({ id, [field]: value }));
  };

  const handleBlur = () => {
    updateFleetVehicleApi({
      id,
      body: {
        [field]: value
      }
    });
  };

  return (
    <EditableCell withOutPaddingTop disabled={disabled}>
      <CellInput onBlur={handleBlur} value={value} setValue={handleChange} />
    </EditableCell>
  );
};
