import type { ID } from 'common/types';
import type { HeaderItem } from 'components/data-table';
import type { ReactNode } from 'react';

export interface MovementsTable {
  id: ID;
  tire_model: string;
  vehicle_plate: string;
  scheduled_moving_date: string | null;
  scheduled_replacement_date: string | null;
  service_comment: ReactNode;
  confirmed: ReactNode;
  confirmed_at: string | null;
  direction: string;
  doc_number: string;
  carrier: string;
}

export const tableHeaders: HeaderItem<MovementsTable>[] = [
  { id: 'direction', title: 'Напрямок', width: '221px' },
  { id: 'doc_number', title: '№ декларації', width: '136px' },
  { id: 'carrier', title: 'Перевізник', width: '151px' },
  { id: 'tire_model', title: 'Марка / модель шин', width: '308px' },
  { id: 'vehicle_plate', title: 'ДНЗ', width: '96px' },
  {
    id: 'scheduled_moving_date',
    title: (
      <>
        Планова дата <br /> переміщення
      </>
    ),
    width: '144px'
  },
  {
    id: 'scheduled_replacement_date',
    title: (
      <>
        Планова дата <br /> заміни шин
      </>
    ),
    width: '144px'
  },
  {
    id: 'service_comment',
    title: 'Коментар сто',
    width: '271px',
    editable: true
  },
  {
    id: 'confirmed',
    title: 'отримано / відправлено',
    width: '157px',
    editable: true
  },
  { id: 'confirmed_at', title: 'Дата отримання/відправлення', width: '170px' }
];
