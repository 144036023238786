import { BellSvg } from 'components/svg/bell-svg';
import { useEffect, useRef, useState } from 'react';
import { SettingsNotifSvg } from 'components/svg/settings-notif-svg';
import { useOutsideClick } from 'hooks/use-outside-click';
import { ArrowSvg } from 'components/svg/arrow-svg';
import { DateSelect } from 'components/date-select';
import { MONTHS } from 'common/constants';
import { BtnSubmit } from 'components/btn-submit';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { getFleetNotificationsAction } from 'store/fleet-notifications/actions';
import { dateTableFormat, withLeadingZero } from 'helpers/date';
import {
  createFleetNotif,
  getFleetNotificationSettingsApi,
  readAllNotifApi,
  updateFleetNotif
} from 'api/fleet-notifications';
import { updateCount } from 'store/fleet-notifications/reducer';
import {
  BackIcon,
  DateContainer,
  FlexContainer,
  NotifContent,
  NotifContentHeader,
  NotifCount,
  NotifIcon,
  NotifTitle,
  NotificationsStyled,
  ReadAll,
  SettingsIcon,
  SettingsSubmitWrapper,
  Text
} from './style';

export const Notifications = () => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const { fleetNotifications, count } = useTypedSelector(
    (state) => state.fleetNotifications
  );
  const { user } = useTypedSelector((state) => state.user);
  const [showNotif, setShowNotif] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [date, setDate] = useState({ year: '', month: '', day: '' });

  const handleReadAll = () => {
    readAllNotifApi()
      .then(() => {
        dispatch(updateCount(0));
      })
      .catch((err) => console.log(err));
  };

  useOutsideClick(ref, () => {
    setShowNotif(false);
    setShowSettings(false);
    if (showNotif) {
      handleReadAll();
    }
  });

  useEffect(() => {
    if (showNotif && count) {
      dispatch(getFleetNotificationsAction());
    }
  }, [showNotif, dispatch, count]);

  useEffect(() => {
    dispatch(getFleetNotificationsAction())
      .unwrap()
      .then((res) =>
        dispatch(
          updateCount(res.results.filter((item) => !item.is_read).length)
        )
      );
  }, [dispatch]);

  const handleCloseSettings = () => {
    setShowSettings(false);
    setDate({ day: '', month: '', year: '' });
  };

  const handleCreateNotif = () => {
    getFleetNotificationSettingsApi().then((res) => {
      if (res.count) {
        updateFleetNotif({
          id: res.results[0].id,
          body: {
            date: `${date.year}-${
              MONTHS.find((item) => item.title === date.month)?.id || '01'
            }-${withLeadingZero(+date.day)}`
          }
        })
          .then(() => {
            handleCloseSettings();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        createFleetNotif({
          date: `${date.year}-${
            MONTHS.find((item) => item.title === date.month)?.id || '01'
          }-${withLeadingZero(+date.day)}`,
          user: user?.id || ''
        })
          .then(() => {
            handleCloseSettings();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  return (
    <NotificationsStyled ref={ref}>
      <NotifIcon
        $isActive={showNotif || !!count}
        onClick={() => setShowNotif((state) => !state)}
      >
        <BellSvg />
      </NotifIcon>
      {showNotif && (
        <NotifContent $isSettings={showSettings}>
          {showSettings ? (
            <div style={{ paddingTop: '20px' }}>
              <FlexContainer>
                <BackIcon onClick={handleCloseSettings}>
                  <ArrowSvg fill="#D4002A" />
                </BackIcon>
                <NotifCount>Налаштування сповіщень</NotifCount>
              </FlexContainer>
              <DateContainer>
                <DateSelect
                  options={new Array(31).fill('-').map((_, i) => `${i + 1}`)}
                  value={date.day}
                  setValue={(day) => setDate((state) => ({ ...state, day }))}
                  minWidth="85px"
                  placeholder="День"
                />
                <DateSelect
                  options={MONTHS.map((item) => item.title)}
                  value={date.month}
                  setValue={(month) =>
                    setDate((state) => ({ ...state, month }))
                  }
                  minWidth="120px"
                  placeholder="Місяць"
                />
                <DateSelect
                  options={new Array(3)
                    .fill('-')
                    .map((_, i) => `${new Date().getFullYear() + 2 - i}`)}
                  value={date.year}
                  setValue={(year) => setDate((state) => ({ ...state, year }))}
                  minWidth="85px"
                  placeholder="Рік"
                />
              </DateContainer>
              {date.day && date.year && date.month ? (
                <SettingsSubmitWrapper>
                  <BtnSubmit onClick={handleCreateNotif}>
                    Зберегти зміни
                  </BtnSubmit>
                </SettingsSubmitWrapper>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div>
              <NotifContentHeader $isEmpty={!fleetNotifications.results.length}>
                <FlexContainer>
                  <NotifCount>
                    {fleetNotifications.results.length
                      ? `+${count} сповіщень`
                      : '+0 сповіщень'}
                  </NotifCount>
                  <SettingsIcon onClick={() => setShowSettings(true)}>
                    <SettingsNotifSvg />
                  </SettingsIcon>
                </FlexContainer>
                {!!count && (
                  <ReadAll onClick={handleReadAll}>Прочитати всі</ReadAll>
                )}
              </NotifContentHeader>
              {fleetNotifications.results.map((item) => (
                <div key={item.id}>
                  {' '}
                  <NotifTitle>
                    {item.title}
                    <span>{dateTableFormat(item.created_at)}</span>
                  </NotifTitle>
                  <Text>{item.message}</Text>
                </div>
              ))}
            </div>
          )}
        </NotifContent>
      )}
    </NotificationsStyled>
  );
};
