export const UpdatedSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M13.3333 15.6257H6.66659C6.27996 15.6252 5.9093 15.4714 5.63591 15.198C5.36252 14.9246 5.20872 14.5539 5.20825 14.1673V13.334C5.20825 13.1682 5.2741 13.0093 5.39131 12.892C5.50852 12.7748 5.66749 12.709 5.83325 12.709C5.99901 12.709 6.15798 12.7748 6.27519 12.892C6.3924 13.0093 6.45825 13.1682 6.45825 13.334V14.1673C6.45836 14.2225 6.48034 14.2755 6.51939 14.3145C6.55844 14.3536 6.61136 14.3755 6.66659 14.3757H13.3333C13.3885 14.3755 13.4414 14.3536 13.4804 14.3145C13.5195 14.2755 13.5415 14.2225 13.5416 14.1673V13.334C13.5416 13.1682 13.6074 13.0093 13.7246 12.892C13.8419 12.7748 14.0008 12.709 14.1666 12.709C14.3323 12.709 14.4913 12.7748 14.6085 12.892C14.7257 13.0093 14.7916 13.1682 14.7916 13.334V14.1673C14.7911 14.5539 14.6373 14.9246 14.3639 15.198C14.0905 15.4714 13.7199 15.6252 13.3333 15.6257Z"
      fill="#D4002A"
    />
    <path
      d="M9.99988 12.2917C9.83413 12.2916 9.67519 12.2258 9.55798 12.1086C9.44078 11.9914 9.37492 11.8324 9.37488 11.6667V7.5C9.37488 7.33424 9.44073 7.17527 9.55794 7.05806C9.67515 6.94085 9.83412 6.875 9.99988 6.875C10.1656 6.875 10.3246 6.94085 10.4418 7.05806C10.559 7.17527 10.6249 7.33424 10.6249 7.5V11.6667C10.6248 11.8324 10.559 11.9914 10.4418 12.1086C10.3246 12.2258 10.1656 12.2916 9.99988 12.2917Z"
      fill="#D4002A"
    />
    <path
      d="M9.99996 12.863C9.65188 12.8625 9.31546 12.7375 9.05147 12.5106L6.67639 10.4745C6.55056 10.3666 6.47275 10.2131 6.46008 10.0478C6.44741 9.88253 6.50092 9.71899 6.60884 9.59315C6.71676 9.46732 6.87024 9.38951 7.03553 9.37685C7.20081 9.36418 7.36436 9.41769 7.49019 9.5256L9.86446 11.5609C9.90264 11.5927 9.95081 11.6101 10.0005 11.6099C10.0502 11.6098 10.0983 11.5921 10.1363 11.5601L12.5097 9.52559C12.6356 9.41767 12.7991 9.36416 12.9644 9.37683C13.1297 9.3895 13.2832 9.46731 13.3911 9.59314C13.499 9.71897 13.5525 9.88252 13.5398 10.0478C13.5272 10.2131 13.4494 10.3666 13.3235 10.4745L10.9493 12.5098C10.6852 12.7371 10.3484 12.8624 9.99996 12.863Z"
      fill="#D4002A"
    />
    <path
      d="M17.5 18.9577H2.49996C2.11333 18.9572 1.74267 18.8034 1.46928 18.53C1.19589 18.2566 1.0421 17.886 1.04163 17.4993V2.49935C1.0421 2.11272 1.19589 1.74206 1.46928 1.46867C1.74267 1.19528 2.11333 1.04149 2.49996 1.04102H8.74996C8.97643 1.04148 9.19969 1.09449 9.4022 1.19588C9.6047 1.29727 9.7809 1.44428 9.91695 1.62532L11.9169 4.29052C11.9362 4.31648 11.9613 4.33757 11.9902 4.35212C12.0191 4.36667 12.0509 4.37428 12.0833 4.37435H17.5C17.8866 4.37482 18.2572 4.52861 18.5306 4.802C18.804 5.07539 18.9578 5.44605 18.9583 5.83268V17.4993C18.9578 17.886 18.804 18.2566 18.5306 18.53C18.2572 18.8034 17.8866 18.9572 17.5 18.9577ZM2.49996 2.29102C2.44474 2.29112 2.39181 2.3131 2.35276 2.35215C2.31372 2.3912 2.29173 2.44413 2.29163 2.49935V17.4993C2.29173 17.5546 2.31372 17.6075 2.35276 17.6465C2.39181 17.6856 2.44474 17.7076 2.49996 17.7077H17.5C17.5552 17.7076 17.6081 17.6856 17.6472 17.6465C17.6862 17.6075 17.7082 17.5546 17.7083 17.4993V5.83268C17.7082 5.77746 17.6862 5.72453 17.6472 5.68548C17.6081 5.64644 17.5552 5.62445 17.5 5.62435H12.0833C11.8568 5.62389 11.6336 5.57088 11.4311 5.46948C11.2286 5.36809 11.0523 5.22109 10.9163 5.04004L8.91638 2.37484C8.89708 2.34889 8.87198 2.32779 8.84309 2.31324C8.8142 2.29869 8.78231 2.29108 8.74996 2.29102H2.49996Z"
      fill="#65615D"
    />
  </svg>
);
