export const CloseMenuSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_436_60058)">
      <path
        d="M0.976745 19.9999C0.783573 19.9999 0.594729 19.9427 0.434101 19.8354C0.273472 19.7281 0.148276 19.5755 0.074347 19.3971C0.000418327 19.2186 -0.0189212 19.0222 0.0187748 18.8328C0.0564707 18.6433 0.149509 18.4693 0.28612 18.3327L18.333 0.285822C18.5162 0.102657 18.7646 -0.000244141 19.0236 -0.000244141C19.2827 -0.000244141 19.5311 0.102657 19.7142 0.285822C19.8974 0.468987 20.0003 0.717412 20.0003 0.976447C20.0003 1.23548 19.8974 1.48391 19.7142 1.66707L1.66737 19.7139C1.57676 19.8047 1.4691 19.8767 1.35058 19.9258C1.23207 19.9749 1.10502 20.0001 0.976745 19.9999Z"
        fill="#D4002A"
      />
      <path
        d="M19.0236 19.9999C18.8953 20.0001 18.7682 19.9749 18.6497 19.9258C18.5312 19.8767 18.4236 19.8047 18.3329 19.7139L0.286066 1.66707C0.102901 1.48391 0 1.23548 0 0.976447C0 0.717412 0.102901 0.468987 0.286066 0.285822C0.469231 0.102657 0.717657 -0.000244141 0.976691 -0.000244141C1.23573 -0.000244141 1.48415 0.102657 1.66732 0.285822L19.7142 18.3327C19.8508 18.4693 19.9438 18.6433 19.9815 18.8328C20.0192 19.0222 19.9999 19.2186 19.926 19.3971C19.852 19.5755 19.7268 19.7281 19.5662 19.8354C19.4056 19.9427 19.2167 19.9999 19.0236 19.9999Z"
        fill="#D4002A"
      />
    </g>
    <defs>
      <clipPath id="clip0_436_60058">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
