import { AppRoute } from 'common/enums/app/app-route';
import { PublickRoute } from 'page-guard/public-route';
import { createBrowserRouter } from 'react-router-dom';
import { PrivateRoute } from 'page-guard/private-route';
import { LogIn } from './log-in';
import { HomePage } from './home';
import ErrorPage from './error-page';
import { MovementsHistoryPage } from './tires-movements/movements-history';
import { TiresReplacementsPage } from './tires-replacements';
import { FleetTiresPurchasesPage } from './fleet-tires-purchases';
import { AvisContactsPage } from './avis-contacts';
import { FleetVehiclePage } from './fleet-vehicle';
import { TiresDisposalsPage } from './tires-disposals';
import { ReportsPage } from './reports';
import { TiresReplacementsHistoryPage } from './tires-replacements/tires-replacements-history';
import { TiresDisposalsHistoryPage } from './tires-disposals/tires-disposals-history';
import { FleetReplacementsHistoryPage } from './fleet-tires-replacements/fleet-replacements-history';
import { FleetPurchasesHistoryPage } from './fleet-tires-purchases/fleet-purchases-history';

export const router = createBrowserRouter([
  {
    path: AppRoute.ROOT,
    element: <PrivateRoute component={HomePage} />,
    errorElement: <PrivateRoute component={ErrorPage} />
  },
  {
    path: AppRoute.LOG_IN,
    element: <PublickRoute component={LogIn} />
  },
  {
    path: AppRoute.MOVEMENTS_HISTORY,
    element: <PrivateRoute component={MovementsHistoryPage} />
  },
  {
    path: AppRoute.REPLACEMENTS_HISTORY,
    element: <PrivateRoute component={TiresReplacementsHistoryPage} />
  },
  {
    path: AppRoute.UTILIZATION_HISTORY,
    element: <PrivateRoute component={TiresDisposalsHistoryPage} />
  },
  {
    path: AppRoute.FLEET_REPLACEMENTS_HISTORY,
    element: <PrivateRoute component={FleetReplacementsHistoryPage} />
  },
  {
    path: AppRoute.FLEET_PURCHASES_HISTORY,
    element: <PrivateRoute component={FleetPurchasesHistoryPage} />
  },
  {
    path: AppRoute.TIRES_REPLACEMENTS,
    element: <PrivateRoute component={TiresReplacementsPage} />
  },
  {
    path: AppRoute.FLEET_TIRES_PURCHASASES,
    element: <PrivateRoute component={FleetTiresPurchasesPage} />
  },
  {
    path: AppRoute.FLEET_CONNECT,
    element: <PrivateRoute component={AvisContactsPage} />
  },
  {
    path: AppRoute.FLEET_AUTO,
    element: <PrivateRoute component={FleetVehiclePage} />
  },
  {
    path: AppRoute.UTILIZATION,
    element: <PrivateRoute component={TiresDisposalsPage} />
  },
  {
    path: AppRoute.REMAINING,
    element: <PrivateRoute component={ReportsPage} />
  }
]);
