import { Input } from 'components/input';
import type { Dispatch, FormEventHandler, SetStateAction } from 'react';
import { useState } from 'react';
import { Select } from 'components/select';
import type { OptionItem } from 'common/types';
import { BtnGroup } from 'components/btn-group';
import { useAppDispatch } from 'store/store';
import { createCompanyAction } from 'store/companies/actions';
import { toast } from 'react-toastify';
import { getApiError } from 'helpers/get-api-error';
import { useSearchParams } from 'react-router-dom';
import { createCompany } from 'api/companies';
import { AddUserForm } from './style';

interface AddUserProps {
  setShow: Dispatch<SetStateAction<boolean>>;
}

export const AddUser = ({ setShow }: AddUserProps) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [role, setRole] = useState<OptionItem>({ id: '', title: '' });
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const page = searchParams.get('page');

    if (page && +page > 1) {
      createCompany({
        company_name: name,
        company_role: role.id,
        email,
        company_id: companyId
      })
        .then(() => {
          searchParams.delete('page');
          setSearchParams(searchParams);
          setShow(false);
        })
        .catch((err) => {
          toast(getApiError(err), { type: 'error' });
        });
    } else {
      dispatch(
        createCompanyAction({
          company_name: name,
          company_role: role.id,
          email,
          company_id: companyId
        })
      )
        .unwrap()
        .then(() => {
          setShow(false);
        })
        .catch((err) => {
          toast(getApiError(err), { type: 'error' });
        });
    }
  };

  return (
    <AddUserForm onSubmit={handleSubmit}>
      <Input
        type="text"
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
        label="Логін (назва компанії)"
        placeholder="Введіть назву"
      />
      <Input
        type="email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        label="Електронна пошта"
        placeholder="Введіть email"
      />
      <Input
        type="text"
        value={companyId}
        onChange={(e) => {
          setCompanyId(e.target.value);
        }}
        label="Ідентифікатор компанії (ЄДРПОУ/ІПН)"
        placeholder="Введіть (ЄДРПОУ/ІПН)"
      />
      <Select
        value={role}
        setValue={setRole}
        label="Роль"
        options={[
          { id: 1, title: 'Кабінет СТО' },
          { id: 2, title: 'Фліт менеджер' }
        ]}
      />
      <BtnGroup
        disabled={!role.id || !name || !email || !companyId}
        onCancel={() => setShow(false)}
      />
    </AddUserForm>
  );
};
