import type { Dispatch, MouseEventHandler, ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import type { ID } from 'common/types';
import { Pagination, SIZE } from 'components/paginations';
import { Loader } from 'components/loader';
import { useSearchParams } from 'react-router-dom';
import { CheckBox } from '../checkbox';
import {
  Cell,
  Row,
  SubTitle,
  TableActions,
  TableActionsCount,
  TableActionsItem,
  TableActionsWrapper,
  TableStyled,
  TableWrapper,
  TBody,
  Th,
  THead
} from './style';
import { defaultStyle, duration, transitionStyles } from './transition';

export interface HeaderItem<T> {
  id: keyof T;
  title: ReactNode;
  width?: string;
  maxWidth?: string;
  subtitle?: string;
  editable?: boolean;
  count?: number;
}

interface TableProps<T> {
  headers: HeaderItem<T>[];
  data: T[];
  checkedList: number[];
  setCheckedList: Dispatch<React.SetStateAction<number[]>>;
  customHeight?: number;
  actions: {
    title: string;
    handler?: () => void;
  }[];
  count?: number;
  mt?: string;
  withOutCheck?: boolean;
  isFetching?: boolean;
  stickyColumns?: number;
}

export const DataTable = <
  T extends {
    id: ID;
    to_response?: boolean;
    isHeader?: boolean;
  }
>({
  data,
  checkedList,
  setCheckedList,
  headers,
  customHeight,
  actions,
  mt,
  withOutCheck,
  isFetching,
  stickyColumns,
  count
}: TableProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const isCheckedAll = data.every((item) => checkedList.includes(+item.id));
  const [tableScrolled, setTableScrolled] = useState(false);
  const [searchParams] = useSearchParams();
  const currentPage = searchParams.get('page');

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0);
    }
  }, [currentPage]);

  const handleChackAll = (checked: boolean) => {
    if (checked) {
      setCheckedList((state) => {
        const updatedList = Array.from(
          new Set([...data.map((item) => +item.id), ...state])
        );

        return updatedList;
      });
    } else {
      setCheckedList((state) =>
        state.filter((item) => !data.map((item) => item.id).includes(item))
      );
    }
  };

  const handleChecked = (checked: boolean, id: number) => {
    if (checked) {
      setCheckedList((state) => [...state, id]);
    } else {
      setCheckedList((state) => state.filter((item) => item !== id));
    }
  };

  const handleClickCheckbox: MouseEventHandler<HTMLTableCellElement> = (e) => {
    e.stopPropagation();
  };

  const handleTableScroll = () => {
    if (stickyColumns && ref.current && ref.current.scrollLeft > 10) {
      setTableScrolled(true);
    } else {
      setTableScrolled(false);
    }
  };

  return (
    <TableWrapper
      ref={ref}
      mt={mt}
      style={{
        height: customHeight ? `${customHeight}px` : 'calc(100% - 282px)'
      }}
      onScroll={handleTableScroll}
    >
      {isFetching && (
        <Loader isBig place={{ left: 'calc(50% - 16px)', top: '100px' }} />
      )}
      <Transition
        in={!!checkedList.length}
        timeout={duration}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <TableActionsWrapper
            style={{
              ...defaultStyle,
              ...transitionStyles[state]
            }}
          >
            <TableActions>
              <TableActionsCount>
                {checkedList.length} обрано{' '}
              </TableActionsCount>
              {actions.map(({ title, handler }, i) => (
                <TableActionsItem onClick={handler} key={i}>
                  {title}
                </TableActionsItem>
              ))}
            </TableActions>
          </TableActionsWrapper>
        )}
      </Transition>
      <TableStyled $isFetching={isFetching}>
        <THead>
          <Row>
            {!withOutCheck && (
              <Th
                onClick={handleClickCheckbox}
                $showActions={!!checkedList.length}
              >
                <CheckBox checked={isCheckedAll} setChecked={handleChackAll} />
              </Th>
            )}
            {headers.map((item) => (
              <Th
                $stickyColumns={stickyColumns}
                $minWidth={
                  item.id === 'description' || item.id === 'categories'
                    ? '300px'
                    : 'unset'
                }
                $showActions={!!checkedList.length}
                key={item.id as string}
                $isEmpty={!item.title}
                style={item.width ? { minWidth: item.width } : undefined}
                $maxWidth={item.maxWidth}
              >
                {item.title}
                {item.subtitle && <SubTitle>{item.subtitle}</SubTitle>}
              </Th>
            ))}
          </Row>
        </THead>
        <TBody>
          {data.map((field) => (
            <Row key={field.id} checked={checkedList.includes(+field.id)}>
              {!withOutCheck && (
                <Cell onClick={handleClickCheckbox}>
                  <CheckBox
                    checked={checkedList.includes(+field.id)}
                    setChecked={(checked) => handleChecked(checked, +field.id)}
                  />
                </Cell>
              )}
              {headers.map((item) => (
                <Cell
                  $isGeneratePassword={item.id === 'generatePassword'}
                  $isEditable={
                    (!field.isHeader && item.editable) || item.id === 'print'
                  }
                  $stickyColumns={stickyColumns}
                  $tableScrolled={tableScrolled}
                  key={item.id as string}
                >
                  {field[item.id] as ReactNode}
                </Cell>
              ))}
            </Row>
          ))}
        </TBody>
      </TableStyled>
      {count && count > SIZE ? <Pagination count={count || 0} /> : ''}
    </TableWrapper>
  );
};
