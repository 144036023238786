import { createSlice } from '@reduxjs/toolkit';
import type { FleetReplacementsHistory } from 'models/fleet-replacements-history';
import type { ResponseWithPagination } from 'common/types';
import { getFleetReplacementsHistoryAction } from './actions';

interface FleetReplacementsHistoryState {
  fleetReplacementsHistory: ResponseWithPagination<FleetReplacementsHistory>;
  isLoading: boolean;
}

const initialState: FleetReplacementsHistoryState = {
  fleetReplacementsHistory: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  isLoading: false
};

const fleetReplacementsHistorySlice = createSlice({
  name: 'fleet-replacements-history',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getFleetReplacementsHistoryAction.fulfilled,
      (state, { payload }) => {
        state.fleetReplacementsHistory = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(getFleetReplacementsHistoryAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFleetReplacementsHistoryAction.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const fleetReplacementsHistoryReducer =
  fleetReplacementsHistorySlice.reducer;
