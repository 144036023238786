import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const activeStyle = css`
  color: ${(p) => p.theme.colors.white};
  background: ${(p) => p.theme.colors.primary};
  svg {
    path {
      fill: #fff;
    }
  }
`;

export const MenuItemStyled = styled.li<{ $isActive?: boolean }>`
  .active {
    ${activeStyle}
  }
  a {
    ${(p) => (p.$isActive ? activeStyle : null)}
  }
`;

export const MenuItemLink = styled(NavLink)`
  display: block;
  padding: 10px 10px 10px 20px;
  color: ${(p) => p.theme.colors.black};
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    font-size: 18px;
    padding: 12px 16px;
    height: 50px;
  }
`;
