import styled from 'styled-components';

export const HistoryItemStyled = styled.div`
  padding: 18px 0;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.colors.stroke};
`;

export const EditIcon = styled.div`
  margin-right: 10px;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 100%;
  background: ${(p) => p.theme.colors.secondaryDark};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HistoryTitle = styled.p`
  color: ${(p) => p.theme.colors.secondaryDark};
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  span {
    font-weight: 700;
  }
`;

export const HistoryDate = styled(HistoryTitle)`
  text-align: right;
  min-width: 150px;
  width: 150px;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    min-width: 85px;
    width: 85px;
    text-align: left;
  }
`;
