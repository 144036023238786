export const CloseSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <g clipPath="url(#clip0_404_26521)">
      <path
        d="M8.28261 7.01144L13.7339 1.55995C14.0887 1.20535 14.0887 0.632019 13.7339 0.277422C13.3793 -0.0771746 12.806 -0.0771746 12.4514 0.277422L6.99992 5.72891L1.5486 0.277422C1.19384 -0.0771746 0.620669 -0.0771746 0.266072 0.277422C-0.0886907 0.632019 -0.0886907 1.20535 0.266072 1.55995L5.71739 7.01144L0.266072 12.4629C-0.0886907 12.8175 -0.0886907 13.3909 0.266072 13.7455C0.442789 13.9223 0.675145 14.0112 0.907336 14.0112C1.13953 14.0112 1.37172 13.9223 1.5486 13.7455L6.99992 8.29396L12.4514 13.7455C12.6283 13.9223 12.8605 14.0112 13.0927 14.0112C13.3249 14.0112 13.5571 13.9223 13.7339 13.7455C14.0887 13.3909 14.0887 12.8175 13.7339 12.4629L8.28261 7.01144Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_404_26521">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
