import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFleetReplacementsHistoryApi } from 'api/fleet-replacements-history';

export enum FleetReplacementsHistoryAction {
  GET_FLEET_REPLACEMENTS_HISTORY = 'fleet-replacements-history/get-fleet-replacements-history'
}

export const getFleetReplacementsHistoryAction = createAsyncThunk(
  FleetReplacementsHistoryAction.GET_FLEET_REPLACEMENTS_HISTORY,
  getFleetReplacementsHistoryApi
);
