import type { ChangeEventHandler, HTMLInputTypeAttribute } from 'react';
import { Label } from 'components/label';
import { InputStyled, InputWrapper } from './style';

interface InputProps {
  label?: string;
  placeholder?: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  type?: HTMLInputTypeAttribute;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  mt?: string;
  mb?: string;
}

export const Input = ({
  label,
  onChange,
  value,
  type,
  name,
  required,
  disabled,
  placeholder,
  mt,
  mb
}: InputProps) => (
  <InputWrapper mt={mt} mb={mb}>
    {label && <Label>{label}</Label>}
    <InputStyled
      type={type}
      onChange={onChange}
      value={value}
      name={name}
      required={required}
      disabled={disabled}
      placeholder={placeholder}
    />
  </InputWrapper>
);
