import { createSlice } from '@reduxjs/toolkit';
import type { TiresDisposalsHistory } from 'models/tires-disposals-history';
import type { ResponseWithPagination } from 'common/types';
import { getDisposalsHistoryAction } from './actions';

interface DisposalsHistoryState {
  disposalsHistory: ResponseWithPagination<TiresDisposalsHistory>;
  isLoading: boolean;
}

const initialState: DisposalsHistoryState = {
  disposalsHistory: { count: 0, next: null, previous: null, results: [] },
  isLoading: false
};

const disposalsHistorySlice = createSlice({
  name: 'disposals-history',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getDisposalsHistoryAction.fulfilled,
      (state, { payload }) => {
        state.disposalsHistory = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(getDisposalsHistoryAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDisposalsHistoryAction.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const disposalsHistoryReducer = disposalsHistorySlice.reducer;
