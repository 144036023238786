export const dateToApiFormat = (date: Date | null) =>
  date
    ? date
        .toLocaleDateString('uk', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        })
        .split('.')
        .reverse()
        .join('-')
    : '';

export const isValidDate = (date: string | null) => {
  if (!date) {
    return false;
  }

  return !isNaN(Date.parse(date));
};

export const withLeadingZero = (date: number) =>
  date < 10 ? `0${date}` : date;

export const dateToHeaderFormat = (date: string | null) => {
  if (!isValidDate(date) || !date) {
    return '';
  }

  const newDate = new Date(date);
  const formatedDate = newDate.toLocaleDateString('uk', {
    day: 'numeric',
    month: 'numeric'
  });

  const hours = newDate.getHours();
  const minutes = withLeadingZero(newDate.getMinutes());

  return `${formatedDate} ${hours}:${minutes}`;
};

export const dateToHistory = (date: string | null) => {
  if (!isValidDate(date) || !date) {
    return '';
  }

  return new Date(date).toLocaleDateString('uk', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
};

export const dateTableFormat = (date: string | null) => {
  if (!isValidDate(date) || !date) {
    return '';
  }

  return new Date(date).toLocaleDateString('uk', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
  });
};
