import styled from 'styled-components';

export const EditableCellStyled = styled.div<{
  $disabled?: boolean;
  $withOutPaddingTop?: boolean;
}>`
  height: 100%;
  background: ${(p) =>
    p.$disabled ? p.theme.colors.white : p.theme.colors.coloumn};
  padding: ${(p) => (p.$withOutPaddingTop ? '0 16px' : '16px')};
  display: flex;
  align-items: center;
`;
