import { createSlice } from '@reduxjs/toolkit';

interface MenuState {
  showMenu: boolean;
}

const initialState: MenuState = {
  showMenu: false
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    toggleMenu: (state) => {
      state.showMenu = !state.showMenu;
    }
  }
});

export const { toggleMenu } = menuSlice.actions;
export const menuReducer = menuSlice.reducer;
