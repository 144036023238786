import styled from 'styled-components';

export const DateRangeStyled = styled.div`
  position: relative;
  background: ${(p) => p.theme.colors.white};
  box-shadow:
    3px 0px 10px rgba(63, 81, 126, 0.11),
    0px 4px 8px rgba(63, 81, 126, 0.09);
  border-radius: 6px;
  overflow: hidden;
`;

export const CalendarContainer = styled.div`
  position: relative;
  display: flex;
  &:after {
    content: '';
    position: absolute;
    height: calc(100% - 48px);
    left: calc(50% - 0.5px);
    top: 24px;
    width: 1px;
    background: #e6e4ed;
    z-index: 1;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    flex-direction: column;
    &:after {
      height: 1px;
      left: 7px;
      width: 240px;
      top: 50%;
    }
  }
`;

export const TopBar = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  span {
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
    color: ${(p) => p.theme.colors.black};
  }
`;

export const Footer = styled.div`
  padding: 10px;
  padding-top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  span {
    color: ${(p) => p.theme.colors.primary};
    cursor: pointer;
  }
`;
