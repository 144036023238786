import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface NotificationsState {
  fleetReplacements: boolean;
  fleetPurchases: boolean;
  fleetVehicle: boolean;
  serviceDisposals: boolean;
  serviceMovements: boolean;
  serviceReplacements: boolean;
}

const initialState: NotificationsState = {
  fleetPurchases: false,
  fleetReplacements: false,
  fleetVehicle: false,
  serviceDisposals: false,
  serviceMovements: false,
  serviceReplacements: false
};

const notificationsSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    updateNotif: (
      state,
      { payload }: PayloadAction<Partial<NotificationsState>>
    ) => ({ ...state, ...payload })
  }
});

export const { updateNotif } = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
