import { Navbar } from 'components/navbar';
import { Subheader } from 'components/subheader';
import { useScrollHeight } from 'hooks/use-scroll-height';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { getReportsAction } from 'store/reports/actions';
import { UpdatedAt } from 'components/updated-at';
import { dateToHeaderFormat, withLeadingZero } from 'helpers/date';
import { NoData } from 'components/no-data';
import { Loader } from 'components/loader';
import { getServiceAllUpdatesAction } from 'store/service-all-updates/actions';
import { MONTHS } from 'common/constants';
import { Filter } from './filter';
import { ReportsContainer } from './style';
import { ReportFile } from './report-file';

export const ReportsPage = () => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const { scrollHeight } = useScrollHeight(ref);
  const [date, setDate] = useState({ day: '', month: '', year: '' });
  const { serviceUpdates } = useTypedSelector((state) => state.serviceUpdates);

  const { reports, isLoading } = useTypedSelector((state) => state.reports);

  useEffect(() => {
    dispatch(
      getReportsAction(
        `?year=${date.year}&month=${
          MONTHS.find((item) => item.title === date.month)?.id || ''
        }&day=${date.day ? withLeadingZero(+date.day) : ''}`
      )
    );
  }, [dispatch, date]);

  useEffect(() => {
    dispatch(getServiceAllUpdatesAction());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <Subheader alignStart title="Залишки на СТО">
        <Filter date={date} setDate={setDate} />
        {!!serviceUpdates.results.length &&
          serviceUpdates.results[0].service_reports_table && (
            <UpdatedAt>
              {dateToHeaderFormat(
                serviceUpdates.results[0].service_reports_table
              )}
            </UpdatedAt>
          )}
      </Subheader>
      <div ref={ref} style={{ position: 'relative' }}>
        {isLoading ? (
          <Loader isBig place={{ left: 'calc(50% - 16px)', top: '100px' }} />
        ) : reports.length ? (
          <ReportsContainer style={{ height: `${scrollHeight}px` }}>
            {reports.map((item) => (
              <ReportFile
                key={item.id}
                fileName={item.filename}
                fileLink={item.file}
              />
            ))}
          </ReportsContainer>
        ) : (
          <NoData
            title={
              <>
                Немає жодного звіту!
                <br />
                Оберіть рік та сезон для відображення!
              </>
            }
            height={`${scrollHeight}px`}
          />
        )}
      </div>
    </>
  );
};
