import type { TiresMovements } from 'models/tires-movements';

export interface GroupedMovements extends TiresMovements {
  isHeader?: boolean;
}

export const groupedMovements = (
  list: TiresMovements[]
): GroupedMovements[] => {
  const groupedData: Record<string, TiresMovements[]> = {};

  list.forEach((movement) => {
    const docNumber = movement.doc_number;
    if (!groupedData[docNumber]) {
      groupedData[docNumber] = [];
    }
    groupedData[docNumber].push(movement);
  });

  return Object.values(groupedData)
    .map((item) => [
      {
        ...item[0],
        isHeader: true,
        id: `${item[0].id}${item[0].doc_number}`
      },
      ...item
    ])
    .flat();
};
