import styled from 'styled-components';

export const CellInputStyled = styled.input`
  padding: 16px 0;
  color: ${(p) => p.theme.colors.secondaryDark};
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  background: transparent;
  border: none;
  width: 100%;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
`;
