import styled from 'styled-components';

export const SubheaderActionsStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    width: 100%;
    justify-content: flex-end;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    justify-content: center;
  }
`;
