import { HttpStatusCode } from 'common/enums/http/http-status-code';
import { ApiPath } from 'common/enums/http/api-path';
import { HttpMethod } from 'common/enums/http/http-method';
import { HttpHeader } from 'common/enums/http/http-header';
import { HttpContentType } from 'common/enums/http/http-content-type';
import { getApiError } from 'helpers/get-api-error';
import type { ID } from 'common/types';
import type { IStorage } from './storage';
import { storage } from './storage';

interface MakeRequest {
  url: string;
  config: RequestInit;
  needAuthorization?: boolean;
  isBlob?: boolean;
  isNotJson?: boolean;
}

interface FetchOptions {
  body?: unknown;
  needAuthorization?: boolean;
  contentType?: HttpContentType;
  isBlob?: boolean;
  isNotJson?: boolean;
}

interface RequestArgs extends FetchOptions {
  method: HttpMethod;
}

class Http {
  private _baseUrl: string;

  private _storage: IStorage;

  constructor(baseUrl: string, storage: IStorage) {
    this._baseUrl = baseUrl;
    this._storage = storage;
  }

  public get<T>(url: string, needAuthorization?: boolean, isBlob?: boolean) {
    const config = this.getRequestOptions({
      method: HttpMethod.GET,
      needAuthorization
    });

    return this.makeRequest<T>({
      url,
      config,
      needAuthorization,
      isBlob
    });
  }

  public post<T>({ url, options }: { url: string; options: FetchOptions }) {
    const config = this.getRequestOptions({
      ...options,
      method: HttpMethod.POST
    });

    return this.makeRequest<T>({
      url,
      config,
      needAuthorization: options.needAuthorization,
      isBlob: options.isBlob,
      isNotJson: options.isNotJson
    });
  }

  public patch<T>({ url, options }: { url: string; options: FetchOptions }) {
    const config = this.getRequestOptions({
      ...options,
      method: HttpMethod.PATCH
    });

    return this.makeRequest<T>({
      url,
      config,
      needAuthorization: options.needAuthorization
    });
  }

  public delete<T>({ url, ids }: { url: string; ids: ID[] }) {
    const config = this.getRequestOptions({
      method: HttpMethod.DELETE
    });

    return this.makeRequest<T>({
      url: `${url}?ids=${ids.join(',')}`,
      config,
      needAuthorization: true
    });
  }

  private getRequestOptions({
    body,
    method,
    needAuthorization = true,
    contentType = HttpContentType.APPLICATION_JSON
  }: RequestArgs): RequestInit {
    const headers: HeadersInit = {
      [HttpHeader.CONTENT_TYPE]: contentType
    };

    if (needAuthorization) {
      const token = this._storage.getAccessToken();
      headers[HttpHeader.AUTHORIZATION] = `Bearer ${token}`;
    }

    const config: RequestInit = {
      headers,
      method
    };

    if (body && contentType === HttpContentType.APPLICATION_JSON) {
      config.body = JSON.stringify(body);
    }

    if (body && contentType === HttpContentType.FORM_DATA) {
      config.body = body as BodyInit;
      delete headers[HttpHeader.CONTENT_TYPE];
    }

    return config;
  }

  private getRefreshedAuthReqConfig(config: RequestInit) {
    const accessToken = this._storage.getAccessToken();

    return {
      ...config,
      headers: {
        ...config.headers,
        [HttpHeader.AUTHORIZATION]: `Bearer ${accessToken}`
      }
    };
  }

  private async makeRequest<T = unknown>({
    url,
    config,
    needAuthorization = true,
    isBlob,
    isNotJson
  }: MakeRequest): Promise<T> {
    let result = await fetch(`${this._baseUrl}${url}`, config);

    if (result.status === HttpStatusCode.UNAUTHORIZED && needAuthorization) {
      try {
        await this.updateAuthorizationToken();
        const updatedConfig = this.getRefreshedAuthReqConfig(config);
        result = await fetch(`${this._baseUrl}${url}`, updatedConfig);
      } catch (err) {
        throw new Error(getApiError(err));
      }
    }

    if (!result.ok) {
      const error: unknown = await result.json();
      throw { data: error };
    }

    if (isBlob) {
      return result.blob() as Promise<T>;
    }

    return isNotJson ? ({} as T) : (result.json() as Promise<T>);
  }

  private async updateAuthorizationToken() {
    const refreshToken = this._storage.getRefreshToken();

    const res = await fetch(`${this._baseUrl}${ApiPath.AUTH_REFRESH_TOKEN}`, {
      method: HttpMethod.POST,
      body: JSON.stringify({ refresh: refreshToken }),
      headers: {
        [HttpHeader.CONTENT_TYPE]: HttpContentType.APPLICATION_JSON
      }
    });

    if (!res.ok) {
      this._storage.logOut();
      const error: unknown = await res.json();
      throw { data: error };
    }

    const { access } = await res.json();

    this._storage.setAccessTokens(access || '');
  }
}

export const http = new Http(process.env.REACT_APP_API_URL || '', storage);
