import { createAsyncThunk } from '@reduxjs/toolkit';
import { getContactManagersApi, getContactsApi } from 'api/contacts';

export enum AvisContactsAction {
  GET_AVIS_CONTACTS = 'avis-contacts/get-avis-contacts',
  GET_AVIS_CONTACT_MANAGERS = 'avis-contacts/get-avis-contact-managers'
}

export const getAvisContactsAction = createAsyncThunk(
  AvisContactsAction.GET_AVIS_CONTACTS,
  getContactsApi
);

export const getAvisContactManagersAction = createAsyncThunk(
  AvisContactsAction.GET_AVIS_CONTACT_MANAGERS,
  getContactManagersApi
);
