import type { ID } from 'common/types';
import type { HeaderItem } from 'components/data-table';
import type { ReactNode } from 'react';

export interface FleetPurchasesTable {
  id: ID;
  vehicle_plate: string;
  vehicle_brand_model: string;
  tire_model: string;
  season: string;
  size: string;
  diameter: string;
  amount: string;
  storage: string;
  comment: ReactNode;
  confirmed: ReactNode;
  confirmed_at: string;
}

export const tableHeaders: HeaderItem<FleetPurchasesTable>[] = [
  { id: 'vehicle_plate', title: 'ДНЗ', width: '100px' },
  { id: 'vehicle_brand_model', title: 'Марка/ модель', width: '150px' },
  { id: 'tire_model', title: 'Модель шин', width: '160px' },
  { id: 'season', title: 'Сезон', width: '79px' },
  { id: 'size', title: 'Розмір', width: '86px' },
  { id: 'diameter', title: 'Діаметр', width: '93px' },
  { id: 'amount', title: 'Ціна, грн', width: '100px' },
  { id: 'storage', title: 'Зберігання шин', width: '200px' },
  {
    id: 'comment',
    title: 'Коментар до закупівлі',
    width: '345px',
    editable: true
  },
  { id: 'confirmed', title: 'Підтвердження', width: '147px', editable: true },
  { id: 'confirmed_at', title: 'дата підтвердження', width: '151px' }
];
