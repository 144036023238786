import { ApiPath } from 'common/enums/http/api-path';
import type { ID, ResponseWithPagination } from 'common/types';
import type { FleetTiresReplacements } from 'models/fleet-tires-replacements';
import { http } from 'services/http';

export const getFleetTiresReplacementsApi = (params?: string) =>
  http.get<ResponseWithPagination<FleetTiresReplacements>>(
    `${ApiPath.FLEET_TIRES_REPLACEMENTS}${params || ''}`
  );

export const updateFleetTiresReplacementsApi = ({
  id,
  body
}: {
  id: ID;
  body: Record<string, unknown>;
}) =>
  http.patch<FleetTiresReplacements>({
    url: `${ApiPath.FLEET_TIRES_REPLACEMENTS}${id}/`,
    options: {
      body
    }
  });
