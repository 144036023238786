import type { ID } from 'common/types';
import type { HeaderItem } from 'components/data-table';
import type { ReactNode } from 'react';

export interface FleetReplacementsTable {
  id: ID;
  vehicle_plate: string;
  vehicle_brand_model: string;
  tire_model: string;
  season: string;
  size: string;
  diameter: string;
  status: string;
  defect: ReactNode;
  tread_depth: ReactNode;
  replacement_confirmation: ReactNode;
  tire_storage: ReactNode;
  paid_storage: string;
  action: ReactNode;
  recipient_data: ReactNode;
  confirmed: ReactNode;
  confirmed_at: string;
}

export const tableHeaders: HeaderItem<FleetReplacementsTable>[] = [
  { id: 'vehicle_plate', title: 'ДНЗ', width: '100px' },
  {
    id: 'vehicle_brand_model',
    title: 'Марка/ модель',
    maxWidth: '150px'
  },
  { id: 'tire_model', title: 'Модель шин', width: '154px' },
  { id: 'season', title: 'Сезон', width: '79px' },
  { id: 'size', title: 'Розмір', width: '86px' },
  { id: 'diameter', title: 'Діаметр', width: '93px' },
  { id: 'status', title: 'Статус шин', width: '170px' },
  { id: 'defect', title: 'Опис брака', width: '119px' },
  { id: 'tread_depth', title: 'ВП', width: '52px' },
  {
    id: 'replacement_confirmation',
    title: 'Підтвердження заміни',
    width: '150px',
    editable: true
  },
  { id: 'tire_storage', title: 'зберігання', width: '200px', editable: true },
  { id: 'paid_storage', title: 'платне зберігання', width: '115px' },
  { id: 'action', title: 'Зняту шину', width: '153px', editable: true },
  {
    id: 'recipient_data',
    title: 'ПІБ, телефон, адреса отримувача',
    width: '209px',
    editable: true
  },
  { id: 'confirmed', title: 'Підтвердження', width: '147px', editable: true },
  { id: 'confirmed_at', title: 'дата підтвердження', width: '151px' }
];
