export const PlusSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_404_24044)">
      <path
        d="M15.2 7.2H8.80005V0.799951C8.80005 0.358447 8.4416 0 7.99995 0C7.55845 0 7.2 0.358447 7.2 0.799951V7.2H0.799951C0.358447 7.2 0 7.55845 0 7.99995C0 8.4416 0.358447 8.80005 0.799951 8.80005H7.2V15.2C7.2 15.6416 7.55845 16 7.99995 16C8.4416 16 8.80005 15.6416 8.80005 15.2V8.80005H15.2C15.6416 8.80005 16 8.4416 16 7.99995C16 7.55845 15.6416 7.2 15.2 7.2Z"
        fill="#65615D"
      />
    </g>
    <defs>
      <clipPath id="clip0_404_24044">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
