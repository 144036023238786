import { AuthLogoSvg } from 'components/svg/auth-logo-svg';
import { LogInStyled, Logo } from './style';
import { LoginForm } from './login-form';

export const LogIn = () => (
  <LogInStyled>
    <Logo>
      <AuthLogoSvg />
    </Logo>
    <LoginForm />
  </LogInStyled>
);
