import { SettingsSvg } from 'components/svg/settings-svg';
import { ArrowSvg } from 'components/svg/arrow-svg';
import type { ChangeEventHandler } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ImportSvg } from 'components/svg/import-svg';
import { ExportSvg } from 'components/svg/export-svg';
import { useOutsideClick } from 'hooks/use-outside-click';
import { useTheme } from 'styled-components';
import { Popup } from 'components/popup';
import { FileSvg } from 'components/svg/file-svg';
import { BtnGroup } from 'components/btn-group';
import { importCompanies } from 'api/user';
import { useAppDispatch } from 'store/store';
import { fetchCompanies } from 'store/companies/actions';
import { exportDataApi } from 'api/companies';
import { getApiError } from 'helpers/get-api-error';
import {
  ExportImportStyled,
  ExportImportWrapper,
  ImportName,
  Options,
  OptionsItem
} from './style';

export const ExportImport = () => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [importError, setImportError] = useState(false);
  const [importErrorText, setImportErrorText] = useState('');

  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  const handleChangeImport: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files;
    setShowConfirm(true);
    if (files) {
      setFile(files[0]);
    }
  };

  const handleImport = () => {
    const formData = new FormData();
    if (file) {
      formData.append('importData', file);
      importCompanies(formData)
        .then(() => {
          dispatch(fetchCompanies());
        })
        .catch((err) => {
          if (getApiError(err, true) === 'Internal Error') {
            setShowConfirm(false);
          } else {
            setImportError(true);
            setImportErrorText(getApiError(err, true));
            setShowConfirm(false);
          }
        })
        .finally(() => {
          setShowConfirm(false);
        });
    }
  };

  const handleExport = () => {
    exportDataApi()
      .then((file) => {
        setIsOpen(false);
        const url = window.URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'exported_data.xls';
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(console.log);
  };

  const handleCancel = useCallback(() => {
    setFile(null);
    setShowConfirm(false);
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (!showConfirm && inputRef.current) {
      inputRef.current.value = '';
      setFile(null);
      setIsOpen(false);
    }
  }, [showConfirm]);

  return (
    <ExportImportWrapper ref={ref}>
      <ExportImportStyled
        $isOpen={isOpen}
        onClick={() => setIsOpen((state) => !state)}
      >
        <SettingsSvg
          fill={isOpen ? theme.colors.primary : theme.colors.secondaryDark}
        />
        <ArrowSvg
          fill={isOpen ? theme.colors.primary : theme.colors.secondaryDark}
        />
      </ExportImportStyled>
      {isOpen && (
        <Options>
          <OptionsItem as="label">
            <input
              ref={inputRef}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={handleChangeImport}
              type="file"
            />
            <ImportSvg />
            Імпорт файла
          </OptionsItem>
          <OptionsItem onClick={handleExport}>
            <ExportSvg />
            Експорт файла
          </OptionsItem>
        </Options>
      )}
      <Popup
        maxContentWidth="448px"
        show={showConfirm}
        setShow={setShowConfirm}
        title="Імпорт"
      >
        <ImportName>
          <FileSvg />
          {file?.name}
        </ImportName>
        <BtnGroup
          onSubmit={handleImport}
          onCancel={handleCancel}
          submitTitle="Зберегти"
        />
      </Popup>
      <Popup
        title="При імпорті виникли помилки"
        show={importError}
        setShow={setImportError}
        maxContentWidth="600px"
      >
        {Array.isArray(importErrorText) ? (
          <ul style={{ padding: '20px 0' }}>
            {importErrorText.map((item, i) => (
              <li style={{ marginBottom: '10px', color: 'red' }} key={i}>
                {item}
              </li>
            ))}
          </ul>
        ) : (
          importErrorText
        )}
      </Popup>
    </ExportImportWrapper>
  );
};
