import type { ReactNode } from 'react';
import { NoDataStyled } from './style';

interface NoDataProps {
  title?: ReactNode;
  height?: string;
}

export const NoData = ({
  title = 'Немає жодної інформації',
  height
}: NoDataProps) => (
  <NoDataStyled style={{ height: height || '30px' }}>{title}</NoDataStyled>
);
