import styled from 'styled-components';

export const LabelStyled = styled.div`
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.secondaryDark};

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    font-size: 14px;
    padding-bottom: 5px;
  }
`;
