import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { TiresDisposals } from 'models/tires-disposals';
import type { ResponseWithPagination } from 'common/types';
import { getTiresDisposalsAction, updateTiresDisposalsAction } from './actions';

interface TiresDisposalsState {
  tiresDisposals: ResponseWithPagination<TiresDisposals>;
  isLoading: boolean;
}

const initialState: TiresDisposalsState = {
  tiresDisposals: { count: 0, next: null, previous: null, results: [] },
  isLoading: false
};

const tiresDisposalsSlice = createSlice({
  name: 'tires-disposals',
  initialState,
  reducers: {
    updateTiresDisposals: (
      state,
      { payload }: PayloadAction<Partial<TiresDisposals>>
    ) => {
      state.tiresDisposals.results = state.tiresDisposals.results.map((item) =>
        item.id === payload.id ? { ...item, ...payload } : item
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTiresDisposalsAction.fulfilled, (state, { payload }) => {
      state.tiresDisposals = payload;
      state.isLoading = false;
    });
    builder.addCase(getTiresDisposalsAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTiresDisposalsAction.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      updateTiresDisposalsAction.fulfilled,
      (state, { payload }) => {
        state.tiresDisposals.results = state.tiresDisposals.results.map(
          (item) => (item.id === payload.id ? payload : item)
        );
      }
    );
  }
});

export const { updateTiresDisposals } = tiresDisposalsSlice.actions;
export const tiresDisposalsReducer = tiresDisposalsSlice.reducer;
