export const PrintSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M13.6562 4.03125H13.2188V2.34375C13.2188 1.05141 12.1673 0 10.875 0H5.125C3.83266 0 2.78125 1.05141 2.78125 2.34375V4.03125H2.34375C1.05141 4.03125 0 5.08266 0 6.375V10.125C0 11.4173 1.05141 12.4688 2.34375 12.4688H2.78125V14.5938C2.78125 15.3692 3.41209 16 4.1875 16H11.8125C12.5879 16 13.2188 15.3692 13.2188 14.5938V12.4688H13.6562C14.9486 12.4688 16 11.4173 16 10.125V6.375C16 5.08266 14.9486 4.03125 13.6562 4.03125ZM3.71875 2.34375C3.71875 1.56834 4.34959 0.9375 5.125 0.9375H10.875C11.6504 0.9375 12.2812 1.56834 12.2812 2.34375V4.03125H3.71875V2.34375ZM12.2812 14.5938C12.2812 14.8522 12.071 15.0625 11.8125 15.0625H4.1875C3.92903 15.0625 3.71875 14.8522 3.71875 14.5938V9.96875H12.2812V14.5938ZM15.0625 10.125C15.0625 10.9004 14.4317 11.5312 13.6562 11.5312H13.2188V9.96875H13.5C13.7589 9.96875 13.9688 9.75887 13.9688 9.5C13.9688 9.24113 13.7589 9.03125 13.5 9.03125H2.5C2.24113 9.03125 2.03125 9.24113 2.03125 9.5C2.03125 9.75887 2.24113 9.96875 2.5 9.96875H2.78125V11.5312H2.34375C1.56834 11.5312 0.9375 10.9004 0.9375 10.125V6.375C0.9375 5.59959 1.56834 4.96875 2.34375 4.96875H13.6562C14.4317 4.96875 15.0625 5.59959 15.0625 6.375V10.125Z"
      fill="#65615D"
    />
  </svg>
);
