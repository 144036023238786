import { styled } from 'styled-components';

export const LoginFormWrapper = styled.div`
  margin-top: 130px;
  padding: 169px 20px;
  width: 100%;
  max-width: 844px;
  background: ${(p) => p.theme.colors.white};

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    margin-top: 90px;
    max-width: 800px;
    padding: 102px 20px;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    margin-top: 174px;
    max-width: 660px;
    padding: 56px 60px;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.xs}) {
    margin-top: 90px;
    padding: 50px 20px;
  }
`;

export const LoginFormStyled = styled.form`
  max-width: 540px;
  margin: 0 auto;
`;

export const FormTitle = styled.h1`
  margin-bottom: 40px;
  color: ${(p) => p.theme.colors.black};
  text-align: center;
  font-size: 44px;
  font-weight: 700;
  line-height: 120%;

  @media (max-width: ${(p) => p.theme.breakpoints.xs}) {
    font-size: 32px;
    margin-bottom: 20px;
  }
`;
