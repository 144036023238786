import type { ReactNode } from 'react';
import { TooltipStyled } from './style';

interface TooltipProps {
  children?: ReactNode;
}

export const Tooltip = ({ children }: TooltipProps) => (
  <TooltipStyled>{children}</TooltipStyled>
);
