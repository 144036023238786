import { ApiPath } from 'common/enums/http/api-path';
import { HttpContentType } from 'common/enums/http/http-content-type';
import type { User } from 'models/user';
import { http } from 'services/http';

export const getUserMe = () => http.get<User>(ApiPath.AUTH_USER_ME);

export const importCompanies = (data: FormData) =>
  http.post({
    url: ApiPath.COMPANIES_IMPORT,
    options: {
      contentType: HttpContentType.FORM_DATA,
      body: data
    }
  });
