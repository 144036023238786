import styled from 'styled-components';

export const PaginationStyled = styled.div`
  position: fixed;
  bottom: 0;
  left: 232px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow:
    -3px 0px 10px rgba(63, 81, 126, 0.11),
    3px 0px 10px rgba(63, 81, 126, 0.11);
  height: 64px;
  background: ${(p) => p.theme.colors.white};
  width: calc(100vw - 232px);
  transition: all 0.4s;
  z-index: 10;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    width: 100%;
    left: 0;
  }
`;

export const PaginationBtnContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NextLink = styled.div`
  display: grid;
  place-items: center;
  margin-left: 8px;
  cursor: pointer;
  svg {
    transform: rotate(-90deg);
  }
`;

export const PrevLink = styled(NextLink)`
  margin-right: 8px;
  margin-left: 0;
  svg {
    transform: rotate(90deg);
  }
`;

export const PaginationLink = styled.div<{ $isActive?: boolean }>`
  margin: 0 2px;
  min-width: 36px;
  height: 36px;
  display: grid;
  place-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${(p) => (p.$isActive ? p.theme.colors.white : p.theme.colors.black)};
  border: 1px solid
    ${(p) => (p.$isActive ? p.theme.colors.primary : p.theme.colors.border)};
  border-radius: 6px;
  background: ${(p) => (p.$isActive ? p.theme.colors.primary : 'transoparent')};
  cursor: pointer;
`;

export const Truncable = styled.span`
  margin: 0 2px;
  display: block;
  width: 40px;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: #b5b5b5;
  text-align: center;
  padding-top: 9px;
  cursor: default;
`;

export const PaginationForm = styled.form`
  margin-left: 36px;
  display: flex;
  align-items: center;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    display: none;
  }
`;

export const PaginationInput = styled.input`
  padding: 10px;
  margin-right: 10px;
  width: 50px;
  height: 36px;
  border: 1px solid ${(p) => p.theme.colors.border};
  border-radius: 6px;
  outline: none;
  text-align: center;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
  &:focus {
    border: 1px solid ${(p) => p.theme.colors.black};
  }
`;

export const SearchBtn = styled.button`
  margin-left: 10px;
  padding: 6px 18px;
  border: 1px solid ${(p) => p.theme.colors.border};
  border-radius: 6px;
  height: 36px;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${(p) => p.theme.colors.black};
  cursor: pointer;
  background: ${(p) => p.theme.colors.white};
`;

export const SelectWrapper = styled.div`
  width: 140px;
`;
