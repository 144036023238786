import type { ID } from 'common/types';

export const groupeEntities = <
  T extends {
    doc_number?: string;
    vehicle_plate: string;
    isHeader?: boolean;
    id: ID;
  }
>(
  list: T[]
): T[] => {
  const groupedData: Record<string, T[]> = {};

  list.forEach((item) => {
    const docNumber = item.doc_number || item.vehicle_plate;
    if (!groupedData[docNumber]) {
      groupedData[docNumber] = [];
    }
    groupedData[docNumber].push(item);
  });

  return Object.values(groupedData)
    .map((item) => [
      {
        ...item[0],
        isHeader: true,
        id: `${item[0].id}${item[0].vehicle_plate}`
      },
      ...item
    ])
    .flat();
};
