import styled from 'styled-components';

export const BtnGroupStyled = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  border-top: 1px solid ${(p) => p.theme.colors.stroke};

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;
