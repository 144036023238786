import { createAsyncThunk } from '@reduxjs/toolkit';
import { getReportsApi } from 'api/report';

export enum ReportsAction {
  GET_REPORTS = 'reports/get-reports'
}

export const getReportsAction = createAsyncThunk(
  ReportsAction.GET_REPORTS,
  getReportsApi
);
