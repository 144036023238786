import styled from 'styled-components';

export const SubheaderStyled = styled.div<{ $alignStart?: boolean }>`
  position: relative;
  min-height: 84px;
  padding: 20px 20px 20px 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(p) => (p.$alignStart ? 'flex-start' : 'space-between')};
  align-items: center;
  gap: 12px;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    height: unset;
    padding: 40px 16px 30px;
    flex-direction: column;
    gap: 30px;
  }
`;

export const PageTitle = styled.h1`
  color: ${(p) => p.theme.colors.secondaryDark};
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
`;

export const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const BackBtn = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid ${(p) => p.theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    transform: rotate(90deg);
  }
`;
