import { useEffect, useRef, useState } from 'react';
import { OneLine, StringCutterStyled } from './style';
import type { StringCutterProps } from './types';

const StringCutter = ({ children, $lines }: StringCutterProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isCut, setIsCut] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setIsCut(
        ref.current?.scrollHeight > ref.current?.offsetHeight ||
          ref.current?.scrollWidth > ref.current?.offsetWidth
      );
    }
  }, []);

  if (+$lines === 1) {
    return (
      <OneLine title={isCut ? children?.toString() : undefined} ref={ref}>
        {children}
      </OneLine>
    );
  }

  return (
    <StringCutterStyled
      title={isCut ? children?.toString() : undefined}
      ref={ref}
      $lines={$lines}
    >
      {children}
    </StringCutterStyled>
  );
};

export { StringCutter };
