import styled from 'styled-components';

export const CalendarWrapper = styled.div`
  position: relative;
`;

export const CalendarStyled = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 6px);
  z-index: 5;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    right: unset;
    left: 0;
  }
`;
