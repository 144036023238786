import type { ReactNode } from 'react';
import { SubheaderActionsStyled } from './style';

interface SubheaderActionsProps {
  children: ReactNode;
}

export const SubheaderActions = ({ children }: SubheaderActionsProps) => (
  <SubheaderActionsStyled>{children}</SubheaderActionsStyled>
);
