import { LoginSubmitStyled } from './style';

interface LoginSubmitProps {
  disabled?: boolean;
}

export const LoginSubmit = ({ disabled }: LoginSubmitProps) => (
  <LoginSubmitStyled disabled={disabled} type="submit">
    Продовжити
  </LoginSubmitStyled>
);
