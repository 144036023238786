import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTiresReplacementsHistoryApi } from 'api/tires-replacements-history';

export enum TiresReplacementsHistoryAction {
  GET_REPLACEMENTS_HISTORY = 'replacements-history/get-replacements-history'
}

export const getReplacementsHistoryAction = createAsyncThunk(
  TiresReplacementsHistoryAction.GET_REPLACEMENTS_HISTORY,
  getTiresReplacementsHistoryApi
);
