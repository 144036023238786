import { DateSelect } from 'components/date-select';
import type { Dispatch, SetStateAction } from 'react';
import { MONTHS } from 'common/constants';
import { FilterStyled } from './style';

interface FilterDate {
  day: string;
  month: string;
  year: string;
}

interface FilterProps {
  date: FilterDate;
  setDate: Dispatch<SetStateAction<FilterDate>>;
}

export const Filter = ({ date, setDate }: FilterProps) => (
  <FilterStyled>
    <DateSelect
      options={new Array(32).fill('-').map((_, i) => (i ? `${i}` : ''))}
      value={date.day}
      setValue={(day) => setDate((state) => ({ ...state, day }))}
      minWidth="85px"
      placeholder="День"
    />
    <DateSelect
      options={MONTHS.map((item) => item.title)}
      value={date.month}
      setValue={(month) => setDate((state) => ({ ...state, month }))}
      minWidth="120px"
      placeholder="Місяць"
    />
    <DateSelect
      options={new Array(4)
        .fill('-')
        .map((_, i) => (i ? `${new Date().getFullYear() + 1 - i}` : ''))}
      value={date.year}
      setValue={(year) => setDate((state) => ({ ...state, year }))}
      minWidth="85px"
      placeholder="Рік"
    />
  </FilterStyled>
);
