import { Navigate } from 'react-router-dom';
import { useEffect, type FC } from 'react';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { useCheckAuth } from 'hooks/use-check-auth';
import { AppRoute } from 'common/enums/app/app-route';
import { MainLayout } from 'components/layouts/main-layout';
import { updateNotif } from 'store/notifications/reducer';
import { updateCount } from 'store/fleet-notifications/reducer';

interface PrivateRouteProps {
  component: FC;
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const dispatch = useAppDispatch();
  const { isAuthorized, user } = useTypedSelector((state) => state.user);
  useCheckAuth();

  useEffect(() => {
    let client: WebSocket | null = null;
    if (user) {
      const url = `${process.env.REACT_APP_SOCKET_URL}${
        user?.role.id === 1 ? 'service-socket/' : 'fleet-socket/'
      }`;
      client = new WebSocket(`${url}?user_id=${user.id}`);

      client.onmessage = (e) => {
        const data = JSON.parse(e.data);
        if (data.type === 'fleet_notifs') {
          dispatch(updateCount(data.unread_count || 0));

          return;
        }
        if (data.type === 'new_rows') {
          switch (data.table) {
            case 'fleet replacements':
              dispatch(
                updateNotif({
                  fleetReplacements: true
                })
              );
              break;
            case 'fleet purchases':
              dispatch(
                updateNotif({
                  fleetPurchases: true
                })
              );
              break;
            case 'fleet vehicle':
              dispatch(
                updateNotif({
                  fleetVehicle: true
                })
              );
              break;
            case 'service disposals':
              dispatch(
                updateNotif({
                  serviceDisposals: true
                })
              );
              break;
            case 'service movements':
              dispatch(
                updateNotif({
                  serviceMovements: true
                })
              );
              break;
            case 'service replacements':
              dispatch(
                updateNotif({
                  serviceReplacements: true
                })
              );
              break;
            default:
              break;
          }
        }
      };
    }

    return () => {
      if (client) {
        client.close();
      }
    };
  }, [dispatch, user]);

  if (isAuthorized === 'needToCheck') return null;

  return isAuthorized === 'yes' ? (
    <MainLayout>
      <Component {...rest} />
    </MainLayout>
  ) : (
    <Navigate to={{ pathname: AppRoute.LOG_IN }} />
  );
};

export { PrivateRoute };
