interface ArrowSvgProps {
  fill?: string;
}

export const ArrowSvg = ({ fill }: ArrowSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={fill || '#65615D'}
  >
    <path
      d="M8 9.57574L7.64645 9.22218L5.01213 6.58787C4.89497 6.47071 4.70503 6.47071 4.58787 6.58787L8 9.57574ZM8 9.57574L8.35355 9.22218M8 9.57574L8.35355 9.22218M8.35355 9.22218L10.9879 6.58787C11.105 6.47071 11.295 6.47071 11.4121 6.58787M8.35355 9.22218L11.4121 6.58787M11.4121 6.58787C11.5293 6.70503 11.5293 6.89497 11.4121 7.01213M11.4121 6.58787L11.4121 7.01213M11.4121 7.01213L8.21213 10.2121M11.4121 7.01213L8.21213 10.2121M8.21213 10.2121C8.09497 10.3293 7.90503 10.3293 7.78787 10.2121M8.21213 10.2121L7.78787 10.2121M7.78787 10.2121L4.58787 7.01213M7.78787 10.2121L4.58787 7.01213M4.58787 7.01213C4.47071 6.89497 4.47071 6.70503 4.58787 6.58787L4.58787 7.01213Z"
      fill={fill || '#65615D'}
      stroke={fill || '#65615D'}
    />
  </svg>
);
