import styled from 'styled-components';

interface SelectHeaderProps {
  $isOpen?: boolean;
  $isSelected?: boolean;
  $minWidth?: string;
}

export const SelectWrapper = styled.div`
  position: relative;
`;

export const SelectHeader = styled.div<SelectHeaderProps>`
  padding: 10px 12px;
  height: 40px;
  min-width: ${(p) => p.$minWidth || 'unset'};
  padding-right: 12px;
  position: relative;
  border: 1px solid
    ${(p) => (p.$isOpen ? p.theme.colors.primary : p.theme.colors.stroke)};
  border-radius: 6px;
  background: ${(p) =>
    p.$isSelected ? p.theme.colors.primary : p.theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  > svg {
    min-width: 16px;
    transform: ${(p) => (p.$isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  }
  user-select: none;
`;

export const SelectHeaderTitle = styled.div<{ $isSelected?: boolean }>`
  width: calc(100% - 26px);
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.2px;
  color: ${(p) =>
    p.$isSelected ? p.theme.colors.white : p.theme.colors.black};
`;

export const SelectOptions = styled.div<{
  customWidth?: string;
}>`
  padding: 8px 0;
  position: absolute;
  left: 0;
  width: ${(p) => p.customWidth || '100%'};
  top: calc(100% + 6px);
  z-index: 11;
  border: 1px solid ${(p) => p.theme.colors.stroke};
  box-shadow:
    4px 5px 9px 0px rgba(63, 81, 126, 0.08),
    -3px -3px 9px 0px rgba(63, 81, 126, 0.06);
  border-radius: 6px;
  max-height: 200px;
  overflow: auto;
  background: ${(p) => p.theme.colors.white};
`;

export const SelectOptionItem = styled.div`
  padding: 9px 5px 9px 14px;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.black};
  background-color: ${(p) => p.theme.colors.white};
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.theme.colors.primary};
    color: ${(p) => p.theme.colors.white};
  }
`;
