import styled from 'styled-components';

interface InputStyledProps {
  mt?: string;
  mb?: string;
}

export const InputWrapper = styled.div<InputStyledProps>`
  margin-top: ${(p) => p.mt || 0};
  margin-bottom: ${(p) => p.mb || 0};
  position: relative;
`;

export const InputStyled = styled.input<InputStyledProps>`
  display: block;
  width: 100%;
  padding: 8px 12px;
  height: 36px;
  border: 1px solid ${(p) => p.theme.colors.border};
  border-radius: 6px;
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  color: ${(p) => p.theme.colors.black};
  &::placeholder {
    color: ${(p) => p.theme.colors.stroke};
  }
  &:focus {
    border: 1px solid ${(p) => p.theme.colors.focus};
  }
  &:disabled {
    background: ${(p) => p.theme.colors.white};
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    height: 44px;
    font-size: 14px;
  }
`;
