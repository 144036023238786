import { LoaderStyled } from './style';

export interface LoaderProps {
  position?: 'static' | 'absolute';
  place?: {
    left?: string;
    right?: string;
    top?: string;
    bottom?: string;
  };
  isBig?: boolean;
}

export const Loader = ({
  position = 'absolute',
  place = { right: '12px', top: '12px' },
  isBig
}: LoaderProps) => (
  <LoaderStyled $isBig={isBig} $position={position} $place={place} />
);
