import { createAsyncThunk } from '@reduxjs/toolkit';
import { getServiceAllUpdatesApi } from 'api/service-all-updates';

export enum ServiceAllUpdatesAction {
  GET_UPDATES = 'service-all-updates/get-updates'
}

export const getServiceAllUpdatesAction = createAsyncThunk(
  ServiceAllUpdatesAction.GET_UPDATES,
  getServiceAllUpdatesApi
);
