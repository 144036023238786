import { updateFleetTiresPurchasesApi } from 'api/fleet-tires-purchases';
import type { ID } from 'common/types';
import { CellInput } from 'components/cell-input';
import { EditableCell } from 'components/editable-cell';
import { updateFleetPurchasesComment } from 'store/fleet-tires-purchases/reducer';
import { useAppDispatch } from 'store/store';

interface CommentProps {
  value: string;
  id: ID;
  disabled?: boolean;
}

export const Comment = ({ id, value, disabled }: CommentProps) => {
  const dispatch = useAppDispatch();

  const handleChange = (value: string) => {
    dispatch(updateFleetPurchasesComment({ id, comment: value }));
  };

  const handleBlur = () => {
    updateFleetTiresPurchasesApi({
      id,
      body: {
        comment: value
      }
    });
  };

  return (
    <EditableCell withOutPaddingTop disabled={disabled}>
      <CellInput
        onBlur={handleBlur}
        value={value}
        setValue={handleChange}
        disabled={disabled}
      />
    </EditableCell>
  );
};
