import type { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  colors: {
    primary: '#D4002A',
    primaryLight: '#FFF6F8',
    secondary: '#E1DED9',
    white: '#FFF',
    black: '#000',
    focus: '#306FE3',
    secondaryDark: '#65615D',
    error: '#D4002A',
    stroke: '#DBDBE0',
    border: '#D2D6ED',
    mainDark: '#49516F',
    coloumn: '#FFF0F2',
    coloumnHover: '#FFC1CA',
    checked: '#5BA227',
    popupOverlay: 'rgba(41, 48, 74, .7)'
  },
  breakpoints: {
    xxl: '1800px',
    xl: '1440px',
    lg: '1200px',
    md: '992px',
    sm: '767px',
    xs: '576px'
  }
};
