import styled from 'styled-components';

export const SubheaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const TableWrapper = styled.div`
  position: relative;
`;

export const GeneratePassword = styled.div`
  color: ${(p) => p.theme.colors.primary};
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  cursor: pointer;
`;
