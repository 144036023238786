import { Sidebar } from 'components/sidebar';
import type { ReactNode } from 'react';
import { Content, MainLayoutStyled } from './style';

interface MainLayoutProps {
  children: ReactNode;
}

export const MainLayout = ({ children }: MainLayoutProps) => (
  <MainLayoutStyled>
    <Sidebar />
    <Content>{children}</Content>
  </MainLayoutStyled>
);
