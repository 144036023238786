import { updateTiresReplacementsApi } from 'api/tires-replacements';
import type { ID } from 'common/types';
import { CellInput } from 'components/cell-input';
import { EditableCell } from 'components/editable-cell';
import { useAppDispatch } from 'store/store';
import { updateReplacementComment } from 'store/tires-replacements/reducer';

interface ServiceCommentProps {
  value: string;
  id: ID;
  disabled?: boolean;
}

export const ServiceComment = ({
  id,
  value,
  disabled
}: ServiceCommentProps) => {
  const dispatch = useAppDispatch();

  const handleChangeComment = (value: string) => {
    dispatch(updateReplacementComment({ id, comment: value }));
  };

  const handleBlur = () => {
    updateTiresReplacementsApi({
      id,
      body: {
        service_comment: value
      }
    });
  };

  return (
    <EditableCell withOutPaddingTop disabled={disabled}>
      <CellInput
        disabled={disabled}
        value={value}
        setValue={handleChangeComment}
        onBlur={handleBlur}
      />
    </EditableCell>
  );
};
