export const BellSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.2059 17.8918C8.03155 17.8918 5.85719 17.5497 3.79483 16.8655C3.01094 16.5973 2.41369 16.0518 2.1524 15.3398C1.88177 14.6278 1.97509 13.8419 2.40436 13.1392L3.47754 11.3731C3.70151 11.0032 3.90681 10.2635 3.90681 9.82894V7.15673C3.90681 3.71706 6.7344 0.915395 10.2059 0.915395C13.6774 0.915395 16.505 3.71706 16.505 7.15673V9.82894C16.505 10.2543 16.7103 11.0032 16.9343 11.3823L17.9981 13.1392C18.3994 13.8049 18.474 14.6093 18.2034 15.3398C17.9328 16.0703 17.3449 16.6251 16.6076 16.8655C14.5546 17.5497 12.3803 17.8918 10.2059 17.8918ZM10.2059 2.30236C7.50896 2.30236 5.30661 4.47527 5.30661 7.15673V9.82894C5.30661 10.5039 5.02665 11.5025 4.68136 12.0851L3.60819 13.8511C3.40288 14.1932 3.34689 14.5539 3.46821 14.859C3.58019 15.1734 3.86015 15.4138 4.24276 15.5432C8.14353 16.8377 12.2776 16.8377 16.1784 15.5432C16.5143 15.4323 16.7756 15.1826 16.8969 14.8497C17.0182 14.5169 16.9903 14.1563 16.8036 13.8511L15.7304 12.0851C15.3758 11.4841 15.1052 10.4947 15.1052 9.8197V7.15673C15.1052 4.47527 12.9122 2.30236 10.2059 2.30236Z"
      fill="black"
    />
    <path
      d="M11.9416 2.55201C11.8763 2.55201 11.811 2.54276 11.7457 2.52427C11.475 2.4503 11.2138 2.39482 10.9618 2.35784C10.1686 2.25613 9.40335 2.3116 8.68479 2.52427C8.42349 2.60749 8.14353 2.52427 7.96622 2.3301C7.78892 2.13592 7.73292 1.85853 7.83557 1.60888C8.21819 0.638003 9.15138 0 10.2152 0C11.2791 0 12.2123 0.628757 12.5949 1.60888C12.6882 1.85853 12.6415 2.13592 12.4642 2.3301C12.3243 2.47804 12.1283 2.55201 11.9416 2.55201Z"
      fill="black"
    />
    <path
      d="M10.2059 20C9.28203 20 8.38616 19.6301 7.73292 18.9829C7.07968 18.3356 6.70641 17.448 6.70641 16.5326H8.1062C8.1062 17.0781 8.33017 17.6144 8.72211 18.0028C9.11406 18.3911 9.65531 18.613 10.2059 18.613C11.3631 18.613 12.3056 17.6791 12.3056 16.5326H13.7054C13.7054 18.4466 12.1376 20 10.2059 20Z"
      fill="black"
    />
  </svg>
);
