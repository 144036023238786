import { useRef, useState } from 'react';
import type { OptionItem } from 'common/types';
import { useOutsideClick } from 'hooks/use-outside-click';
import { Label } from 'components/label';
import { StringCutter } from 'components/string-cutter';
import { ArrowSvg } from 'components/svg/arrow-svg';
import { useTheme } from 'styled-components';
import {
  SelectHeader,
  SelectHeaderTitle,
  SelectOptionItem,
  SelectOptions,
  SelectWrapper
} from './style';

interface SelectProps {
  label?: string;
  value?: OptionItem;
  setValue: (value: OptionItem) => void;
  options: OptionItem[];
  placeholder?: string;
  isStatic?: boolean;
  mt?: string;
  mb?: string;
  openUp?: boolean;
  disabled?: boolean;
}

export const Select = ({
  label,
  value,
  options,
  setValue,
  placeholder = 'Оберіть зі списку',
  mt,
  mb,
  isStatic,
  openUp,
  disabled
}: SelectProps) => {
  const ref = useRef(null);
  const theme = useTheme();
  useOutsideClick(ref, () => setShowOptions(false));
  const [showOptions, setShowOptions] = useState(false);

  const handleShowOptions = () => {
    if (!disabled) {
      setShowOptions((state) => !state);
    }
  };

  const handleSelect = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: OptionItem
  ) => {
    e.stopPropagation();
    setValue(value);
    setShowOptions(false);
  };

  return (
    <SelectWrapper ref={ref} mt={mt} mb={mb}>
      {label && <Label>{label}</Label>}
      <SelectHeader $isActive={showOptions} onClick={handleShowOptions}>
        <SelectHeaderTitle>
          <StringCutter $lines="1">
            {`${value?.id}` ? value?.title : <span>{placeholder}</span>}
          </StringCutter>
        </SelectHeaderTitle>
        <ArrowSvg fill={showOptions ? theme.colors.focus : '#9DABC0'} />
      </SelectHeader>
      {showOptions && (
        <SelectOptions openUp={openUp} isStatic={isStatic}>
          {options.map((item) => (
            <SelectOptionItem
              openUp={openUp}
              onClick={(e) => handleSelect(e, item)}
              key={item.id}
            >
              {item.title}
            </SelectOptionItem>
          ))}
        </SelectOptions>
      )}
    </SelectWrapper>
  );
};
