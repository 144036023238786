import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FleetTiresReplacements } from 'models/fleet-tires-replacements';
import type { ID, ResponseWithPagination } from 'common/types';
import {
  getFleetTiresReplacementsAction,
  updateFleetTiresReplacementsAction
} from './actions';

interface TiresReplacementState {
  fleetReplacements: ResponseWithPagination<FleetTiresReplacements>;
  isLoading: boolean;
}

const initialState: TiresReplacementState = {
  fleetReplacements: { count: 0, next: null, previous: null, results: [] },
  isLoading: false
};

const fleetTiresReplacementsSlice = createSlice({
  name: 'fleet-replacements',
  initialState,
  reducers: {
    updateFleetReplacement: (
      state,
      { payload }: PayloadAction<FleetTiresReplacements>
    ) => {
      state.fleetReplacements.results = state.fleetReplacements.results.map(
        (item) => (item.id === payload.id ? payload : item)
      );
    },
    updateFleetReplacementRecipient: (
      state,
      { payload }: PayloadAction<{ id: ID; recipient: string }>
    ) => {
      state.fleetReplacements.results = state.fleetReplacements.results.map(
        (item) =>
          item.id === payload.id
            ? { ...item, recipient_data: payload.recipient }
            : item
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      getFleetTiresReplacementsAction.fulfilled,
      (state, { payload }) => {
        state.fleetReplacements = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(getFleetTiresReplacementsAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFleetTiresReplacementsAction.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      updateFleetTiresReplacementsAction.fulfilled,
      (state, { payload }) => {
        state.fleetReplacements.results = state.fleetReplacements.results.map(
          (item) => (item.id === payload.id ? payload : item)
        );
      }
    );
  }
});

export const { updateFleetReplacement, updateFleetReplacementRecipient } =
  fleetTiresReplacementsSlice.actions;
export const fleetTiresReplacementsReducer =
  fleetTiresReplacementsSlice.reducer;
