import { HeaderBtn } from 'components/header-btn';
import { CalendarSvg } from 'components/svg/calendar-svg';
import type { Dispatch, SetStateAction } from 'react';
import { useRef, useState } from 'react';
import { DateRange } from 'components/date-range';
import { useOutsideClick } from 'hooks/use-outside-click';
import { useSearchParams } from 'react-router-dom';
import { CalendarStyled, CalendarWrapper } from './style';

interface CalendarProps {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
}

export const Calendar = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate
}: CalendarProps) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  useOutsideClick(ref, () => setShow(false));
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    setShow(false);
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  return (
    <CalendarWrapper ref={ref}>
      <HeaderBtn
        onClick={() => setShow((state) => !state)}
        icon={<CalendarSvg />}
        isActive={!!(show || startDate || endDate)}
      >
        Дата надходження
      </HeaderBtn>
      <CalendarStyled>
        {show && (
          <DateRange
            onSubmit={() => {}}
            onReset={handleClear}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        )}
      </CalendarStyled>
    </CalendarWrapper>
  );
};
