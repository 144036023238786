import { createSlice } from '@reduxjs/toolkit';
import { getApiError } from 'helpers/get-api-error';
import type { User } from 'models/user';
import { storage } from 'services/storage';
import { fetchUserMe } from './actions';

interface AuthState {
  isAuthorized: 'yes' | 'no' | 'needToCheck';
  user: User | null;
  error: string;
  isLoading: boolean;
}

const initialState: AuthState = {
  isAuthorized: 'needToCheck',
  user: null,
  error: '',
  isLoading: false
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logOut: (state) => {
      state.isAuthorized = 'no';
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserMe.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.isAuthorized = 'yes';
    });
    builder.addCase(fetchUserMe.rejected, (state, { payload }) => {
      storage.logOut();
      state.user = null;
      state.error = getApiError(payload);
      state.isAuthorized = 'no';
    });
  }
});

export const { logOut } = userSlice.actions;
export const userReducer = userSlice.reducer;
