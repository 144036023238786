import { BtnSecondary } from 'components/btn-secondary';
import { BtnSubmit } from 'components/btn-submit';
import { BtnGroupStyled } from './style';

interface BtnGroupProps {
  onSubmit?: () => void;
  onCancel: () => void;
  submitTitle?: string;
  disabled?: boolean;
}

export const BtnGroup = ({
  onSubmit,
  onCancel,
  submitTitle = 'Створити',
  disabled
}: BtnGroupProps) => (
  <BtnGroupStyled>
    <BtnSecondary onClick={onCancel} minWidth="130px">
      Відмінити
    </BtnSecondary>
    <BtnSubmit disabled={disabled} onClick={onSubmit} minWidth="130px">
      {submitTitle}
    </BtnSubmit>
  </BtnGroupStyled>
);
