import { useLocation, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { toggleMenu } from 'store/menu/reducer';
import { useTheme } from 'styled-components';
import { UpdatedSvg } from 'components/svg/updated-svg';
import { MenuItemLink, MenuItemStyled } from './style';

interface MenuItemProps {
  path: string;
  title: string;
  tag?: string;
  showNotif?: boolean;
}

export const MenuItem = ({ path, title, tag, showNotif }: MenuItemProps) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const { pathname } = useLocation();

  const handleCloseMenu = () => {
    if (window.innerWidth <= +theme.breakpoints.sm.slice(0, -2)) {
      dispatch(toggleMenu());
    }
    if (pathname === path && showNotif) {
      if (searchParams.has('updated')) {
        searchParams.delete('updated');
      } else {
        searchParams.set('updated', 'true');
      }
      setSearchParams(searchParams);
    }
  };

  const isActive = tag ? pathname.indexOf(tag) > -1 : undefined;

  return (
    <MenuItemStyled onClick={handleCloseMenu} $isActive={isActive}>
      <MenuItemLink to={path}>
        {title}
        {showNotif && <UpdatedSvg />}
      </MenuItemLink>
    </MenuItemStyled>
  );
};
