import { updateFleetVehicleApi } from 'api/fleet-vehicle';
import type { ID } from 'common/types';
import { CellInput } from 'components/cell-input';
import { EditableCell } from 'components/editable-cell';
import { updateFleetVehicle } from 'store/fleet-vehicle/reducer';
import { useAppDispatch } from 'store/store';

interface DriverPhoneProps {
  value: string;
  id: ID;
  disabled?: boolean;
}

export const DriverPhone = ({ id, value, disabled }: DriverPhoneProps) => {
  const dispatch = useAppDispatch();

  const handleChange = (value: string) => {
    dispatch(updateFleetVehicle({ id, driver_phone: value }));
  };

  const handleBlur = () => {
    updateFleetVehicleApi({
      id,
      body: {
        driver_phone: value
      }
    });
  };

  return (
    <EditableCell withOutPaddingTop disabled={disabled}>
      <CellInput onBlur={handleBlur} value={value} setValue={handleChange} />
    </EditableCell>
  );
};
