import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFleetVehicleApi, updateFleetVehicleApi } from 'api/fleet-vehicle';

export enum FleetVehicleAction {
  GET_FLEET_VEHICLE = 'fleet-vehicle/get-fleet-vehicle',
  UPDATE_FLEET_VEHICLE = 'fleet-vehicle/update-fleet-vehicle'
}

export const getFleetVehicleAction = createAsyncThunk(
  FleetVehicleAction.GET_FLEET_VEHICLE,
  getFleetVehicleApi
);

export const updateFleetVehicleAction = createAsyncThunk(
  FleetVehicleAction.UPDATE_FLEET_VEHICLE,
  updateFleetVehicleApi
);
