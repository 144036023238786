import { StorageKey } from 'common/enums/storage';
import { getCookie, removeCookie, setCookie } from 'typescript-cookie';
import jwt_decode from 'jwt-decode';
import { AppRoute } from 'common/enums/app/app-route';

export interface IStorage {
  getAccessToken(): string | null;
  getRefreshToken(): string | undefined;
  setAccessTokens(accessToken: string): void;
  setRefreshToken(refreshToken: string): void;
  logOut(): void;
}

class Storage implements IStorage {
  public getAccessToken(): string | null {
    return localStorage.getItem(StorageKey.ACCESS_TOKEN);
  }

  public getRefreshToken(): string | undefined {
    return getCookie(StorageKey.REFRESH_TOKEN);
  }

  public setRefreshToken(refreshToken: string): void {
    const { exp: refreshExp } = jwt_decode<{ exp: string }>(refreshToken);
    setCookie(StorageKey.REFRESH_TOKEN, refreshToken, {
      expires: new Date(refreshExp + Date.now()),
      path: AppRoute.ROOT
    });
  }

  public setAccessTokens(accessToken: string): void {
    localStorage.setItem(StorageKey.ACCESS_TOKEN, accessToken);
  }

  public logOut(): void {
    localStorage.removeItem(StorageKey.ACCESS_TOKEN);
    removeCookie(StorageKey.REFRESH_TOKEN, { path: AppRoute.ROOT });
  }
}

// class Storage implements IStorage {
//   public getAccessToken(): string | null {
//     return sessionStorage.getItem(StorageKey.ACCESS_TOKEN);
//   }

//   public getRefreshToken(): string | null {
//     return sessionStorage.getItem(StorageKey.REFRESH_TOKEN);
//   }

//   public setRefreshToken(refreshToken: string): void {
//     sessionStorage.setItem(StorageKey.REFRESH_TOKEN, refreshToken);
//   }

//   public setAccessTokens(accessToken: string): void {
//     sessionStorage.setItem(StorageKey.ACCESS_TOKEN, accessToken);
//   }

//   public logOut(): void {
//     sessionStorage.removeItem(StorageKey.ACCESS_TOKEN);
//     sessionStorage.removeItem(StorageKey.REFRESH_TOKEN);
//   }
// }

export const storage = new Storage();
