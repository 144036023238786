import styled from 'styled-components';

export const BtnSubmitStyled = styled.button<{ $minWidth?: string }>`
  padding: 9px 20px;
  min-width: ${(p) => p.$minWidth || 'unset'};
  height: 40px;
  border-radius: 6px;
  background: ${(p) => p.theme.colors.secondaryDark};
  cursor: pointer;
  color: ${(p) => p.theme.colors.white};
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  &:disabled {
    background: ${(p) => p.theme.colors.secondary};
    color: ${(p) => p.theme.colors.secondaryDark};
    cursor: default;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    height: 48px;
  }
`;
