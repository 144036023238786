import styled, { keyframes } from 'styled-components';

const rotation = keyframes`
 from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoaderStyled = styled.div<{
  $position: 'static' | 'absolute';
  $place?: {
    left?: string;
    right?: string;
    top?: string;
    bottom?: string;
  };
  $isBig?: boolean;
}>`
  position: ${(p) => p.$position};
  left: ${(p) => p.$place?.left || 'unset'};
  right: ${(p) => p.$place?.right || 'unset'};
  bottom: ${(p) => p.$place?.bottom || 'unset'};
  top: ${(p) => p.$place?.top || 'unset'};
  width: ${(p) => (p.$isBig ? '32px' : '16px')};
  height: ${(p) => (p.$isBig ? '32px' : '16px')};
  border: 2px solid ${(p) => p.theme.colors.primary};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
  animation: ${rotation} 1s linear infinite;
  margin: 0 auto;
`;
