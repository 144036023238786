import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FleetTiresPurchases } from 'models/fleet-tires-purchases';
import type { ID, ResponseWithPagination } from 'common/types';
import {
  getFleetTiresPurchasesAction,
  updateFleetTiresPurchasesAction
} from './actions';

interface TiresPurchasesState {
  fleetPurchases: ResponseWithPagination<FleetTiresPurchases>;
  isLoading: boolean;
}

const initialState: TiresPurchasesState = {
  fleetPurchases: { count: 0, next: null, previous: null, results: [] },
  isLoading: false
};

const fleetTiresPurchasesSlice = createSlice({
  name: 'fleet-purchases',
  initialState,
  reducers: {
    updateFleetPurchases: (
      state,
      { payload }: PayloadAction<FleetTiresPurchases>
    ) => {
      state.fleetPurchases.results = state.fleetPurchases.results.map((item) =>
        item.id === payload.id ? payload : item
      );
    },
    updateFleetPurchasesComment: (
      state,
      { payload }: PayloadAction<{ id: ID; comment: string }>
    ) => {
      state.fleetPurchases.results = state.fleetPurchases.results.map((item) =>
        item.id === payload.id ? { ...item, comment: payload.comment } : item
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      getFleetTiresPurchasesAction.fulfilled,
      (state, { payload }) => {
        state.fleetPurchases = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(getFleetTiresPurchasesAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFleetTiresPurchasesAction.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      updateFleetTiresPurchasesAction.fulfilled,
      (state, { payload }) => {
        state.fleetPurchases.results = state.fleetPurchases.results.map(
          (item) => (item.id === payload.id ? payload : item)
        );
      }
    );
  }
});

export const { updateFleetPurchases, updateFleetPurchasesComment } =
  fleetTiresPurchasesSlice.actions;
export const fleetTiresPurchasesReducer = fleetTiresPurchasesSlice.reducer;
