import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getFleetTiresReplacementsApi,
  updateFleetTiresReplacementsApi
} from 'api/fleet-tires-replacements';

export enum FleetTiresReplacementsAction {
  GET_FLEET_TIRES_REPPLACEMENTS = 'fleet-replacements/get-fleet-tires-replacements',
  UPDATE_FLEET_TIRES_REPPLACEMENTS = 'fleet-replacements/update-fleet-tires-replacements'
}

export const getFleetTiresReplacementsAction = createAsyncThunk(
  FleetTiresReplacementsAction.GET_FLEET_TIRES_REPPLACEMENTS,
  getFleetTiresReplacementsApi
);

export const updateFleetTiresReplacementsAction = createAsyncThunk(
  FleetTiresReplacementsAction.UPDATE_FLEET_TIRES_REPPLACEMENTS,
  updateFleetTiresReplacementsApi
);
