import { ApiPath } from 'common/enums/http/api-path';
import type { ID, ResponseWithPagination } from 'common/types';
import type { Company } from 'models/company';
import { http } from 'services/http';

export interface CreateCompany {
  company_name: string;
  company_role: ID;
  email: string;
  password_text?: string;
  company_id: string;
}

export const getCompanies = (page?: string) =>
  http.get<ResponseWithPagination<Company>>(
    `${ApiPath.COMPANIES}?page=${page || 1}`
  );

export const createCompany = (data: CreateCompany) =>
  http.post<Company>({
    url: ApiPath.COMPANIES,
    options: {
      body: data
    }
  });

export const deleteCompanies = (ids: ID[]) =>
  http.delete<null>({ url: ApiPath.COMPANIES, ids });

export const updateCompany = ({
  id,
  data
}: {
  id: ID;
  data: Partial<CreateCompany>;
}) =>
  http.patch<Company>({
    url: `${ApiPath.COMPANIES}${id}/`,
    options: {
      body: data
    }
  });

export const exportDataApi = () =>
  http.post<Blob>({
    url: ApiPath.COMPANIES_EXPORT,
    options: {
      body: {},
      isBlob: true
    }
  });
