import styled from 'styled-components';

export const InputWrapper = styled.div<{ $focus?: boolean; $error?: boolean }>`
  margin-top: 20px;
  position: relative;
  padding: 15px 54px 15px 64px;
  height: 60px;
  border-radius: 6px;
  border: 1px solid
    ${(p) =>
      p.$error
        ? p.theme.colors.error
        : p.$focus
        ? p.theme.colors.focus
        : p.theme.colors.secondary};

  @media (max-width: ${(p) => p.theme.breakpoints.xs}) {
    margin-top: 16px;
    height: 54px;
    padding: 15px 35px 15px 49px;
  }
`;

export const InputIcon = styled.div`
  position: absolute;
  left: 20px;
  top: 15px;
  width: 30px;
  height: 30px;
  svg {
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.xs}) {
    width: 24px;
    height: 24px;
    left: 15px;
  }
`;

export const InputStyled = styled.input`
  display: block;
  width: 100%;
  color: ${(p) => p.theme.colors.black};
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  height: 30px;
  border: none;
  background: transparent;
  outline: none;
  &::placeholder {
    color: ${(p) => p.theme.colors.secondaryDark};
  }

  @media (max-width: ${(p) => p.theme.breakpoints.xs}) {
    font-size: 16px;
    height: 24px;
  }
`;

export const Eye = styled.div`
  position: absolute;
  right: 20px;
  top: 18px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  svg {
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.xs}) {
    width: 20px;
    height: 20px;
    right: 17px;
  }
`;

export const HelpText = styled.p`
  margin-top: 3px;
  color: ${(p) => p.theme.colors.error};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.24px;
`;
