import { createSlice } from '@reduxjs/toolkit';
import type { Report } from 'models/report';
import { getReportsAction } from './actions';

interface ReportsState {
  reports: Report[];
  isLoading: boolean;
}

const initialState: ReportsState = {
  reports: [],
  isLoading: false
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReportsAction.fulfilled, (state, { payload }) => {
      state.reports = payload;
      state.isLoading = false;
    });
    builder.addCase(getReportsAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getReportsAction.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const reportsReducer = reportsSlice.reducer;
