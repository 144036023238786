import { ApiPath } from 'common/enums/http/api-path';
import type { ID, ResponseWithPagination } from 'common/types';
import type { FleetVehicle } from 'models/fleet-vehicle';
import { http } from 'services/http';

export const getFleetVehicleApi = (params?: string) =>
  http.get<ResponseWithPagination<FleetVehicle>>(
    `${ApiPath.FLEET_VEHICLE}${params || ''}`
  );

export const updateFleetVehicleApi = ({
  id,
  body
}: {
  id: ID;
  body: Partial<Record<keyof FleetVehicle, unknown>>;
}) =>
  http.patch<FleetVehicle>({
    url: `${ApiPath.FLEET_VEHICLE}${id}/`,
    options: {
      body
    }
  });
