import type { Dispatch, SetStateAction } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CustomDatePicker } from '../custom-date-picker';
import { CalendarContainer, DateRangeStyled, Footer } from './style';

interface DateRangeProps {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
  onSubmit: () => void;
  onReset: () => void;
}

export const DateRange = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onReset
}: DateRangeProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handlechangeDate = (date: Date | null, isStart?: boolean) => {
    if (isStart) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  return (
    <DateRangeStyled>
      <CalendarContainer>
        <CustomDatePicker
          isRange
          onChange={(date) => handlechangeDate(date as Date | null, true)}
          selected={startDate}
          selectsStart
          startDate={startDate}
          endDate={endDate}
        />
        <CustomDatePicker
          isRange
          selected={endDate}
          onChange={(date) => handlechangeDate(date as Date | null)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
        />
      </CalendarContainer>

      <Footer>
        <span onClick={onReset}>Скинути</span>
      </Footer>
    </DateRangeStyled>
  );
};
