import { ApiPath } from 'common/enums/http/api-path';
import type { ResponseWithPagination } from 'common/types';
import type { AvisContactManagers } from 'models/avis-contact-managers';
import type { AvisContacts } from 'models/avis-contacts';
import { http } from 'services/http';

export const getContactsApi = () =>
  http.get<ResponseWithPagination<AvisContacts>>(ApiPath.AVIS_CONTACTS);

export const getContactManagersApi = () =>
  http.get<ResponseWithPagination<AvisContactManagers>>(
    ApiPath.AVIS_CONTACT_MANAGERS
  );
