import type { ChangeEventHandler } from 'react';
import { useEffect, useState } from 'react';
import { SearchSvg } from 'components/svg/search-svg';
import { CloseSvg } from 'components/svg/close-svg';
import { useDebounce } from 'hooks/use-debounce';
import { Loader } from 'components/loader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import type { AppRoute } from 'common/enums/app/app-route';
import type { ID, ResponseWithPagination } from 'common/types';
import { removeDuplicatesByField } from 'helpers/remove-duplicates-by-field';
import { useTheme } from 'styled-components';
import { useAppDispatch } from 'store/store';
import { toggleMenu } from 'store/menu/reducer';
import {
  CloseIcon,
  Options,
  OptionsItem,
  SearchIcon,
  SearchInput,
  SearchStyled,
  SearchWrapper
} from './style';

type SearchResponse = { id: ID; vehicle_plate: string };

interface SearchProps {
  rootPath: AppRoute;
  fetcher: (params?: string) => Promise<ResponseWithPagination<SearchResponse>>;
}

export const Search = ({ rootPath, fetcher }: SearchProps) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState('');
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [options, setOptions] = useState<SearchResponse[]>([]);
  const debounce = useDebounce(setSearch, 500);
  const theme = useTheme();

  useEffect(() => {
    if (search) {
      setIsLoading(true);
      fetcher(`?search=${search}`)
        .then((res) => {
          setOptions(res.results);
        })
        .catch(console.log)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [search, fetcher]);

  useEffect(() => {
    setValue(searchParams.get('search') || '');
  }, [searchParams]);

  const handleClose = () => {
    setValue('');
    setSearch('');
    setOptions([]);
    searchParams.delete('search');
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    setValue(value);
    debounce(value);
  };

  const handleSelect = (value: string) => {
    setSearch('');
    setValue(value);
    navigate(`${rootPath}?search=${value}`);
    if (window.innerWidth <= +theme.breakpoints.sm.slice(0, -2)) {
      dispatch(toggleMenu());
    }
  };

  return (
    <SearchWrapper>
      <SearchStyled $isActive={!!value}>
        <SearchIcon>
          <SearchSvg />
        </SearchIcon>
        <SearchInput
          value={value}
          onChange={handleChange}
          placeholder="Пошук (№ декларації, ДНЗ)"
        />
        {value ? (
          isLoading ? (
            <Loader />
          ) : (
            <CloseIcon onClick={handleClose}>
              <CloseSvg />
            </CloseIcon>
          )
        ) : (
          ''
        )}
      </SearchStyled>
      {!!options.length && search && (
        <Options>
          {removeDuplicatesByField(options, 'vehicle_plate').map((item) => (
            <OptionsItem
              onClick={() => handleSelect(item.vehicle_plate)}
              key={item.id}
            >
              {item.vehicle_plate}
            </OptionsItem>
          ))}
        </Options>
      )}
    </SearchWrapper>
  );
};
