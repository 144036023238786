import styled from 'styled-components';

interface SelectHeaderProps {
  $isActive?: boolean;
}

export const SelectWrapper = styled.div<{ mt?: string; mb?: string }>`
  position: relative;
  margin-top: ${(p) => p.mt || 0};
  margin-bottom: ${(p) => p.mb || 0};
`;

export const SelectHeader = styled.div<SelectHeaderProps>`
  padding: 5px 12px;
  height: 36px;
  width: 100%;
  padding-right: 16px;
  position: relative;
  border: 1px solid
    ${(p) => (p.$isActive ? p.theme.colors.focus : p.theme.colors.border)};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  > svg {
    min-width: 16px;
    transform: ${(p) => (p.$isActive ? 'rotate(180deg)' : 'rotate(0)')};
  }
  user-select: none;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    height: 44px;
  }
`;

export const SelectHeaderTitle = styled.div`
  width: calc(100% - 28px);
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.black};
  span {
    color: ${(p) => p.theme.colors.stroke};
  }
`;

export const SelectOptions = styled.div<{
  isStatic?: boolean;
  openUp?: boolean;
  customWidth?: string;
}>`
  padding: 8px 0;
  position: ${(p) => (p.isStatic ? 'static' : 'absolute')};
  margin-top: ${(p) => (p.isStatic ? '6px' : '0')};
  left: 0;
  width: ${(p) => p.customWidth || '100%'};
  top: ${(p) => (p.openUp ? 'unset' : 'calc(100% + 6px)')};
  bottom: ${(p) => (p.openUp ? 'calc(100% + 6px)' : 'unset')};
  z-index: 11;
  border: 1px solid ${(p) => p.theme.colors.stroke};
  box-shadow:
    4px 5px 9px 0px rgba(63, 81, 126, 0.08),
    -3px -3px 9px 0px rgba(63, 81, 126, 0.06);
  border-radius: 6px;
  max-height: 200px;
  overflow: auto;
  background: ${(p) => p.theme.colors.white};
`;

export const SelectOptionItem = styled.div<{ openUp?: boolean }>`
  padding: ${(p) => (p.openUp ? '7px 12px' : '9px 24px')};
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.black};
  background-color: ${(p) => p.theme.colors.white};
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.theme.colors.primary};
    color: ${(p) => p.theme.colors.white};
  }
`;
