import { ApiPath } from 'common/enums/http/api-path';
import type { ID, ResponseWithPagination } from 'common/types';
import type { TiresReplacements } from 'models/tires-replacements';
import { http } from 'services/http';

export interface UpdateTiresReplacements {
  id: ID;
  body: Partial<Record<keyof TiresReplacements, unknown>>;
}

export const getTiresReplacementsApi = (params?: string) =>
  http.get<ResponseWithPagination<TiresReplacements>>(
    `${ApiPath.TIRES_REPLACEMENTS}${params || ''}`
  );

export const printLabelsApi = (ids: ID[]) =>
  http.get(`${ApiPath.PRINT_LABELS}?labels_ids=${ids.join(',')}`, true, true);

export const updateTiresReplacementsApi = ({
  id,
  body
}: UpdateTiresReplacements) =>
  http.patch<TiresReplacements>({
    url: `${ApiPath.TIRES_REPLACEMENTS}${id}/`,
    options: {
      body
    }
  });
