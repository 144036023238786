import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Company } from 'models/company';
import type { ID, OptionItem, ResponseWithPagination } from 'common/types';
import {
  createCompanyAction,
  fetchCompanies,
  updateCompanyAction
} from './actions';

interface CompaniesState {
  companies: ResponseWithPagination<Company>;
  isLoading: boolean;
}

const initialState: CompaniesState = {
  companies: { count: 0, next: null, previous: null, results: [] },
  isLoading: false
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    updateRole: (
      state,
      { payload }: PayloadAction<{ companyId: ID; role: OptionItem }>
    ) => {
      state.companies.results = state.companies.results.map((item) =>
        item.id === payload.companyId
          ? { ...item, company_role: payload.role }
          : item
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanies.fulfilled, (state, { payload }) => {
      state.companies = payload;
      state.isLoading = false;
    });
    builder.addCase(fetchCompanies.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCompanies.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createCompanyAction.fulfilled, (state, { payload }) => {
      state.companies.results = [payload, ...state.companies.results];
    });
    builder.addCase(updateCompanyAction.fulfilled, (state, { payload }) => {
      state.companies.results = state.companies.results.map((item) =>
        item.id === payload.id ? payload : item
      );
    });
  }
});

export const { updateRole } = companiesSlice.actions;
export const companiesReducer = companiesSlice.reducer;
