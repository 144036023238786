import type { Dispatch, SetStateAction } from 'react';
import { useState, type HTMLInputTypeAttribute, useRef } from 'react';
import { AuthEmailSvg } from 'components/svg/auth-email-svg';
import { AuthPasswordSvg } from 'components/svg/auth-password-svg';
import { EyeOpenSvg } from 'components/svg/eye-open-svg';
import { EyeCloseSvg } from 'components/svg/eye-close-svg';
import { Eye, HelpText, InputIcon, InputStyled, InputWrapper } from './style';

interface LoginInputProps {
  type?: HTMLInputTypeAttribute;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  placeholder?: string;
  error?: string;
}

export const LoginInput = ({
  type,
  value,
  setValue,
  placeholder,
  error
}: LoginInputProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [focus, setFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [needToValidate, setNeedToValidate] = useState(false);

  const handleWrapperClick = () => {
    if (ref.current) {
      ref.current.focus();
    }
  };

  const handleOnBlur = () => {
    setFocus(false);
    setNeedToValidate(true);
  };

  return (
    <>
      {' '}
      <InputWrapper $focus={focus} onClick={handleWrapperClick}>
        <InputIcon>
          {type === 'email' ? <AuthEmailSvg /> : <AuthPasswordSvg />}
        </InputIcon>
        <InputStyled
          onFocus={() => setFocus(true)}
          onBlur={handleOnBlur}
          type={showPassword ? 'text' : type}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          ref={ref}
          placeholder={placeholder}
        />
        {type === 'password' && (
          <Eye onClick={() => setShowPassword((state) => !state)}>
            {showPassword ? <EyeOpenSvg /> : <EyeCloseSvg />}
          </Eye>
        )}
      </InputWrapper>
      {error && needToValidate && <HelpText>{error}</HelpText>}
    </>
  );
};
