import type { HeaderItem } from 'components/data-table';
import type { ReactNode } from 'react';

interface UsersTable {
  id: number;
  company_id: string;
  login: string;
  email: string;
  role: ReactNode;
  password: string;
  generatePassword: ReactNode;
}

export const tableHeaders: HeaderItem<UsersTable>[] = [
  { id: 'login', title: 'Логін', width: '150px', subtitle: 'Назва компанії' },
  { id: 'company_id', title: 'ЄДРПОУ/ІПН', width: '100px' },
  { id: 'email', title: 'Електронна пошта', width: '218px' },
  { id: 'role', title: 'Роль', width: '134px', editable: true },
  { id: 'password', title: 'Пароль', width: '171px' },
  { id: 'generatePassword', title: '' }
];
