import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getTiresDisposalsApi,
  updateTiresDisposalsApi
} from 'api/tires-disposals';

export enum TiresDisposalsAction {
  GET_TIRES_DISPOSALS = 'tires-disposals/get-tires-disposals',
  UPDATE_TIRES_DISPOSALS = 'tires-disposals/update-fleet-vehicle'
}

export const getTiresDisposalsAction = createAsyncThunk(
  TiresDisposalsAction.GET_TIRES_DISPOSALS,
  getTiresDisposalsApi
);

export const updateTiresDisposalsAction = createAsyncThunk(
  TiresDisposalsAction.UPDATE_TIRES_DISPOSALS,
  updateTiresDisposalsApi
);
