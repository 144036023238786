import type { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useTypedSelector } from 'store/store';
import { useCheckAuth } from 'hooks/use-check-auth';
import { AppRoute } from 'common/enums/app/app-route';

interface PublickRouteProps {
  component: FC;
}

const PublickRoute = ({ component: Component, ...rest }: PublickRouteProps) => {
  const { isAuthorized } = useTypedSelector((state) => state.user);
  useCheckAuth();

  if (isAuthorized === 'needToCheck') return null;

  return isAuthorized === 'yes' ? (
    <Navigate to={{ pathname: AppRoute.ROOT }} />
  ) : (
    <Component {...rest} />
  );
};

export { PublickRoute };
