import { useEffect } from 'react';

export const useBeforeUnload = (condition: boolean) => {
  useEffect(() => {
    const beforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      if (condition) {
        e.returnValue = '';

        return;
      }

      e.returnValue = null;
    };

    if (!condition) {
      window.removeEventListener('beforeunload', beforeUnload);
    } else {
      window.addEventListener('beforeunload', beforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [condition]);
};
