import type { ReactNode } from 'react';
import { HistoryContainerStyled } from './style';

interface HistoryContainerProps {
  children: ReactNode;
  withPagination?: boolean;
}

export const HistoryContainer = ({
  children,
  withPagination
}: HistoryContainerProps) => (
  <HistoryContainerStyled $withPagination={withPagination}>
    {children}
  </HistoryContainerStyled>
);
