import { updateTiresMovementsApi } from 'api/tires-movements';
import type { ID } from 'common/types';
import { CellInput } from 'components/cell-input';
import { EditableCell } from 'components/editable-cell';
import type { ServiceStation } from 'models/tires-movements';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { updateTiresMovementsComment } from 'store/tires-movements.ts/reducer';

interface ServiceCommentProps {
  value: string;
  id: ID;
  disabled?: boolean;
  serviceStationSender: ServiceStation | null;
}

export const ServiceComment = ({
  id,
  value,
  disabled,
  serviceStationSender
}: ServiceCommentProps) => {
  const dispatch = useAppDispatch();
  const { user } = useTypedSelector((state) => state.user);

  const handleChangeComment = (value: string) => {
    const isSender = user?.company_id === serviceStationSender?.company_id;
    dispatch(
      updateTiresMovementsComment({
        id,
        comment: value,
        key: isSender ? 'sender_comment' : 'receiver_comment'
      })
    );
  };

  const handleBlur = () => {
    const isSender = user?.company_id === serviceStationSender?.company_id;
    updateTiresMovementsApi({
      id,
      body: {
        [isSender ? 'sender_comment' : 'receiver_comment']: value
      }
    });
  };

  return (
    <EditableCell withOutPaddingTop disabled={disabled}>
      <CellInput
        disabled={disabled}
        value={value}
        setValue={handleChangeComment}
        onBlur={handleBlur}
      />
    </EditableCell>
  );
};
