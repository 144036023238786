export const EyeOpenSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 5.24869C4.5 5.24869 1.5 11.9994 1.5 11.9994C1.5 11.9994 4.5 18.7487 12 18.7487C19.5 18.7487 22.5 11.9994 22.5 11.9994C22.5 11.9994 19.5 5.24869 12 5.24869Z"
      stroke="#65615D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 15.7495C14.0711 15.7495 15.75 14.0705 15.75 11.9995C15.75 9.92841 14.0711 8.24948 12 8.24948C9.92893 8.24948 8.25 9.92841 8.25 11.9995C8.25 14.0705 9.92893 15.7495 12 15.7495Z"
      stroke="#65615D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
