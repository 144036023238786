import { createSlice } from '@reduxjs/toolkit';
import type { ServiceAllUpdates } from 'models/service-all-updates';
import type { ResponseWithPagination } from 'common/types';
import { getServiceAllUpdatesAction } from './actions';

interface ServiceAllUpdatesState {
  serviceUpdates: ResponseWithPagination<ServiceAllUpdates>;
}

const initialState: ServiceAllUpdatesState = {
  serviceUpdates: { count: 0, next: null, previous: null, results: [] }
};

const serviceUpdatesSlice = createSlice({
  name: 'service-all-updates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getServiceAllUpdatesAction.fulfilled,
      (state, { payload }) => {
        state.serviceUpdates = payload;
      }
    );
  }
});

export const serviceUpdatesReducer = serviceUpdatesSlice.reducer;
