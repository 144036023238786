import styled from 'styled-components';

export const LoginSubmitStyled = styled.button`
  width: 100%;
  margin-top: 30px;
  padding: 13px 20px;
  height: 60px;
  border-radius: 6px;
  background: ${(p) => p.theme.colors.secondaryDark};
  color: ${(p) => p.theme.colors.white};
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
  cursor: pointer;
  &:disabled {
    background: ${(p) => p.theme.colors.secondary};
    color: ${(p) => p.theme.colors.secondaryDark};
  }

  @media (max-width: ${(p) => p.theme.breakpoints.xs}) {
    margin-top: 20px;
  }
`;
