import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { TiresReplacements } from 'models/tires-replacements';
import type { ID, ResponseWithPagination } from 'common/types';
import {
  getTiresReplacementsAction,
  updateTiresReplacementsAction
} from './actions';

interface TiresReplacementState {
  replacements: ResponseWithPagination<TiresReplacements>;
  isLoading: boolean;
}

const initialState: TiresReplacementState = {
  replacements: { count: 0, next: null, previous: null, results: [] },
  isLoading: false
};

const tiresReplacementsSlice = createSlice({
  name: 'replacements',
  initialState,
  reducers: {
    updateReplacement: (
      state,
      { payload }: PayloadAction<TiresReplacements>
    ) => {
      state.replacements.results = state.replacements.results.map((item) =>
        item.id === payload.id ? payload : item
      );
    },
    updateReplacementComment: (
      state,
      { payload }: PayloadAction<{ id: ID; comment: string }>
    ) => {
      state.replacements.results = state.replacements.results.map((item) =>
        item.id === payload.id
          ? { ...item, service_comment: payload.comment }
          : item
      );
    },
    updateReplacementTreadDepth: (
      state,
      { payload }: PayloadAction<{ id: ID; treadDepth: string }>
    ) => {
      state.replacements.results = state.replacements.results.map((item) =>
        item.id === payload.id
          ? { ...item, tread_depth: payload.treadDepth }
          : item
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      getTiresReplacementsAction.fulfilled,
      (state, { payload }) => {
        state.replacements = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(getTiresReplacementsAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTiresReplacementsAction.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      updateTiresReplacementsAction.fulfilled,
      (state, { payload }) => {
        state.replacements.results = state.replacements.results.map((item) =>
          item.id === payload.id ? payload : item
        );
      }
    );
  }
});

export const {
  updateReplacement,
  updateReplacementComment,
  updateReplacementTreadDepth
} = tiresReplacementsSlice.actions;
export const tiresReplacementsReducer = tiresReplacementsSlice.reducer;
