export const EditSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
  >
    <g clipPath="url(#clip0_337_84187)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.9405 0.162717C7.15744 -0.0542389 7.50922 -0.0542389 7.72617 0.162717L9.28172 1.71827C9.38589 1.82246 9.44444 1.96376 9.44444 2.11111C9.44444 2.25844 9.38589 2.39976 9.28172 2.50394L4.17061 7.61506C4.06644 7.71922 3.92511 7.77778 3.77778 7.77778H2.22222C1.9154 7.77778 1.66667 7.52906 1.66667 7.22222V5.66667C1.66667 5.51933 1.7252 5.378 1.82938 5.27383L6.9405 0.162717ZM7.33333 1.34123L2.77778 5.89678V6.66667H3.54766L8.10322 2.11111L7.33333 1.34123ZM0 9.44444C0 9.13761 0.248733 8.88889 0.555556 8.88889H9.44444C9.75128 8.88889 10 9.13761 10 9.44444C10 9.75128 9.75128 10 9.44444 10H0.555556C0.248733 10 0 9.75128 0 9.44444Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_337_84187">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
