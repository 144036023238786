export const FileSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M16.6546 19.8897H5.91998C5.45946 19.8897 5.0178 19.7065 4.69217 19.3805C4.36653 19.0544 4.18359 18.6122 4.18359 18.1511V2.23861C4.18359 1.7775 4.36653 1.33528 4.69217 1.00923C5.0178 0.683175 5.45946 0.5 5.91998 0.5H10.8451C11.2859 0.500901 11.711 0.664249 12.0393 0.95889L17.7842 6.12948C17.9731 6.29865 18.1242 6.50584 18.2277 6.7375C18.3311 6.96916 18.3845 7.22007 18.3845 7.47383V18.1511C18.3845 18.6111 18.2025 19.0523 17.8782 19.3782C17.554 19.7041 17.114 19.888 16.6546 19.8897ZM5.91998 1.79265C5.80185 1.79265 5.68857 1.83963 5.60504 1.92327C5.52151 2.0069 5.47459 2.12033 5.47459 2.23861V18.1511C5.47459 18.2694 5.52151 18.3828 5.60504 18.4664C5.68857 18.5501 5.80185 18.5971 5.91998 18.5971H16.6546C16.7727 18.5971 16.886 18.5501 16.9695 18.4664C17.053 18.3828 17.1 18.2694 17.1 18.1511V7.44151C17.0998 7.37073 17.0848 7.30077 17.0559 7.23619C17.027 7.1716 16.9848 7.11383 16.9321 7.06665L11.1872 1.89606C11.0897 1.82086 10.9679 1.78407 10.8451 1.79265H5.91998Z"
      fill="#313654"
    />
    <path
      d="M17.7411 7.56959H12.0543C11.6793 7.56959 11.3198 7.42047 11.0547 7.15502C10.7896 6.88957 10.6406 6.52955 10.6406 6.15415V1.50708H11.9316V6.15415C11.9316 6.18672 11.9445 6.21795 11.9675 6.24098C11.9905 6.26401 12.0217 6.27695 12.0543 6.27695H17.7411V7.56959Z"
      fill="#313654"
    />
    <path
      d="M3.10728 17.0848C2.68035 17.0797 2.27253 16.9067 1.97183 16.6032C1.67113 16.2997 1.50166 15.89 1.5 15.4625V4.92742C1.49995 4.68779 1.55313 4.45115 1.6557 4.23464C1.75826 4.01813 1.90763 3.82717 2.09299 3.67559C2.27836 3.52402 2.49507 3.41562 2.72744 3.35826C2.9598 3.3009 3.202 3.296 3.43649 3.34393L4.8243 3.5766L4.61129 4.86925L3.21056 4.61718C3.15993 4.60343 3.10682 4.60153 3.05533 4.61161C3.00384 4.6217 2.95536 4.64351 2.91364 4.67535C2.8756 4.70545 2.84482 4.74373 2.82359 4.78737C2.80236 4.83101 2.79122 4.87888 2.79099 4.92742V15.4625C2.79194 15.5107 2.80345 15.5581 2.8247 15.6014C2.84595 15.6447 2.87642 15.6828 2.91397 15.713C2.95151 15.7432 2.99522 15.7647 3.04201 15.7762C3.0888 15.7876 3.13752 15.7887 3.18474 15.7792L4.64357 15.5853L4.81139 16.8779L3.39776 17.0654C3.3019 17.0822 3.20452 17.0887 3.10728 17.0848Z"
      fill="#313654"
    />
  </svg>
);
