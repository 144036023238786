import { createSlice } from '@reduxjs/toolkit';
import type { FleetAllUpdates } from 'models/fleet-all-updates';
import type { ResponseWithPagination } from 'common/types';
import { getFleetAllUpdatesAction } from './actions';

interface FleetAllUpdatesState {
  fleetUpdates: ResponseWithPagination<FleetAllUpdates>;
}

const initialState: FleetAllUpdatesState = {
  fleetUpdates: { count: 0, next: null, previous: null, results: [] }
};

const fleetUpdatesSlice = createSlice({
  name: 'fleet-all-updates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getFleetAllUpdatesAction.fulfilled,
      (state, { payload }) => {
        state.fleetUpdates = payload;
      }
    );
  }
});

export const fleetUpdatesReducer = fleetUpdatesSlice.reducer;
