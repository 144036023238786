import { getTiresMovementsApi } from 'api/tires-movements';
import { AppRoute } from 'common/enums/app/app-route';
import { HistoryContainer } from 'components/history-container';
import { HistoryItem } from 'components/history-item';
import { Loader } from 'components/loader';
import { Navbar } from 'components/navbar';
import { NoData } from 'components/no-data';
import { Pagination, SIZE } from 'components/paginations';
import { Search } from 'components/search';
import { Subheader } from 'components/subheader';
import { UpdatedAt } from 'components/updated-at';
import { dateToHeaderFormat } from 'helpers/date';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getServiceAllUpdatesAction } from 'store/service-all-updates/actions';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { getMovementsHistoryAction } from 'store/tires-movements-history/actions';

export const MovementsHistoryPage = () => {
  const dispatch = useAppDispatch();
  const { movementsHistory, isLoading } = useTypedSelector(
    (state) => state.movementsHistory
  );
  const { serviceUpdates } = useTypedSelector((state) => state.serviceUpdates);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const page = searchParams.get('page');
    dispatch(getMovementsHistoryAction(`?page=${page || 1}`));
    dispatch(getServiceAllUpdatesAction());
  }, [dispatch, searchParams]);

  return (
    <>
      <Navbar>
        <Search fetcher={getTiresMovementsApi} rootPath={AppRoute.ROOT} />
      </Navbar>
      <Subheader title="Історія" backRoute={AppRoute.ROOT}>
        {!!serviceUpdates.results.length &&
          !!movementsHistory.results.length && (
            <UpdatedAt absoluteAlign>
              {dateToHeaderFormat(
                serviceUpdates.results[0].service_movements_table
              )}
            </UpdatedAt>
          )}
      </Subheader>
      {isLoading ? (
        <Loader isBig position="static" />
      ) : movementsHistory.results.length ? (
        <HistoryContainer withPagination={movementsHistory.count > SIZE}>
          {movementsHistory.results.map((item, i) => (
            <HistoryItem
              key={i}
              title={item.history_message}
              date={item.date}
            />
          ))}
        </HistoryContainer>
      ) : (
        <NoData height="calc(100vh - 162px)" />
      )}
      {movementsHistory.count > SIZE ? (
        <Pagination count={movementsHistory.count} />
      ) : (
        ''
      )}
    </>
  );
};
