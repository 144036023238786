export const SearchSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.66665 14.5002C3.89998 14.5002 0.833313 11.4335 0.833313 7.66683C0.833313 3.90016 3.89998 0.833496 7.66665 0.833496C11.4333 0.833496 14.5 3.90016 14.5 7.66683C14.5 11.4335 11.4333 14.5002 7.66665 14.5002ZM7.66665 1.8335C4.44665 1.8335 1.83331 4.4535 1.83331 7.66683C1.83331 10.8802 4.44665 13.5002 7.66665 13.5002C10.8866 13.5002 13.5 10.8802 13.5 7.66683C13.5 4.4535 10.8866 1.8335 7.66665 1.8335Z"
      fill="black"
    />
    <path
      d="M14.6666 15.1668C14.54 15.1668 14.4133 15.1202 14.3133 15.0202L12.98 13.6868C12.7866 13.4935 12.7866 13.1735 12.98 12.9802C13.1733 12.7868 13.4933 12.7868 13.6866 12.9802L15.02 14.3135C15.2133 14.5068 15.2133 14.8268 15.02 15.0202C14.92 15.1202 14.7933 15.1668 14.6666 15.1668Z"
      fill="black"
    />
  </svg>
);
