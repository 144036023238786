import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFleetNotificationsApi } from 'api/fleet-notifications';

export enum FleetNotificationsAction {
  GET_FLEET_NOTIFICATINS = 'fleet-notifications/get-fleet-notifications'
}

export const getFleetNotificationsAction = createAsyncThunk(
  FleetNotificationsAction.GET_FLEET_NOTIFICATINS,
  getFleetNotificationsApi
);
