import { CheckBox } from 'components/checkbox';
import { DataTable } from 'components/data-table';
import { EditableCell } from 'components/editable-cell';
import { Navbar } from 'components/navbar';
import { Subheader } from 'components/subheader';
import { useScrollHeight } from 'hooks/use-scroll-height';
import type { FleetTiresReplacements } from 'models/fleet-tires-replacements';
import { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { groupeEntities } from 'helpers/groupe-entities';
import { dateTableFormat, dateToHeaderFormat } from 'helpers/date';
import { NoData } from 'components/no-data';
import {
  getFleetTiresReplacementsAction,
  updateFleetTiresReplacementsAction
} from 'store/fleet-tires-replacements/actions';
import type { ID, OptionItem } from 'common/types';
import { TableSelect } from 'components/table-select';
import { FleetTiresActionList, TiresStorage } from 'common/constants';
import { UpdatedAt } from 'components/updated-at';
import { Loader } from 'components/loader';
import { updateNotif } from 'store/notifications/reducer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getFleetAllUpdatesAction } from 'store/fleet-all-updates/actions';
import { HeaderBtn } from 'components/header-btn';
import { AppRoute } from 'common/enums/app/app-route';
import { SIZE } from 'components/paginations';
import { tableHeaders } from './data';
import type { FleetReplacementsTable } from './data';
import { Recipient } from './recipient';

export const FleetTiresReplacementsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tableRef = useRef(null);
  const { scrollHeight } = useScrollHeight(tableRef);

  const { fleetReplacements, isLoading } = useTypedSelector(
    (state) => state.fleetTiresReplacements
  );
  const { fleetUpdates } = useTypedSelector((state) => state.fleetUpdates);

  useEffect(() => {
    const page = searchParams.get('page');
    dispatch(getFleetTiresReplacementsAction(`?page=${page || 1}`));
    dispatch(updateNotif({ fleetReplacements: false }));
    dispatch(getFleetAllUpdatesAction());
  }, [dispatch, searchParams]);

  const handleReplacementConfirmation = (id: ID, checked: boolean) => {
    dispatch(
      updateFleetTiresReplacementsAction({
        id,
        body: {
          replacement_confirmation: checked
        }
      })
    );
  };

  const handleTiresStorageSelect = useCallback(
    (id: ID, key: string, option: OptionItem) => {
      const body = {
        [key]: option.id
      };

      if (key === 'action' && option.id !== 2) {
        body.recipient_data = '';
      }
      dispatch(
        updateFleetTiresReplacementsAction({
          id,
          body
        })
      );
    },
    [dispatch]
  );

  const handleConfirm = (item: FleetTiresReplacements) => {
    dispatch(
      updateFleetTiresReplacementsAction({
        id: item.id,
        body: {
          confirmed: true,
          recipient_data: item.recipient_data,
          tire_storage: item.tire_storage.id,
          action: item.action.id,
          replacement_confirmation: item.replacement_confirmation
        }
      })
    );
  };

  const transformToTableFormat = (replacements: FleetTiresReplacements[]) =>
    replacements.map((item) => ({
      ...item,
      season: item.isHeader ? '' : item.season,
      status: item.isHeader ? '' : item.status,
      recipient_data: item.isHeader ? (
        ''
      ) : item.action.id === 2 ? (
        <Recipient
          disabled={item.confirmed}
          value={item.recipient_data}
          id={item.id}
        />
      ) : (
        ''
      ),
      tire_storage: item.isHeader ? (
        ''
      ) : (
        <TableSelect
          disabled={item.confirmed}
          options={TiresStorage}
          initialValue={item.tire_storage}
          onSelect={(value) =>
            handleTiresStorageSelect(item.id, 'tire_storage', value)
          }
        />
      ),
      paid_storage: item.isHeader ? '' : item.paid_storage ? 'Так' : 'Ні',
      confirmed_at: item.isHeader ? '' : dateTableFormat(item.confirmed_at),
      size: item.isHeader ? '' : item.size,
      diameter: item.isHeader ? '' : item.diameter,
      action: item.isHeader ? (
        ''
      ) : (
        <TableSelect
          disabled={item.confirmed}
          options={FleetTiresActionList}
          initialValue={item.action}
          onSelect={(value) =>
            handleTiresStorageSelect(item.id, 'action', value)
          }
        />
      ),
      tread_depth: item.isHeader ? '' : item.tread_depth,
      tire_model: item.isHeader ? '' : item.tire_model,
      vehicle_brand_model: item.isHeader ? item.vehicle_brand_model : '',
      vehicle_plate: item.isHeader ? item.vehicle_plate : '',
      defect: item.isHeader ? '' : item.defect,
      replacement_confirmation: item.isHeader ? (
        ''
      ) : (
        <EditableCell
          disabled={item.confirmed || item.status === "заказ обов'язковий"}
        >
          <CheckBox
            checked={item.replacement_confirmation}
            setChecked={(checked) =>
              handleReplacementConfirmation(item.id, checked)
            }
            disabled={item.confirmed || item.status === "заказ обов'язковий"}
          />
        </EditableCell>
      ),
      confirmed: item.isHeader ? (
        ''
      ) : (
        <EditableCell disabled={item.confirmed}>
          <CheckBox
            checked={item.confirmed}
            disabled={item.confirmed}
            setChecked={() => handleConfirm(item)}
          />
        </EditableCell>
      )
    }));

  return (
    <>
      <Navbar />
      <Subheader title="Заміна / Утилізація">
        {!!fleetUpdates.results.length &&
          !!fleetReplacements.results.length && (
            <UpdatedAt absoluteAlign>
              {dateToHeaderFormat(
                fleetUpdates.results[0].fleet_replacements_table
              )}
            </UpdatedAt>
          )}
        <HeaderBtn
          onClick={() => navigate(AppRoute.FLEET_REPLACEMENTS_HISTORY)}
        >
          Переглянути історію
        </HeaderBtn>
      </Subheader>
      <div ref={tableRef} style={{ position: 'relative' }}>
        {fleetReplacements.results.length ? (
          <DataTable<FleetReplacementsTable>
            headers={tableHeaders}
            data={transformToTableFormat(
              groupeEntities(fleetReplacements.results)
            )}
            checkedList={[]}
            setCheckedList={() => {}}
            actions={[]}
            withOutCheck
            customHeight={
              fleetReplacements.count >= SIZE ? scrollHeight - 64 : scrollHeight
            }
            stickyColumns={2}
            count={fleetReplacements.count}
            isFetching={isLoading}
          />
        ) : isLoading ? (
          <Loader isBig place={{ left: 'calc(50% - 16px)', top: '100px' }} />
        ) : (
          <NoData height={`${scrollHeight}px`} />
        )}
      </div>
    </>
  );
};
