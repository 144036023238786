import styled from 'styled-components';

export const ConfirmPopupStyled = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99;
  background: ${(p) => p.theme.colors.popupOverlay};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;
`;

export const PopupContent = styled.div<{ customWidth?: string }>`
  padding: 50px 75px;
  width: 100%;
  max-width: ${(p) => p.customWidth || '448px'};
  background: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.border};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  text-align: center;
  color: ${(p) => p.theme.colors.black};
`;

export const ButtonsContainer = styled.form`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;
