import { createSlice } from '@reduxjs/toolkit';
import type { FleetPurchasesHistory } from 'models/fleet-purchases-history';
import type { ResponseWithPagination } from 'common/types';
import { getFleetPurchasesHistoryAction } from './actions';

interface FleetPurchasesHistoryState {
  fleetPurchasesHistory: ResponseWithPagination<FleetPurchasesHistory>;
  isLoading: boolean;
}

const initialState: FleetPurchasesHistoryState = {
  fleetPurchasesHistory: { count: 0, next: null, previous: null, results: [] },
  isLoading: false
};

const fleetPurchasesHistorySlice = createSlice({
  name: 'fleet-purchases-history',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getFleetPurchasesHistoryAction.fulfilled,
      (state, { payload }) => {
        state.fleetPurchasesHistory = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(getFleetPurchasesHistoryAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFleetPurchasesHistoryAction.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const fleetPurchasesHistoryReducer = fleetPurchasesHistorySlice.reducer;
