import { createSlice } from '@reduxjs/toolkit';
import type { AvisContacts } from 'models/avis-contacts';
import type { AvisContactManagers } from 'models/avis-contact-managers';
import type { ResponseWithPagination } from 'common/types';
import { getAvisContactManagersAction, getAvisContactsAction } from './actions';

interface ContactsState {
  contacts: ResponseWithPagination<AvisContacts>;
  contactManagers: ResponseWithPagination<AvisContactManagers>;
}

const initialState: ContactsState = {
  contacts: { count: 0, next: null, previous: null, results: [] },
  contactManagers: { count: 0, next: null, previous: null, results: [] }
};

const fleetTiresPurchasesSlice = createSlice({
  name: 'avis-contacts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAvisContactsAction.fulfilled, (state, { payload }) => {
      state.contacts = payload;
    });
    builder.addCase(
      getAvisContactManagersAction.fulfilled,
      (state, { payload }) => {
        state.contactManagers = payload;
      }
    );
  }
});

export const contactsReducer = fleetTiresPurchasesSlice.reducer;
