import { useAppDispatch, useTypedSelector } from 'store/store';
import { useEffect, useRef } from 'react';
import {
  getAvisContactManagersAction,
  getAvisContactsAction
} from 'store/contacts/actions';
import { Navbar } from 'components/navbar';
import { Subheader } from 'components/subheader';
import { useScrollHeight } from 'hooks/use-scroll-height';
import { UpdatedAt } from 'components/updated-at';
import { dateToHeaderFormat } from 'helpers/date';
import { getFleetAllUpdatesAction } from 'store/fleet-all-updates/actions';
import {
  ContactsFooter,
  ContactsStyled,
  FooterData,
  FooterItem,
  FooterSubItem,
  FooterSubtitle,
  FooterTitle
} from './style';
import { ContactItem } from './contact-item';

export const AvisContactsPage = () => {
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const { scrollHeight } = useScrollHeight(ref);
  const { contacts, contactManagers } = useTypedSelector(
    (state) => state.contacts
  );
  const { fleetUpdates } = useTypedSelector((state) => state.fleetUpdates);

  useEffect(() => {
    dispatch(getAvisContactsAction());
    dispatch(getAvisContactManagersAction());
    dispatch(getFleetAllUpdatesAction());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <Subheader title="Зв’язатись з AVIS">
        {!!fleetUpdates.results.length && (
          <UpdatedAt absoluteAlign>
            {dateToHeaderFormat(fleetUpdates.results[0].fleet_contacts_table)}
          </UpdatedAt>
        )}
      </Subheader>
      <ContactsStyled ref={ref} style={{ height: `${scrollHeight}px` }}>
        {!!contactManagers.results.length && (
          <>
            <ContactItem
              email={contactManagers.results[0].account_manager_email || ''}
              fullName={contactManagers.results[0].account_manager_full_name}
              phone={contactManagers.results[0].account_manager_phone || ''}
              title="Акаунт менеджер"
            />
            <ContactItem
              email={contactManagers.results[0].service_manager_email || ''}
              fullName={contactManagers.results[0].service_manager_full_name}
              phone={contactManagers.results[0].service_manager_phone || ''}
              title="сервіс  менеджер"
            />
          </>
        )}
        {!!contacts.results.length && (
          <ContactsFooter>
            <FooterItem>
              <FooterTitle>
                Контакт - центр
                <FooterSubtitle>
                  (реєстрація ДТП та інші питання)
                </FooterSubtitle>
              </FooterTitle>
              <FooterData
                href={`tel:${contacts.results[0].contact_center_phone?.replaceAll(
                  ' ',
                  ''
                )}`}
              >
                {contacts.results[0].contact_center_phone}
              </FooterData>
            </FooterItem>
            <FooterItem>
              <FooterSubItem>
                <FooterTitle>Email</FooterTitle>
                <FooterData
                  href={`mailto:${contacts.results[0].contact_center_email}`}
                >
                  {contacts.results[0].contact_center_email}
                </FooterData>
              </FooterSubItem>
              <FooterSubItem>
                <FooterTitle>Чат-бот</FooterTitle>
                <FooterData as="span">
                  {contacts.results[0].contact_center_chatbot}
                </FooterData>
              </FooterSubItem>
            </FooterItem>
          </ContactsFooter>
        )}
      </ContactsStyled>
    </>
  );
};
