import styled from 'styled-components';

export const ReportFileStyled = styled.div`
  width: 250px;
`;

export const FileIcon = styled.div`
  width: 250px;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.theme.colors.coloumn};
  border-radius: 6px;
  cursor: pointer;
`;

export const FileName = styled.div`
  margin-top: 13px;
  color: ${(p) => p.theme.colors.black};
  font-size: 20px;
  font-weight: 400;
`;

export const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const TableWrapper = styled.div`
  overflow: auto;
  table {
    width: 100%;
    border: 1px solid #b0cbef;
    border-width: 1px 0px 0px 1px;
    padding: 10px;
    border-spacing: 0px;
    border-collapse: collapse;
    th {
      font-weight: bold;
      font-size: 14px;
      border: 1px solid #9eb6ce;
      border-width: 0px 1px 1px 0px;
      height: 17px;
      line-height: 17px;
      text-align: center;
    }
    td {
      border: 0px;
      background-color: white;
      padding: 0px 4px 0px 2px;
      border: 1px solid #d0d7e5;
      border-width: 0px 1px 1px 0px;
    }
  }
`;
