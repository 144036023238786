import styled from 'styled-components';

export const SelectDateContainer = styled.div`
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  color: ${(p) => p.theme.colors.black};
  margin-right: 10px;
`;
