import styled from 'styled-components';

export const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 350px;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    max-width: 767px;
  }
`;

export const SearchStyled = styled.div<{ $isActive?: boolean }>`
  position: relative;
  padding-left: 36px;
  height: 40px;
  border: 1px solid
    ${(p) => (p.$isActive ? p.theme.colors.primary : p.theme.colors.black)};
  background: ${(p) =>
    p.$isActive ? p.theme.colors.white : p.theme.colors.secondary};
  border-radius: 5px;
  transition: all 0.2s;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    background: ${(p) => p.theme.colors.white};
  }
`;

export const SearchIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchInput = styled.input`
  display: block;
  width: 100%;
  height: 100%;
  padding: 12px 0;
  color: ${(p) => p.theme.colors.black};
  font-size: 14px;
  font-weight: 400;
  border: none;
  outline: none;
  background: transparent;
`;

export const CloseIcon = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Options = styled.div`
  padding: 6px 0;
  position: absolute;
  left: 0;
  top: calc(100% + 6px);
  width: 100%;
  max-height: 300px;
  overflow: auto;
  border-radius: 6px;
  border: 1px solid ${(p) => p.theme.colors.stroke};
  background: ${(p) => p.theme.colors.white};
  box-shadow:
    4px 5px 9px 0px rgba(63, 81, 126, 0.08),
    -3px -3px 9px 0px rgba(63, 81, 126, 0.06);
  z-index: 1000;
`;

export const OptionsItem = styled.div`
  padding: 10px 12px;
  color: ${(p) => p.theme.colors.secondaryDark};
  font-size: 14px;
  font-weight: 400;
  background-color: ${(p) => p.theme.colors.white};
  transition: background-color color 0.2s;
  cursor: pointer;
  &:hover {
    transition: background-color color 0.2s;
    background-color: ${(p) => p.theme.colors.primary};
    color: ${(p) => p.theme.colors.white};
  }
`;
