import styled from 'styled-components';

export const ExportImportWrapper = styled.div`
  position: relative;
`;

export const ExportImportStyled = styled.div<{ $isOpen?: boolean }>`
  padding: 10px 13px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid
    ${(p) =>
      p.$isOpen ? p.theme.colors.primary : p.theme.colors.secondaryDark};
  background: ${(p) =>
    p.$isOpen ? p.theme.colors.primaryLight : p.theme.colors.white};
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
  svg {
    transform: ${(p) => (p.$isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  }
`;

export const Options = styled.div`
  padding: 8px 0;
  position: absolute;
  right: 0;
  top: calc(100% + 6px);
  min-width: 184px;
  border-radius: 9px;
  border: 1px solid ${(p) => p.theme.colors.stroke};
  background: ${(p) => p.theme.colors.white};
  box-shadow:
    4px 5px 9px 0px rgba(63, 81, 126, 0.08),
    -3px -3px 9px 0px rgba(63, 81, 126, 0.06);
  z-index: 10;
`;

export const OptionsItem = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.black};
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  background: ${(p) => p.theme.colors.white};
  input {
    display: none;
  }
  svg {
    margin-right: 10px;
    path {
      fill: ${(p) => p.theme.colors.black};
    }
  }
  &:hover {
    color: ${(p) => p.theme.colors.white};
    background: ${(p) => p.theme.colors.primary};
    svg {
      path {
        fill: ${(p) => p.theme.colors.white};
      }
    }
  }
  cursor: pointer;
`;

export const ImportName = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.black};
  svg {
    margin-right: 12px;
  }
`;
