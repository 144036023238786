export const removeDuplicatesByField = <T>(arr: T[], field: keyof T) => {
  const uniqueValues = new Set();
  const result = [];

  for (const item of arr) {
    const value = item[field];

    if (!uniqueValues.has(value)) {
      uniqueValues.add(value);
      result.push(item);
    }
  }

  return result;
};
