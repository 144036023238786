import { EditSvg } from 'components/svg/edit-svg';
import type { ReactNode } from 'react';
import { dateToHistory } from 'helpers/date';
import { FlexContainer } from 'components/flex-container';
import {
  EditIcon,
  HistoryDate,
  HistoryItemStyled,
  HistoryTitle
} from './style';

interface HistoryItemProps {
  title: ReactNode;
  date: string | null;
}

export const HistoryItem = ({ title, date }: HistoryItemProps) => (
  <HistoryItemStyled>
    <FlexContainer>
      <EditIcon>
        <EditSvg />
      </EditIcon>
      <HistoryTitle>{title}</HistoryTitle>
    </FlexContainer>
    <HistoryDate>{dateToHistory(date)}</HistoryDate>
  </HistoryItemStyled>
);
