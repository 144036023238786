import type { ReactNode } from 'react';
import { ArrowSvg } from 'components/svg/arrow-svg';
import { useNavigate } from 'react-router-dom';
import type { AppRoute } from 'common/enums/app/app-route';
import { BackBtn, PageTitle, SubheaderStyled, TitleGroup } from './style';

interface SubheaderProps {
  title: string;
  children?: ReactNode;
  backRoute?: AppRoute;
  alignStart?: boolean;
}

export const Subheader = ({
  title,
  backRoute,
  children,
  alignStart
}: SubheaderProps) => {
  const navigate = useNavigate();

  return (
    <SubheaderStyled $alignStart={alignStart}>
      <TitleGroup>
        {!!backRoute && (
          <BackBtn onClick={() => navigate(backRoute)}>
            <ArrowSvg />
          </BackBtn>
        )}
        <PageTitle>{title}</PageTitle>
      </TitleGroup>
      {children}
    </SubheaderStyled>
  );
};
