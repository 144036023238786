import { ExcellSvg } from 'components/svg/excell-svg';
import { DownloadSvg } from 'components/svg/download-svg';
import { StringCutter } from 'components/string-cutter';
import { Popup } from 'components/popup';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import { useState } from 'react';
import {
  FileIcon,
  FileName,
  Footer,
  ReportFileStyled,
  TableWrapper
} from './style';

interface ReportFileProps {
  fileName: string;
  fileLink: string;
}

export const ReportFile = ({ fileName, fileLink }: ReportFileProps) => {
  const [showPopup, setShowPopup] = useState(false);
  const [excelShema, setExcelShema] = useState({ cols: 0, rows: 0 });

  const handleShowPopup = () => {
    fetch(fileLink.replace('http', 'https'))
      .then((res) => res.blob())
      .then((res) => {
        ExcelRenderer(
          res,
          (err: unknown, resp: { cols: number; rows: number }) => {
            if (err) {
              console.log(err);
            } else {
              setExcelShema({
                cols: resp.cols,
                rows: resp.rows
              });
              setShowPopup(true);
            }
          }
        );
      });
  };

  return (
    <ReportFileStyled>
      <FileIcon onClick={handleShowPopup}>
        <ExcellSvg />
      </FileIcon>
      <FileName>
        <StringCutter $lines="2">{fileName}</StringCutter>
      </FileName>
      <Footer>
        <a download={fileName} href={fileLink.replace('http', 'https')}>
          <DownloadSvg />
        </a>
      </Footer>
      <Popup
        maxContentWidth="90%"
        show={!!(showPopup && excelShema.cols)}
        setShow={setShowPopup}
      >
        <TableWrapper>
          <OutTable
            data={excelShema.rows}
            columns={excelShema.cols}
            tableClassName="ExcelTable2007"
            tableHeaderRowClass="heading"
          />
        </TableWrapper>
      </Popup>
    </ReportFileStyled>
  );
};
