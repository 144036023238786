import { useMemo } from 'react';
import { FleetTiresReplacementsPage } from 'routes/fleet-tires-replacements';
import { TiresMovementsPage } from 'routes/tires-movements';
import { UsersPage } from 'routes/users';
import { useTypedSelector } from 'store/store';

export const HomePage = () => {
  const { user } = useTypedSelector((state) => state.user);

  const renderPage = useMemo(() => {
    if (!user) {
      return null;
    }
    switch (user.role.id) {
      case 3:
        return <UsersPage />;
      case 1:
        return <TiresMovementsPage />;
      case 2:
        return <FleetTiresReplacementsPage />;
      default:
        return null;
    }
  }, [user]);

  return renderPage;
};
