export const AuthEmailSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M3.75 7.3125H26.25M3.75 7.3125V23.25C3.75 23.4986 3.84877 23.7371 4.02459 23.9129C4.2004 24.0887 4.43886 24.1875 4.6875 24.1875H25.3125C25.5611 24.1875 25.7996 24.0887 25.9754 23.9129C26.1512 23.7371 26.25 23.4986 26.25 23.25V7.3125M3.75 7.3125L15 17.625L26.25 7.3125"
      stroke="#65615D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
