import type { ID } from 'common/types';
import type { HeaderItem } from 'components/data-table';
import type { ReactNode } from 'react';

export interface TiresDisposalsTable {
  id: ID;
  vehicle_plate: string;
  vehicle_model: string;
  tire_location: string;
  season: string;
  tire_model: string;
  size: string;
  diameter: string;
  tread_depth: string;
  tire_status: string;
  comment: string;
  service_comment: ReactNode;
  confirmed: ReactNode;
}

export const tableHeaders: HeaderItem<TiresDisposalsTable>[] = [
  { id: 'vehicle_plate', title: 'ДНЗ', width: '100px' },
  { id: 'vehicle_model', title: 'Марка / модель', width: '158px' },
  { id: 'tire_location', title: 'Розміщення шин', width: '169px' },
  { id: 'season', title: 'Сезон', width: '81px' },
  { id: 'tire_model', title: 'Марка / модель шин', width: '196px' },
  { id: 'size', title: 'Розмір', width: '87px' },
  { id: 'diameter', title: 'Діаметр', width: '96px' },
  { id: 'tread_depth', title: 'ВП', width: '52px' },
  { id: 'tire_status', title: 'Статус шини', width: '136px' },
  { id: 'comment', title: 'Коментар', width: '190px' },
  {
    id: 'service_comment',
    title: 'Коментар СТО',
    width: '269px',
    editable: true
  },
  { id: 'confirmed', title: 'Підтвердження', width: '156px', editable: true }
];
