import {
  ContactData,
  ContactItemBody,
  ContactItemHeader,
  ContactItemStyled,
  FullName
} from './style';

interface ContactItemProps {
  title: string;
  fullName: string;
  email: string;
  phone: string;
}

export const ContactItem = ({
  title,
  fullName,
  email,
  phone
}: ContactItemProps) => (
  <ContactItemStyled>
    <ContactItemHeader>{title}</ContactItemHeader>
    <ContactItemBody>
      <FullName>{fullName}</FullName>
      <ContactData href={`tel:${phone.replaceAll(' ', '')}`}>
        {phone}
      </ContactData>
      <ContactData href={`mailto:${email}`}>{email}</ContactData>
    </ContactItemBody>
  </ContactItemStyled>
);
