import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FleetNotifications } from 'models/fleet-notifications';
import type { ResponseWithPagination } from 'common/types';
import { getFleetNotificationsAction } from './actions';

interface FleetNotificationsState {
  fleetNotifications: ResponseWithPagination<FleetNotifications>;
  isLoading: boolean;
  count: number;
}

const initialState: FleetNotificationsState = {
  fleetNotifications: { count: 0, next: null, previous: null, results: [] },
  isLoading: false,
  count: 0
};

const fleetNotificationsSlice = createSlice({
  name: 'fleet-notifications',
  initialState,
  reducers: {
    updateCount: (state, { payload }: PayloadAction<number>) => {
      state.count = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      getFleetNotificationsAction.fulfilled,
      (state, { payload }) => {
        state.fleetNotifications = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(getFleetNotificationsAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFleetNotificationsAction.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const { updateCount } = fleetNotificationsSlice.actions;
export const fleetNotificationsReducer = fleetNotificationsSlice.reducer;
