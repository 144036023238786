import { DataTable } from 'components/data-table';
import { Navbar } from 'components/navbar';
import { Subheader } from 'components/subheader';
import { useScrollHeight } from 'hooks/use-scroll-height';
import { useEffect, useRef } from 'react';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { groupeEntities } from 'helpers/groupe-entities';
import {
  dateTableFormat,
  dateToApiFormat,
  dateToHeaderFormat
} from 'helpers/date';
import { NoData } from 'components/no-data';
import {
  getFleetTiresPurchasesAction,
  updateFleetTiresPurchasesAction
} from 'store/fleet-tires-purchases/actions';
import type { FleetTiresPurchases } from 'models/fleet-tires-purchases';
import { UpdatedAt } from 'components/updated-at';
import { TableSelect } from 'components/table-select';
import type { OptionItem } from 'common/types';
import { Loader } from 'components/loader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { updateNotif } from 'store/notifications/reducer';
import { getFleetAllUpdatesAction } from 'store/fleet-all-updates/actions';
import { HeaderBtn } from 'components/header-btn';
import { AppRoute } from 'common/enums/app/app-route';
import { SIZE } from 'components/paginations';
import type { FleetPurchasesTable } from './data';
import { tableHeaders } from './data';
import { Comment } from './comment';

export const FleetTiresPurchasesPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const { scrollHeight } = useScrollHeight(tableRef);
  const [searchParams] = useSearchParams();

  const { fleetPurchases, isLoading } = useTypedSelector(
    (state) => state.fleetTiresPurchases
  );
  const { fleetUpdates } = useTypedSelector((state) => state.fleetUpdates);

  useEffect(() => {
    const page = searchParams.get('page');
    dispatch(getFleetTiresPurchasesAction(`?page=${page || 1}`));
    dispatch(updateNotif({ fleetPurchases: false }));
    dispatch(getFleetAllUpdatesAction());
  }, [dispatch, searchParams]);

  const handleConfirm = (item: FleetTiresPurchases, option: OptionItem) => {
    if (option.id === 0) {
      return;
    }
    dispatch(
      updateFleetTiresPurchasesAction({
        id: item.id,
        body: {
          confirmed: option.id === 1 ? true : false,
          comment: item.comment,
          confirmed_at: dateToApiFormat(new Date())
        }
      })
    );
  };

  const transformToTableFormat = (purchases: FleetTiresPurchases[]) =>
    purchases.map((item) => ({
      ...item,
      season: item.isHeader ? '' : item.season,
      amount: item.isHeader ? '' : item.amount,
      storage: item.isHeader ? '' : item.storage,
      confirmed_at: item.isHeader ? '' : dateTableFormat(item.confirmed_at),
      size: item.isHeader ? '' : item.size,
      diameter: item.isHeader ? '' : item.diameter,
      tire_model: item.isHeader ? '' : item.tire_model,
      vehicle_brand_model: item.isHeader ? item.vehicle_brand_model : '',
      vehicle_plate: item.isHeader ? item.vehicle_plate : '',
      comment: item.isHeader ? (
        ''
      ) : (
        <Comment
          value={item.comment}
          id={item.id}
          disabled={!!item.confirmed_at}
        />
      ),
      confirmed: item.isHeader ? (
        ''
      ) : (
        <TableSelect
          disabled={!!item.confirmed_at}
          options={[
            { id: 1, title: 'Так' },
            { id: 2, title: 'Ні' }
          ]}
          initialValue={
            !item.confirmed_at
              ? { id: 0, title: '-' }
              : item.confirmed
              ? { id: 1, title: 'Так' }
              : { id: 2, title: 'Ні' }
          }
          onSelect={(value) => handleConfirm(item, value)}
        />
      )
    }));

  return (
    <>
      <Navbar />
      <Subheader title="Підтвердження закупівлі">
        {!!fleetUpdates.results.length && !!fleetPurchases.results.length && (
          <UpdatedAt absoluteAlign>
            {dateToHeaderFormat(fleetUpdates.results[0].fleet_purchases_table)}
          </UpdatedAt>
        )}
        <HeaderBtn onClick={() => navigate(AppRoute.FLEET_PURCHASES_HISTORY)}>
          Переглянути історію
        </HeaderBtn>
      </Subheader>
      <div ref={tableRef} style={{ position: 'relative' }}>
        {fleetPurchases.results.length ? (
          <DataTable<FleetPurchasesTable>
            headers={tableHeaders}
            data={transformToTableFormat(
              groupeEntities(fleetPurchases.results)
            )}
            checkedList={[]}
            setCheckedList={() => {}}
            actions={[]}
            withOutCheck
            customHeight={
              fleetPurchases.count >= SIZE ? scrollHeight - 64 : scrollHeight
            }
            count={fleetPurchases.count}
          />
        ) : isLoading ? (
          <Loader isBig place={{ left: 'calc(50% - 16px)', top: '100px' }} />
        ) : (
          <NoData height={`${scrollHeight}px`} />
        )}
      </div>
    </>
  );
};
