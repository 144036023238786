import styled from 'styled-components';

interface CheckBoxStyledProps {
  checked: boolean;
}

export const CheckBoxStyled = styled.label<CheckBoxStyledProps>`
  position: relative;
  input {
    display: none;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  min-width: 17px;
  height: 16px;
  border: 1px solid
    ${(p) => (p.checked ? p.theme.colors.checked : p.theme.colors.stroke)};
  border-radius: 4px;
  background: ${(p) =>
    p.checked ? p.theme.colors.checked : p.theme.colors.white};
  svg {
    width: 100%;
    height: 100%;
    display: ${(p) => (p.checked ? 'block' : 'none')};
  }
  cursor: pointer;
`;
