interface SettingsSvgProps {
  fill?: string;
}

export const SettingsSvg = ({ fill }: SettingsSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
  >
    <path
      d="M9 20C8.59851 20 8.23048 19.9 7.86245 19.7L1.13755 15.8333C0.434944 15.4333 0 14.6667 0 13.8667V6.13333C0 5.33333 0.434944 4.56667 1.13755 4.16667L7.86245 0.3C8.56505 -0.1 9.43494 -0.1 10.1375 0.3L16.8625 4.16667C17.5651 4.56667 18 5.33333 18 6.13333V13.8667C18 14.6667 17.5651 15.4333 16.8625 15.8333L10.1375 19.7C9.76952 19.9 9.40149 20 9 20ZM9 1.33333C8.83271 1.33333 8.66543 1.36667 8.5316 1.46667L1.80669 5.33333C1.50558 5.5 1.33829 5.8 1.33829 6.13333V13.8667C1.33829 14.2 1.50558 14.5 1.80669 14.6667L8.5316 18.5333C8.83271 18.7 9.16729 18.7 9.4684 18.5333L16.1933 14.6667C16.4944 14.5 16.6617 14.2 16.6617 13.8667V6.13333C16.6617 5.8 16.4944 5.5 16.1933 5.33333L9.4684 1.46667C9.33457 1.36667 9.16728 1.33333 9 1.33333Z"
      fill={fill || '#65615D'}
    />
    <path
      d="M9.00117 13.3043C7.16102 13.3043 5.6889 11.8377 5.6889 10.0043C5.6889 8.17101 7.16102 6.70435 9.00117 6.70435C10.8413 6.70435 12.3134 8.17101 12.3134 10.0043C12.3134 11.8377 10.8413 13.3043 9.00117 13.3043ZM9.00117 8.03768C7.89708 8.03768 7.02719 8.90435 7.02719 10.0043C7.02719 11.1043 7.89708 11.971 9.00117 11.971C10.1053 11.971 10.9751 11.1043 10.9751 10.0043C10.9751 8.90435 10.1053 8.03768 9.00117 8.03768Z"
      fill={fill || '#65615D'}
    />
  </svg>
);
