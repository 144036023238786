import styled from 'styled-components';

export const NavbarStyled = styled.header`
  padding: 25px 20px;
  width: 100%;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(p) => p.theme.colors.secondary};
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    padding: 13px 16px;
    height: 60px;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    display: none;
  }
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
`;

export const UserName = styled.div`
  color: ${(p) => p.theme.colors.black};
  text-align: right;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  text-transform: uppercase;
`;

export const Burger = styled.div`
  display: none;
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    display: block;
  }
`;
