export const BurgerSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="20"
    viewBox="0 0 28 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.4375 19.5625H23.5625C24.149 19.5625 24.625 19.0865 24.625 18.5C24.625 17.9135 24.149 17.4375 23.5625 17.4375H4.4375C3.851 17.4375 3.375 17.9135 3.375 18.5C3.375 19.0865 3.851 19.5625 4.4375 19.5625ZM1.25 11.0625H26.75C27.3365 11.0625 27.8125 10.5865 27.8125 10C27.8125 9.4135 27.3365 8.9375 26.75 8.9375H1.25C0.6635 8.9375 0.1875 9.4135 0.1875 10C0.1875 10.5865 0.6635 11.0625 1.25 11.0625ZM4.4375 2.5625H23.5625C24.149 2.5625 24.625 2.0865 24.625 1.5C24.625 0.9135 24.149 0.4375 23.5625 0.4375H4.4375C3.851 0.4375 3.375 0.9135 3.375 1.5C3.375 2.0865 3.851 2.5625 4.4375 2.5625Z"
      fill="#D4002A"
    />
  </svg>
);
