import { createSlice } from '@reduxjs/toolkit';
import type { TiresMovementsHistory } from 'models/tires-movements-history';
import type { ResponseWithPagination } from 'common/types';
import { getMovementsHistoryAction } from './actions';

interface MovementsHistoryState {
  movementsHistory: ResponseWithPagination<TiresMovementsHistory>;
  isLoading: boolean;
}

const initialState: MovementsHistoryState = {
  movementsHistory: { count: 0, next: null, previous: null, results: [] },
  isLoading: false
};

const movementsHistorySlice = createSlice({
  name: 'movements-history',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getMovementsHistoryAction.fulfilled,
      (state, { payload }) => {
        state.movementsHistory = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(getMovementsHistoryAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMovementsHistoryAction.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const movementsHistoryReducer = movementsHistorySlice.reducer;
